import React from 'react'
import { withRouter } from 'react-router-dom'

import LogoLink from '../components/LogoLink'
import NavbarButton from '../components/NavbarButton'
import BottomNavigation from '../components/BottomNavigation'

import styles from './Navbar.module.css'
import routes from './routes'

const Navbar = ({ match }) => {
  return (
    <div className={styles.navbar}>
      <LogoLink />

      <div className={styles.navbarTop}>
        {routes.map((route) => {
          return (
            <NavbarButton
              to={`${match.url}${route.path}`}
              label={route.label}
              icon={route.fontAwesomeIcon}
              key={route.label}
            />
          )
        })}
      </div>

      <BottomNavigation />
    </div>
  )
}

export default withRouter(Navbar)
