import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import DesktopIcon from './DesktopIcon'
import { ModalButton } from '../../components/Modal'
import NewAppModal from '../../modals/NewAppModal'

import styles from './Desktop.module.css'

const App = SortableElement((props) => <DesktopIcon {...props} />)

const Apps = SortableContainer(({ apps, handleClick }) => {
  return (
    <div className={styles.apps}>
      <ModalButton component={NewAppModal}>
        <div className={styles.app__item}>
          <div className={`${styles.app__icon} ${styles.new}`} />
          <div className={styles.app__name}>Create New App</div>
        </div>
      </ModalButton>
      {apps.map(({ id, data }, i) => (
        <App
          index={i}
          id={id}
          name={data.company}
          icon={data.icon}
          handleClick={handleClick}
          key={id}
        />
      ))}
    </div>
  )
})

export default Apps
