import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'

import { Box } from '../../ui/layout'
import { Input, Select, TextArea, FormFiles } from '../../ui/inputs'

export const ProductForm = ({
  media,
  bindInput,
  handleUpload,
  handleDelete,
}) => {
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Upload Images">
        <FormFiles
          name="image"
          files={media.image}
          onChange={handleUpload}
          onDelete={handleDelete}
        />
      </FormRow>

      <FormRow label="Product Type">
        <Select placeholder="Select" {...bindInput('productType')}>
          <option value="music">Music</option>
        </Select>
      </FormRow>

      <FormRow label="Product Name">
        <Input placeholder="Product Name" {...bindInput('title')} />
      </FormRow>

      <FormRow label="Quantity">
        <Input
          type="number"
          min="0"
          placeholder="Quantity"
          {...bindInput('quantity')}
        />
      </FormRow>

      <FormRow label="Price">
        <Box display="flex">
          <Input
            type="number"
            min="0"
            placeholder="100"
            {...bindInput('price')}
          />
          <Select style={{ marginLeft: '8px' }}>
            <option value="EUR">EUR</option>
          </Select>
        </Box>
      </FormRow>

      <FormRow>
        <TextArea
          placeholder="Product Description"
          {...bindInput('description')}
        />
      </FormRow>
    </>
  )
}

export default ProductForm
