import React from 'react'

import { Box } from '../../ui'
import SearchInput from '../../ui/SearchInput'

import Table from '../../components/Table'
import selectCheckbox from '../../tables/actions/SelectCheckbox'

import DataProvider from '../../components/DataProvider'

const RelatedItemsForm = ({ type, model, state, handleCheckbox }) => {
  return (
    <DataProvider type={type}>
      {({ data, loading }, { filterItems }) => (
        <div style={{ minHeight: 300 }}>
          <Box paddingX={6} paddingY={6}>
            <SearchInput onChange={filterItems} />
          </Box>
          <Table
            data={data}
            model={[
              ...model,
              selectCheckbox('children', state, handleCheckbox),
              selectCheckbox('parents', state, handleCheckbox),
            ]}
          />
        </div>
      )}
    </DataProvider>
  )
}

export default RelatedItemsForm
