import React from 'react'

import styles from './App.module.css'

const AppMenu = ({ style = {}, menu, showMenu, ...props }) => {
  const menuStyles = {
    left: showMenu ? '0' : '-100%',
    color: style.menuTextColor,
    backgroundColor: style.menuBackgroundColor,
  }
  return (
    <>
      <div className={styles.menu} style={menuStyles}>
        {menu
          .filter((item) => item.visible)
          .map((item, i) => (
            <div className={styles.menuLink} key={i}>
              {item.menuName}
            </div>
          ))}
        <div
          className={styles.menuLink}
          style={{ position: 'absolute', bottom: 0, width: '100%' }}
        >
          Settings
        </div>
      </div>
      {showMenu && (
        <div className={styles.menuOverlay} onClick={props.toggleMenu} />
      )}
    </>
  )
}

export default AppMenu
