import produceReducer from '../immer'
import * as types from './types'
import { arrayMove } from '../../utils'

export const initialState = {}

const reducer = produceReducer(initialState, {
  [types.FETCH_COMPANIES_SUCCESS]: (state, action) =>
    (state.companies = action.data),
  [types.FETCH_PROFILE_SUCCESS]: (state, action) =>
    (state.profile = action.data),
  [types.UPDATE_PROFILE_SUCCESS]: (state, action) =>
    (state.profile = action.data),
  [types.CREATE_APP]: (state, action) => state.companies.push(action.data),
  [types.DELETE_APP]: (state, action) =>
    state.companies.filter((c) => c.id !== action.id),
  [types.SORT_APPS]: (state, action) =>
    (state.companies = arrayMove(
      state.companies,
      action.oldIndex,
      action.newIndex
    )),
})

export default reducer
