import React from 'react'

import FormRow from './components/FormRow'
import { Input, Select, TextArea } from '../ui/inputs'

import PopupTabs from '../ui/PopupTabs'

const NotificationForm = ({ bindInput }) => (
  <>
    <FormRow label="Notification name">
      <Input {...bindInput('name')} placeholder="Notification name" />
    </FormRow>
    <FormRow label="Notification title">
      <Input {...bindInput('title')} placeholder="Notification title" />
    </FormRow>
    <FormRow label="Target URL">
      <Input placeholder="https://" {...bindInput('url')} />
    </FormRow>

    <FormRow>
      <TextArea placeholder="Notification body" {...bindInput('body')} />
    </FormRow>

    <FormRow label="Select Type">
      <Select
        label="Select Type"
        placeholder="Select Type"
        {...bindInput('type')}
      >
        <option value="select">Type</option>
      </Select>
    </FormRow>

    <FormRow label="Send date">
      <Input placeholder="Send date" {...bindInput('date')} />
    </FormRow>
  </>
)

const NotificationFormWithTabs = (props) => {
  return (
    <PopupTabs
      tabs={[
        {
          icon: 'cog',
          label: 'Settings',
          component: () => <NotificationForm {...props} />,
        },
        {
          icon: 'clock',
          label: 'Scheduling',
          component: () => <div>Scheduling content</div>,
        },
        {
          icon: 'street-view',
          label: 'Geofence',
          component: () => <div>Geofence content</div>,
        },
      ]}
    />
  )
}

export default NotificationFormWithTabs
