import React from 'react'

function prependHttp(link) {
  if (typeof link === 'string') {
    return link.indexOf('://') === -1 ? 'http://' + link : link
  }
}

const ExternalLink = ({ children, href, ...props }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={prependHttp(href)}
      {...props}
    >
      {children}
    </a>
  )
}

export default ExternalLink
