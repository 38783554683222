import React from 'react'
import { withRouter } from 'react-router-dom'

import { ModalButton } from '../../components/Modal'
import Profile from '../../modals/ProfileModal'
import HelpModal from '../../modals/HelpModal'

import LogoutButton from '../../components/LogoutButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoundButton from '../../ui/RoundButton'

import navbar from '../dashboard/Navbar.module.css'

const BottomNavigation = (props) => {
  return (
    <div className={navbar.navbarBottom}>
      <ModalButton component={HelpModal}>
        <RoundButton data-tooltip="Help">
          <FontAwesomeIcon icon="question-circle" />
        </RoundButton>
      </ModalButton>

      <ModalButton component={Profile}>
        <RoundButton data-tooltip="Profile">
          <FontAwesomeIcon icon="cog" />
        </RoundButton>
      </ModalButton>

      <LogoutButton />
    </div>
  )
}

export default withRouter(BottomNavigation)
