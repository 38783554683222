import React from 'react'

import mask from './Mask.module.css'

const Mask = ({
  shape,
  width = '100%',
  height = '100%',
  className,
  children,
}) => {
  return (
    <div
      className={[mask[shape], className].join(' ').trim()}
      style={{ width, height }}
    >
      {children}
    </div>
  )
}

export default Mask
