import TYPE from '../../../api/types'

import exhibitorsModel from '../../tables/exhibitorsModel'
import { ExhibitorModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.EXHIBITOR,
  title: 'Exhibitors',
  table: exhibitorsModel,
  modal: {
    header: 'Edit Exhibitor',
    component: ExhibitorModal,
  },
}

export default pageFactory(page)
