import api, { BASE_URL } from './api'
import { setAuthToken, clearAuthToken } from './storage'

export function register({
  email = 'slava@festivality.co',
  password = 'slava',
  company = 'newapp',
  ...rest
}) {
  const body = JSON.stringify({
    email,
    password,
    company,
    ...rest,
  })

  return api(`${BASE_URL}/public/register`, { method: 'POST', body })
}

export async function login({
  email = 'slava@festivality.co',
  password = 'slava',
}) {
  const body = JSON.stringify({
    email,
    password,
  })

  const res = await api(`${BASE_URL}/public/login`, { method: 'POST', body })
  setAuthToken(res.bearerToken)
  return res
}

export async function logout() {
  const res = await api(`${BASE_URL}/auth/logout`, { method: 'POST' })
  clearAuthToken()
  return res
}

export function reset(email, password) {
  const body = JSON.stringify({
    email,
    password,
  })

  return api(`${BASE_URL}/user/reset`, { method: 'POST', body })
}

export async function clientLogin(id) {
  const body = JSON.stringify({
    clientId: id,
  })

  const res = await api(`${BASE_URL}/auth/client`, {
    method: 'POST',
    body,
  })

  setAuthToken(res.bearerToken)
  return res
}
