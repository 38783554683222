import React from 'react'

import { connect } from 'react-redux'
import { getButtonsState, updateButtons } from '../../store/ui'

import Popup from '../ui/Popup'
import FormRow from '../forms/components/FormRow'
import {
  // RadioGroup,
  // RadioButton,
  Checkbox,
  Button,
} from '../ui/inputs'
// import RoundButton from '../ui/RoundButton'
// import Emoji from '../ui/Emoji'

const BUTTONS = {
  artists: { path: '/content/artists', label: 'Artists', value: '🎤' },
  speakers: { path: '/content/speakers', label: 'Speakers', value: '🎙️' },
  venues: { path: '/content/venues', label: 'Venues', value: '📍' },
  events: { path: '/content/events', label: 'Events', value: '📅' },
  pages: { path: '/content/pages', label: 'Pages', value: '📄' },
  exhibitors: { path: '/content/exhibitors', label: 'Exhibitors', value: '👾' },
  partners: { path: '/content/partners', label: 'Partners', value: '🤝' },
  products: { path: '/monetization/products', label: 'Products', value: '🛒' },
  offers: { path: '/monetization/offers', label: 'Offers', value: '🍰' },
  banners: { path: '/banners', label: 'Banners', value: '💲' },
  notifications: {
    path: '/notifications/push',
    label: 'Notifications',
    value: '🔔',
  },
  overview: { path: '/overview', label: 'Overview', value: '📊' },
}

const DevModal = ({ hideModal, header, buttons, ...props }) => {
  // const handleChange = (ev) => {
  //   const { value } = ev.target
  //   console.log(value)
  // }

  // const changeVisibility = (ev, label) => {
  //   const update = buttons.map((button) => {
  //     if (button.label === label) {
  //       button.visible = ev.target.checked
  //     }
  //     return button
  //   })
  //   props.dispatch(updateButtons(update))
  // }

  const handleCheckbox = (ev) => {
    const { value, checked } = ev.target

    const oldCheckboxValue = buttons

    const newCheckboxValue = checked
      ? oldCheckboxValue.concat(value)
      : oldCheckboxValue.filter((v) => v !== value)

    props.dispatch(updateButtons(newCheckboxValue))
  }

  // const sorted = [...buttons].sort((a, b) => a.label.localeCompare(b.label))
  return (
    <Popup header={header} hideModal={hideModal}>
      <div>
        {/* <FormRow label="App Status">
          <RadioGroup value={props.data.status} onChange={handleChange}>
            <RadioButton value="1" label="1. Developing" />
            <RadioButton value="2" label="2. Deploying" />
            <RadioButton value="3" label="3. Deployed" />
          </RadioGroup>
        </FormRow> */}
        <FormRow label="Components">
          {Object.keys(BUTTONS).map((button, i) => (
            <Checkbox
              name="component"
              value={button}
              label={button}
              checked={buttons.indexOf(button) > -1}
              // onChange={(ev) => changeVisibility(ev, button)}
              onChange={handleCheckbox}
              key={i}
            />
          ))}
        </FormRow>
        <FormRow label="Reset State">
          <Button onClick={() => props.dispatch({ type: 'RESET_STATE' })}>
            Reset
          </Button>
        </FormRow>
      </div>
    </Popup>
  )
}

DevModal.defaultProps = {
  header: 'Dev Settings',
  item: {
    data: {},
    media: {},
  },
}

function mapStateToProps(state, ownProps) {
  return { buttons: getButtonsState(state) }
}

export default connect(mapStateToProps)(DevModal)
