import { connect } from 'react-redux'

import useFetcher from './useFetcher'

const AbstractDataFetcher = ({ children, fetch, map, ...props }) => {
  const { loading, error, fetchData } = useFetcher(() => {
    if (typeof fetch === 'function') {
      props.dispatch(fetch())
    }
  })

  return children({
    loading: loading,
    error: error,
    props: props,
    retry: fetchData,
  })
}

const mapStateToProps = (state, props) => {
  if (typeof props.map === 'function') return props.map(state)
  else return {}
}

export default connect(mapStateToProps)(AbstractDataFetcher)
