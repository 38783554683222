import React from 'react'

import DesignForm from '../../forms/app/DesignForm'
import DataFetcher from '../../components/DataFetcher'
import TYPE from '../../../api/types'

const Design = ({ item }) => {
  return (
    <DesignForm
      title="App Design"
      initialState={item.data}
      initialMedia={item.media}
      item={item}
    />
  )
}

Design.defaultProps = {
  item: {
    data: {},
    media: {},
  },
}

export default DataFetcher(TYPE.APP_DESIGN)(Design)
