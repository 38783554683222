import Frontpage from './screens/frontpage'
import Artists from './screens/Artists'
import Speakers from './screens/Speakers'
import Partners from './screens/Partners'
import Exhibitors from './screens/Exhibitors'
import Venues from './screens/Venues'
import Events from './screens/Events'
import Pages from './screens/Pages'
import Offers from './screens/Offers'
import Products from './screens/Products'

export default [
  {
    path: '/setup/frontpage',
    component: Frontpage,
  },
  {
    path: '/content/artists',
    component: Artists,
  },
  {
    path: '/content/partners',
    component: Partners,
  },
  {
    path: '/content/exhibitors',
    component: Exhibitors,
  },
  {
    path: '/content/speakers',
    component: Speakers,
  },
  {
    path: '/content/venues',
    component: Venues,
  },
  {
    path: '/content/events',
    component: Events,
  },
  {
    path: '/content/pages',
    component: Pages,
  },
  {
    path: '/content/offers',
    component: Offers,
  },
  {
    path: '/content/shop',
    component: Products,
  },
  {
    path: '/banners',
    component: Partners,
  },
]
