import React from 'react'

import Label from './Label'
import Radio from './base/Radio'

const RadioButton = ({ justify, reverse, inline, label, style, ...props }) => {
  if (label) {
    return (
      <Label
        {...{
          justify,
          reverse,
          inline,
          label,
          style,
        }}
      >
        <Radio {...props} />
      </Label>
    )
  } else {
    return <Radio {...props} />
  }
}

export default RadioButton
