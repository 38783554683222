import React from 'react'

import { Checkbox } from '../../ui/inputs'

const statefulCheckbox = (name, state, handleCheckbox) => (props) => {
  const checked = state[name]
    ? state[name].indexOf(props.id + '') !== -1
    : false

  return (
    <Checkbox
      name={name}
      value={props.id}
      // checked={checked}
      onChange={handleCheckbox}
    />
  )
}

export default (name, state, handleCheckbox) => {
  return {
    style: { flex: 'none', width: '48px' },
    render: statefulCheckbox(name, state, handleCheckbox),
  }
}
