import React from 'react'

import { Box } from '../../ui/layout'
import { Input } from '../../ui/inputs'

import FormRow from '../components/FormRow'
import Text from '../../ui/Text'

const CredentialsForm = ({ bindInput }) => {
  return (
    <>
      <Box padding="24px">
        <Text size="sm">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur
          ut, quos dolore, consectetur modi recusandae explicabo, totam
          dignissimos quam voluptates blanditiis?
        </Text>
      </Box>

      <FormRow label="Apple iTunes">
        <Input placeholder="Apple ID" {...bindInput('appleId')} />
        <Input
          type="password"
          style={{ marginTop: '16px' }}
          placeholder="Password"
          {...bindInput('applePassword')}
        />
      </FormRow>
      <FormRow label="Google Play Store">
        <Input placeholder="Google Account" {...bindInput('googleAccount')} />
        <Input
          type="password"
          style={{ marginTop: '16px' }}
          placeholder="Password"
          {...bindInput('googlePassword')}
        />
      </FormRow>
    </>
  )
}

export default CredentialsForm
