import React from 'react'

import styles from './Checkbox.module.css'

const Radio = ({ style, ...props }) => (
  <label className={styles.checkbox} style={style}>
    <input className={styles.input} type="radio" {...props} />
    <div className={styles.label} />
  </label>
)

export default Radio
