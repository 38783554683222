import React from 'react'

import { Text } from '../ui'
import { Box } from '../ui/layout'
import { Input, Select, SelectCountry, DateTime } from '../ui/inputs'

import FormRow from '../forms/components/FormRow'

let NewAppForm = ({
  state,
  handleChange,
  handleUpdate,
  handleBlur,
  bindInput,
  ...props
}) => {
  const events = [
    { value: 'music', label: 'Music' },
    { value: 'film', label: 'Film' },
    { value: 'wine', label: 'Wine' },
    { value: 'beer', label: 'Beer festival' },
    { value: 'tour', label: 'Live tour' },
    { value: 'sports', label: 'Sports' },
    { value: 'conference', label: 'Conference' },
    { value: 'trade', label: 'Trade show' },
    { value: 'other', label: 'Other' },
  ]

  return (
    <form className="form" onBlur={handleBlur}>
      <FormRow label="Event name">
        <Input placeholder="App/Event Name" {...bindInput('appName')} />
      </FormRow>

      <FormRow label="Event type">
        <Select placeholder="Event Type" {...bindInput('eventType')}>
          {events.map((event, i) => (
            <option key={i} value={event.value}>
              {event.label}
            </option>
          ))}
        </Select>
      </FormRow>

      <FormRow label="Event start/end">
        <Box display="flex" justifyContent="space-between">
          <Box width="49%">
            <DateTime
              timeFormat={false}
              name="startDate"
              placeholder="Start date"
              onChange={handleChange}
            />
          </Box>
          <Box width="49%">
            <DateTime
              timeFormat={false}
              name="endDate"
              placeholder="End date"
              onChange={handleChange}
            />
          </Box>
        </Box>
      </FormRow>

      <FormRow label="Country">
        <SelectCountry {...bindInput('country')} />
      </FormRow>

      <FormRow label="Website">
        <Input placeholder="Website" {...bindInput('website')} />
      </FormRow>

      <Box paddingX={6} paddingY={4}>
        <Text size="sm">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta ipsam
          itaque nam sit atque, veritatis non temporibus, delectus quisquam
          nihil voluptatem repellendus. Tenetur architecto esse commodi
          deserunt, non enim inventore optio, saepe labore laboriosam dolore
          maxime dolores blanditiis eaque maiores id. Corrupti illo quidem porro
          vel ullam laborum atque asperiores.
        </Text>
      </Box>
    </form>
  )
}

export default NewAppForm
