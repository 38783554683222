import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Popup from '../ui/Popup'
import Heading from '../ui/Heading'
import { Box } from '../ui'
import Text from '../ui/Text'

import image from '../../images/help.png'

const HelpComponent = (props) => {
  const bg = {
    height: 300,
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    borderRadius: '4px 4px 0 0',
  }
  const location = props.location.pathname.split('/').pop()
  return (
    <div>
      <div style={bg} />
      <Box paddingX={6} paddingY={6}>
        <Heading>{location + ' page'}</Heading>
        <Box marginTop="16px">
          <Text size="sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Praesentium, eius tenetur repellendus, et architecto cumque quod
            laudantium vel harum, assumenda sunt.
          </Text>
        </Box>
      </Box>
    </div>
  )
}

const HelpModal = (props) => (
  <Popup header="Help" hideModal={props.hideModal}>
    <Switch>
      <Route component={HelpComponent} />
    </Switch>
  </Popup>
)

export default HelpModal
