import React from 'react'

const Undraw = ({ svg, children, style, ...props }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        backgroundImage: `url(${svg})`,
        backgroundSize: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export default Undraw
