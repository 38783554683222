import React from 'react'
import TYPE from '../../../api/types'

import FormRow from '../components/FormRow'
import { Input, TextArea, Toggle, FileInput } from '../../ui/inputs'
import Img from '../../ui/Img'

import { getLastImage } from '../../../utils/normalize'

import appSetupFormFactory from '../../factories/appSetupFormFactory'

import touch from '../../ui/Touch.module.css'

import placeholder from '../../../images/native-icon.png'

const Icon = ({ src = placeholder, size = 96, ...props }) => {
  return (
    <Img
      src={src}
      width={size}
      height={size}
      className={touch.touch}
      style={{ display: 'inline', borderRadius: 4 }}
      fit="cover"
      {...props}
    />
  )
}

const AppearanceForm = ({
  state,
  media,
  bindInput,
  handleUpload,
  handleDelete,
}) => {
  return (
    <>
      <FormRow label="App Icon" description="512 x 512 pixels">
        <div style={{ textAlign: 'right' }}>
          <FileInput
            name="image"
            onChange={handleUpload}
            // onDelete={handleDelete}
            accept=".jpg, .jpeg, .png"
          >
            <Icon src={getLastImage(media.image)} />
          </FileInput>
        </div>
      </FormRow>
      <FormRow label="App Name" inline={false}>
        <Input placeholder="App Name" {...bindInput('appStoreName')} />
      </FormRow>
      <FormRow label="App Description" inline={false}>
        <TextArea
          placeholder="Apple App Store description"
          {...bindInput('appStoreDescription')}
        />
      </FormRow>
      <FormRow label="Keywords" inline={false}>
        <Input placeholder="Keywords" {...bindInput('appStoreKeywords')} />
      </FormRow>

      <FormRow label="Google Play Appearance" inline={false}>
        <Toggle
          label="Use different setup for Android?"
          reverse
          justify
          {...bindInput('android')}
        />
      </FormRow>

      {state.android && (
        <>
          <FormRow label="Android App Icon" description="512 x 512 pixels">
            <div style={{ textAlign: 'right' }}>
              <FileInput
                name="androidIcon"
                onChange={handleUpload}
                // onDelete={handleDelete}
                accept=".jpg, .jpeg, .png"
              >
                <Icon src={getLastImage(media.androidIcon)} />
              </FileInput>
            </div>
          </FormRow>
          <FormRow label="Android App Name" inline={false}>
            <Input placeholder="App Name" {...bindInput('playStoreName')} />
          </FormRow>
          <FormRow label="Android App Description" inline={false}>
            <TextArea
              placeholder="Google Play Store description"
              {...bindInput('playStoreDescription')}
            />
          </FormRow>
          <FormRow label="Keywords" inline={false}>
            <Input placeholder="Keywords" {...bindInput('playStoreKeywords')} />
          </FormRow>
        </>
      )}
    </>
  )
}

export default appSetupFormFactory(TYPE.APP_APPEARANCE, AppearanceForm)

// export default compose(
//   Forma(),
//   FormaHandlers(TYPE.APP_APPEARANCE)
// )(AppearanceForm)
