import React from 'react'

import './Preview.css'

import App from '../../preview/App'

class Preview extends React.Component {
  state = {
    showPreview: this.props.preview,
    showDevice: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.showDevice === false && nextProps.preview === true) {
      return { showDevice: true }
    }
    return null
  }

  componentDidMount() {
    this.preview.addEventListener('transitionend', this.handleTransition)
  }

  componentWillUnmount() {
    this.preview.removeEventListener('transitionend', this.handleTransition)
  }

  handleTransition = (ev) => {
    if (ev.propertyName === 'width' && !this.props.preview) {
      this.setState({ showDevice: false, showPreview: false })
    }
  }

  togglePreview = (ev) => {
    this.setState({
      showPreview: !this.state.showPreview,
      showDevice: !this.state.showPreview ? true : this.state.showDevice,
    })
  }

  render() {
    const classname = this.props.preview ? 'preview' : 'preview preview--hidden'

    return (
      <div className={classname} ref={(ref) => (this.preview = ref)}>
        <div className="preview__resize" onClick={this.props.togglePreview} />
        <div className="preview__device">
          {this.state.showDevice && <App />}
        </div>
      </div>
    )
  }
}

export default Preview
