import * as types from './types'

// Actions creators
export function fetchCompaniesRequest() {
  return { type: types.FETCH_COMPANIES_REQUEST }
}

export function fetchCompaniesSuccess(data) {
  return { type: types.FETCH_COMPANIES_SUCCESS, data }
}

export function fetchCompaniesFailure(message) {
  return { type: types.FETCH_COMPANIES_FAILURE, message }
}

export function fetchProfileRequest() {
  return { type: types.FETCH_PROFILE_REQUEST }
}

export function fetchProfileSuccess(data) {
  return { type: types.FETCH_PROFILE_SUCCESS, data }
}

export function fetchProfileFailure(message) {
  return { type: types.FETCH_PROFILE_FAILURE, message }
}

export function updateProfileRequest() {
  return { type: types.UPDATE_PROFILE_REQUEST }
}

export function updateProfileSuccess(data) {
  return { type: types.UPDATE_PROFILE_SUCCESS, data }
}

export function updateProfileFailure(message) {
  return { type: types.UPDATE_PROFILE_FAILURE, message }
}

export function createApp(res) {
  return { type: types.CREATE_APP, res }
}

export function deleteApp(id) {
  return { type: types.DELETE_APP, id }
}

export function sortApps(oldIndex, newIndex) {
  return { type: types.SORT_APPS, oldIndex, newIndex }
}
