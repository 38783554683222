import { connect } from 'react-redux'
import { mapAppType } from '../../store/app'

const mapType = (type) => (Component) => {
  const mapStateToProps = (state) => ({
    data: mapAppType(state, type),
  })

  return connect(mapStateToProps)(Component)
}

export default mapType
