import React from 'react'
import { connect } from 'react-redux'

import { clientLogin } from '../../../api'
import {
  togglePreview,
  toggleSidebar,
  getPreviewState,
  getSidebarState,
} from '../../../store/ui'

import Spinner from '../../ui/Spinner'

import App from '../../preview/App'
import Sidebar from './Sidebar'
import Content from './Content'
import Preview from './Preview'
import { Setup, SetupNavigation } from '../setup'

import { isMobile } from '../../../utils'

class Dashboard extends React.Component {
  state = {
    loading: true,
    error: false,
  }

  static defaultProps = {
    showPreview: true,
    showSidebar: false,
  }

  componentDidMount() {
    const { match, history } = this.props
    if (history.action === 'POP') {
      const id = match.params.app
      this.login(id)
    } else {
      this.setState({ loading: false })
    }
  }

  login(id) {
    this.setState({ loading: true })
    clientLogin(id)
      .then((res) => {
        this.setState({ loading: false })
      })
      .catch((err) => {
        this.setState({ loading: false, error: true })
      })
  }

  togglePreview = (ev) => {
    this.props.dispatch(togglePreview(!this.props.showPreview))
  }

  toggleSidebar = (ev) => {
    this.props.dispatch(toggleSidebar(!this.props.showSidebar))
  }

  render() {
    if (this.state.loading === true) {
      return <Spinner show={true} style={{ background: 'rgba(0,0,0,.5)' }} />
    }
    if (isMobile()) {
      return <App />
    }
    return (
      <div className="dashboard">
        <Sidebar
          showSidebar={this.props.showSidebar}
          toggleSidebar={this.toggleSidebar}
        />

        <Content />

        <Preview
          preview={this.props.showPreview}
          togglePreview={this.togglePreview}
        />

        <Setup />
        <SetupNavigation
          preview={this.props.showPreview}
          togglePreview={this.togglePreview}
          status={1}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    showPreview: getPreviewState(state),
    showSidebar: getSidebarState(state),
  }
}

export default connect(mapStateToProps)(Dashboard)
