import Heatmap from './views/Heatmap'
import Stats from './views/Stats'
import Users from './views/Users'
import TopArtists from './views/TopArtists'
import TopSessions from './views/TopSessions'
import TopVenues from './views/TopVenues'
import Notifications from './views/Notifications'

export const push = {
  path: '/push',
  label: 'Notifications',
  component: Notifications,
}

export const stats = { path: '/stats', label: 'Stats', component: Stats }

export const users = { path: '/users', label: 'Users', component: Users }

export const heatmap = {
  path: '/heatmap',
  label: 'Heatmap',
  component: Heatmap,
}

export const places = { path: '/places', label: 'Places', component: Heatmap }

export const topartists = {
  path: '/top/artists',
  label: 'Top Artists',
  component: TopArtists,
}
export const topsessions = {
  path: '/top/sessions',
  label: 'Top Sessions',
  component: TopSessions,
}
export const topvenues = {
  path: '/top/venues',
  label: 'Top Venues',
  component: TopVenues,
}

export const analytics = [heatmap, users]
export const toplists = [topartists, topsessions, topvenues]
export const notifications = [push]
