import React from 'react'

import styles from './Checkbox.module.css'

const Checkbox = ({ className, style, ...props }) => (
  <label className={styles.checkbox} style={style}>
    <input className={styles.input} type="checkbox" {...props} />
    <div className={styles.label}>
      <svg focusable="false" version="1.1" viewBox="0 0 24 24">
        <path d="M4.1,12.7 9,17.6 20.3,6.3" fill="none" stroke="white" />
      </svg>
    </div>
  </label>
)

export default Checkbox
