import * as actions from './actions'

import { getTypeList, postItem, deleteItem, sortItems } from '../../api'
import postMultipartData from '../../api/multipart'
import { arrayMove } from '../../utils'

import {
  getLastOfType,
  normalize,
  transformItem,
  flattenMediaObject,
} from '../../utils/normalize'

// Operations
export function fetchTypeList(type) {
  return async (dispatch) => {
    try {
      dispatch(actions.fetchItemsRequest())

      const res = await getTypeList(type).then(normalize)

      dispatch(actions.fetchItemsSuccess(res, type))

      return res
    } catch (error) {
      dispatch(actions.fetchItemsFailure(error.message))
      throw error
    }
  }
}

export function fetchLastItem(type) {
  return async (dispatch) => {
    try {
      dispatch(actions.fetchItemsRequest())

      const res = await getTypeList(type)
        .then(getLastOfType)
        .then(transformItem)

      dispatch(actions.fetchItemsSuccess(res, type))

      return res
    } catch (error) {
      dispatch(actions.fetchItemsFailure(error.message))
      throw error
    }
  }
}

export function addAppChild(type, data, media) {
  const upload = flattenMediaObject(media)
  console.log('addAppChild', data, upload)

  return async (dispatch) => {
    try {
      dispatch(actions.addItemRequest())

      const post = {
        type,
        isActive: true, // makes item "active" by default
        ...data, // overwrite isActive from ...data object
      }

      const res = await postMultipartData(post, upload).then(transformItem)
      // const res = await postItem({ type, ...data }).then(transformItem)

      dispatch(actions.addItemSuccess(res))

      return res
    } catch (error) {
      dispatch(actions.addItemFailure(error.message))
      throw error
    }
  }
}

export function updateAppChild(item, data, media, remove) {
  const upload = flattenMediaObject(media)
  console.log('updateAppChild', item, data, upload, remove)

  return async (dispatch) => {
    try {
      dispatch(actions.updateItemRequest())

      const post = {
        type: item.object,
        id: item.id,
        ...data,
      }

      const res = await postMultipartData(post, upload, remove).then(
        transformItem
      )

      dispatch(actions.updateItemSuccess(res))

      return res
    } catch (error) {
      dispatch(actions.updateItemFailure(error.message))
      throw error
    }
  }
}

export function hideAppChild(item, state) {
  console.log('hideAppChild', item, state)

  return async (dispatch, getState) => {
    try {
      dispatch(actions.updateItemRequest())

      const res = await postItem({
        type: item.object,
        id: item.id,
        ...item.data,
        isActive: state,
      }).then(transformItem)

      dispatch(actions.updateItemSuccess(res))

      return res
    } catch (error) {
      dispatch(actions.updateItemFailure(error.message))
      throw error
    }
  }
}

export function protectAppChild(item, state) {
  console.log('protectAppChild', item, state)

  return async (dispatch, getState) => {
    try {
      dispatch(actions.updateItemRequest())

      const res = await postItem({
        type: item.object,
        id: item.id,
        ...item.data,
        isProtected: state,
      }).then(transformItem)

      dispatch(actions.updateItemSuccess(res))

      return res
    } catch (error) {
      dispatch(actions.updateItemFailure(error.message))
      throw error
    }
  }
}

export function deleteAppChild(item) {
  console.log('deleteAppChild', item)

  return async (dispatch, getState) => {
    try {
      dispatch(actions.deleteItemRequest())

      const res = await deleteItem(item.object, item.id)

      dispatch(actions.deleteItemSuccess(item.object, item.id))

      return res
    } catch (error) {
      dispatch(actions.deleteItemFailure(error.message))
      throw error
    }
  }
}

export function sortAppItems(type, oldIndex, newIndex, items) {
  console.log('sortAppItems', type, oldIndex, newIndex)

  return async (dispatch, getState) => {
    try {
      dispatch(actions.sortItemsRequest())

      const sorted = arrayMove(items, oldIndex, newIndex)
      dispatch(actions.sortItemsSuccess(type, sorted))
      const res = await sortItems(type, sorted)

      return res
    } catch (error) {
      dispatch(actions.sortItemsFailure(error.message))
      return error
    }
  }
}

export function clearAppData() {
  return actions.clearAppData()
}
