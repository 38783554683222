import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import { RadioButton } from '../../../ui/inputs'

export const RadioDemo = (props) => {
  return (
    <>
      <FormRow label="Radio reverse justify">
        <RadioButton name="system_radio" label="Option 1" reverse justify />
        <RadioButton name="system_radio" label="Option 2" reverse justify />
        <RadioButton name="system_radio" label="Option 3" reverse justify />
      </FormRow>
      <FormRow label="Radio">
        <RadioButton name="system_radio" label="Option 1" />
        <RadioButton name="system_radio" label="Option 2" />
        <RadioButton name="system_radio" label="Option 3" />
      </FormRow>
      <FormRow label="Radio reverse">
        <RadioButton name="system_radio" label="Option 1" reverse />
        <RadioButton name="system_radio" label="Option 2" reverse />
        <RadioButton name="system_radio" label="Option 3" reverse />
      </FormRow>
      <FormRow label="Radio inline">
        <RadioButton name="system_radio" label="Option 1" inline />
        <RadioButton name="system_radio" label="Option 2" inline />
        <RadioButton name="system_radio" label="Option 3" inline />
      </FormRow>
      <FormRow label="Radio inline reverse">
        <RadioButton name="system_radio" label="Option 1" inline reverse />
        <RadioButton name="system_radio" label="Option 2" inline reverse />
        <RadioButton name="system_radio" label="Option 3" inline reverse />
      </FormRow>
    </>
  )
}

export default RadioDemo
