import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Editable from '../../../components/Editable'

import styles from './SortableMenu.module.css'
import themes from '../../../ui/Themes.module.css'

const AppMenuItem = ({ item, ...props }) => {
  const hidden = {
    ...(!item.active && { display: 'none' }),
  }

  return (
    <div className={`${styles.item} ${themes.menuItem}`} style={hidden}>
      <Editable
        name={item.name}
        value={item.menuName}
        onChange={props.renameMenuItem}
      >
        <div>{item.menuName}</div>
      </Editable>
      <div className={styles.icon} onClick={props.toggleVisibility}>
        <FontAwesomeIcon icon={item.visible ? 'eye' : 'eye-slash'} />
      </div>
    </div>
  )
}

export default AppMenuItem
