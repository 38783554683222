import React from 'react'
import {
  // Switch,
  // Route,
  withRouter,
} from 'react-router-dom'

import { connect } from 'react-redux'
import { getAppType } from '../../store/app'
import { getTempType } from '../../store/temp'
import TYPE from '../../api/types'

import AppNavBar from './AppNavBar'
import AppMenu from './AppMenu'

import styles from './App.module.css'

import { Routes } from './AppRoutes'

class App extends React.Component {
  state = {
    showMenu: false,
  }

  static defaultProps = {
    settings: {
      data: {},
      media: {},
    },
    design: {
      data: {},
      media: {},
    },
  }

  toggleMenu = (ev) => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  render() {
    let { design, settings, tempDesign, tempSettings } = this.props

    design = tempDesign ? tempDesign.state : design.data
    settings = tempSettings ? tempSettings.state : settings.data

    const title =
      tempSettings || tempDesign ? 'Festivality Draft' : 'Festivality Live'

    return (
      <div className={styles.app}>
        <div className={styles.statusbar} />
        <AppNavBar title={title} style={design} toggleMenu={this.toggleMenu} />
        <div
          className={styles.body}
          style={{
            color: design && design.textColor,
            backgroundColor: design && design.backgroundColor,
          }}
        >
          <Routes match={this.props.match} />
        </div>
        <AppMenu
          menu={(settings && settings.menu) || []}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          style={design}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const design = getAppType(state, TYPE.APP_DESIGN)
  const settings = getAppType(state, TYPE.APP_SETTINGS)
  const tempDesign = getTempType(state, TYPE.APP_DESIGN)
  const tempSettings = getTempType(state, TYPE.APP_SETTINGS)

  return {
    design,
    settings,
    tempDesign,
    tempSettings,
  }
}

export default withRouter(connect(mapStateToProps)(App))
