import TYPE from '../../../api/types'

import partnersModel from '../../tables/partnersModel'
import { PartnerModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.PARTNER,
  title: 'Partners',
  table: partnersModel,
  modal: {
    header: 'Edit Partner',
    component: PartnerModal,
  },
}

export default pageFactory(page)
