import React from 'react'
import { connect } from 'react-redux'
import { getWallpaper } from '../store/ui'
import { Switch, Route, Redirect } from 'react-router-dom'

import './App.css'

import Home from './pages/Home'
import Login from './pages/login/Login'
import Logout from './pages/login/Logout'

const App = ({ session, ...props }) => {
  return (
    <div className="app" style={{ backgroundImage: `url(${props.wallpaper})` }}>
      {session.checked && (
        <Switch>
          {session.authenticated ? (
            <Route path="/logout" component={Logout} />
          ) : (
            <Redirect from="/logout" to="/login" />
          )}
          <Route
            path="/:path(login|join|reset|logout)"
            render={(props) =>
              !session.authenticated ? (
                <Login {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/"
            render={(props) =>
              session.authenticated ? (
                <Home {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        </Switch>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    session: state.session,
    wallpaper: getWallpaper(state),
  }
}

export default connect(mapStateToProps)(App)
