import React from 'react'

import Menu from '../components/Menu'
import Routes from '../components/Routes'

import {
  heatmap,
  users,
  topartists,
  topsessions,
  topvenues,
  push,
  stats,
} from './routes'

const analytics = [heatmap, users]
const toplists = [topartists, topsessions, topvenues]
const notifications = [push]
const routes = [...analytics, ...toplists, ...notifications]

export const OverviewMenu = ({ match }) => (
  <>
    <Menu label="Analytics" routes={analytics} url={match.url} />
    <Menu label="Top Lists" routes={toplists} url={match.url} />
    <Menu label="Notifications" routes={notifications} url={match.url} />
  </>
)

export const OverviewRoutes = ({ match }) => (
  <Routes match={match} routes={routes} exact={stats.component} />
)

export default OverviewRoutes
