import React, { useState } from 'react'
import {
  SortableElement,
  SortableContainer,
  arrayMove,
} from 'react-sortable-hoc'

import AppMenuItem from './AppMenuItem'

import styles from './SortableMenu.module.css'
import themes from '../../../ui/Themes.module.css'

import menuItems from './menuItems.json'

const SortableItem = SortableElement((props) => props.children)
const SortableList = SortableContainer((props) => props.children)

const settings = {
  helperClass: themes.active,
  distance: 2,
  lockAxis: 'y',
  lockToContainerEdges: true,
  lockOffset: 0,
}

const SortableMenu = ({ name, value, items, setProperty }) => {
  const [sorting, isSorting] = useState(false)

  const onSortStart = ({ node, index, collection }, ev) => {
    ev.preventDefault()
    isSorting(true)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const menu = arrayMove(items, oldIndex, newIndex)
    setProperty(name, menu)
    isSorting(false)
  }

  const menu_settings = sorting
    ? `${styles.container} ${styles.sorting}`
    : styles.container

  const renameMenuItem = (ev) => {
    const { name, value } = ev.target

    const renamed = items.map((item, i) => {
      if (item.name === name) {
        item.menuName = value
      }

      return item
    })

    setProperty(name, renamed)
  }

  const toggleVisibility = (itemName) => (ev) => {
    const updated = items.map((item) => {
      if (item.name === itemName) {
        item.visible = !item.visible
      }

      return item
    })

    setProperty(name, updated)
  }

  return (
    <SortableList {...settings} onSortStart={onSortStart} onSortEnd={onSortEnd}>
      <div className={menu_settings}>
        {items.map((item, i) => (
          <SortableItem index={i} key={`item-${i}`}>
            <AppMenuItem
              item={item}
              renameMenuItem={renameMenuItem}
              toggleVisibility={toggleVisibility(item.name)}
            />
          </SortableItem>
        ))}
      </div>
    </SortableList>
  )
}

SortableMenu.defaultProps = {
  items: menuItems,
}

export default SortableMenu
