import React from 'react'
import { NavLink } from 'react-router-dom'

import logo from '../../../images/festivality-bird.svg'

const LogoLink = (props) => {
  return (
    <NavLink className="logo" to="/">
      <img src={logo} height="30" alt="logo" />
    </NavLink>
  )
}

export default LogoLink
