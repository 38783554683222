import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import { Heading } from '../../../ui'
import Badge from '../../../ui/Badge'

const BadgeDemo = (props) => {
  return (
    <>
      <FormRow label="Default">
        <Heading size="lg">
          Lani <Badge text="new" />
        </Heading>
      </FormRow>
      <FormRow label='Position "top"'>
        <Heading size="lg">
          Lani <Badge text="new" position="top" />
        </Heading>
      </FormRow>
    </>
  )
}

export default BadgeDemo
