import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { getAppType } from '../../../../store/app'
import { getTempType } from '../../../../store/temp'
import TYPE from '../../../../api/types'

import { sections, order as initialOrder } from './sections'
import { mapObjectToArray } from '../../../../utils'

const mapSections = (order) => mapObjectToArray(sections, order)

function getOrder(settings, tempSettings) {
  if (settings || tempSettings) {
    const order = tempSettings ? tempSettings.state.order : settings.data.order
    return order || initialOrder
  }
  return initialOrder
}

const Frontpage = ({ settings, tempSettings, ...props }) => {
  const order = getOrder(settings, tempSettings)
  const items = useMemo(() => mapSections(order), [order])

  return (
    <div>
      {items.map((section, i) => {
        return React.createElement(section, { key: i })
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  const settings = getAppType(state, TYPE.APP_FRONTPAGE)
  const tempSettings = getTempType(state, TYPE.APP_FRONTPAGE)

  return {
    settings,
    tempSettings,
  }
}

export default connect(mapStateToProps)(Frontpage)
