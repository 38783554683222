import React from 'react'

import FormRow from '../components/FormRow'
import { Input, TextArea, FileInput } from '../../ui/inputs'

import Avatar from '../../ui/Avatar'
import touch from '../../ui/Touch.module.css'
import placeholder from '../../../images/DUFQorXXUAU-Ncv.jpg'

const ProfileForm = ({ media, bindInput, handleUpload }) => {
  function getImage(media) {
    return media.image ? media.image[0] : placeholder
  }

  return (
    <>
      <FormRow
        label={
          <FileInput name="image" onChange={handleUpload}>
            <Avatar
              className={touch.touch}
              src={getImage(media)}
              alt="avatar"
            />
          </FileInput>
        }
      >
        <Input
          style={{ marginBottom: '8px' }}
          placeholder="Name"
          {...bindInput('name')}
        />
        <TextArea
          rows="3"
          maxLength={160}
          placeholder="About"
          {...bindInput('about')}
        />
      </FormRow>
      <FormRow label="Phone Number">
        <Input placeholder="+372 44 123 45 67" {...bindInput('phone')} />
      </FormRow>
      <FormRow label="Company Name">
        <Input placeholder="Company Inc." {...bindInput('company')} />
      </FormRow>
      <FormRow label="Company Address">
        <Input placeholder="Street, City" {...bindInput('companyAddress')} />
      </FormRow>
      <FormRow label="Company Website">
        <Input placeholder="www.company.com" {...bindInput('companyWebsite')} />
      </FormRow>
    </>
  )
}

export default ProfileForm
