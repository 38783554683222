import * as types from './types'

// Actions creators
export function togglePreview(state) {
  return { type: types.TOGGLE_PREVIEW, state }
}

export function toggleSidebar(state) {
  return { type: types.TOGGLE_SIDEBAR, state }
}

export function updateButtons(state) {
  return { type: types.UPDATE_BUTTONS, state }
}

export function changeWallpaper() {
  return { type: types.CHANGE_WALLPAPER }
}
