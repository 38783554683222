import React from 'react'

import TableRow from './base/TableRow'
import Shimmer from '../../ui/Shimmer'

import './TablePlaceholder.css'

const TablePlaceholder = ({ children, ...props }) => {
  return (
    <TableRow>
      <Shimmer>
        <div className="table-placeholder">
          <div className="placeholder-circle" />
          <div
            className="placeholder-object"
            style={{ height: '14px', width: '140px', marginLeft: '8px' }}
          />
        </div>
      </Shimmer>
    </TableRow>
  )
}

export default TablePlaceholder
