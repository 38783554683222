import React from 'react'

import Popup from '../ui/Popup'
import PopupTabs from '../ui/PopupTabs'
import Spinner from '../ui/Spinner'

import ProfileForm from '../forms/profile/ProfileForm'
import PasswordForm from '../forms/profile/PasswordForm'
import BillingForm from '../forms/profile/BillingForm'
import CredentialsForm from '../forms/profile/CredentialsForm'

import {
  fetchUserProfile,
  getProfileData,
  getProfileMedia,
} from '../../store/user'

import AbstractDataFetcher from '../components/AbstractDataFetcher'
import NoMatch from '../pages/NoMatch'
import { Button } from '../ui/inputs'
import Forma from '../components/Forma'
import ProfileFormActions from '../forms/components/ProfileFormActions'

const ProfileModal = ({ hideModal }) => {
  return (
    <AbstractDataFetcher fetch={fetchUserProfile} map={mapStateToProps}>
      {({ loading, error, props, retry }) => {
        if (loading || props.data === undefined) {
          return (
            <Popup header="Profile Settings" hideModal={hideModal}>
              <Spinner show={loading} />
            </Popup>
          )
        } else if (error) {
          return (
            <Popup header="Profile Settings" hideModal={hideModal}>
              <NoMatch>
                <Button onClick={retry}>Try Again</Button>
              </NoMatch>
            </Popup>
          )
        } else {
          return (
            <Forma initialState={props.data} initialMedia={props.media}>
              {(forma) => (
                <Popup header="Profile Settings" hideModal={hideModal}>
                  <form onBlur={forma.handleBlur}>
                    <PopupTabs
                      tabs={[
                        {
                          icon: 'user',
                          label: 'Profile',
                          component: () => <ProfileForm {...forma} />,
                        },
                        {
                          icon: 'key',
                          label: 'Email & Password',
                          component: () => <PasswordForm {...forma} />,
                        },
                        {
                          icon: 'credit-card',
                          label: 'Billing',
                          component: () => <BillingForm {...forma} />,
                        },
                        {
                          icon: 'code',
                          label: 'Credentials',
                          component: () => <CredentialsForm {...forma} />,
                        },
                      ]}
                    />

                    <ProfileFormActions
                      state={forma.state}
                      media={forma.media}
                      hideModal={hideModal}
                    />
                  </form>
                </Popup>
              )}
            </Forma>
          )
        }
      }}
    </AbstractDataFetcher>
  )
}

function mapStateToProps(state) {
  return {
    data: getProfileData(state),
    media: getProfileMedia(state),
  }
}

export default ProfileModal
