import React from 'react'

import SearchInput from '../../../ui/SearchInput'

import Table from '../../../components/Table'
import usersModel from '../../../tables/usersModel'
import { Header, Box } from '../../../ui/layout'

const Users = (props) => (
  <div>
    <Header>
      <Box paddingX={2} flexGrow={1}>
        <SearchInput />
      </Box>
    </Header>

    <Table model={usersModel} />
  </div>
)

export default Users
