import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import { Input } from '../../../ui/inputs'

const TextInputDemo = (props) => {
  return (
    <>
      <FormRow label="Default">
        <Input />
      </FormRow>
      <FormRow label="Disabled">
        <Input disabled />
      </FormRow>
      <FormRow label="Input Error">
        <Input />
      </FormRow>
    </>
  )
}

export default TextInputDemo
