import React from 'react'

const RadioGroup = ({ children, ...props }) => {
  const { value, ...inputProps } = props
  return (
    <div>
      {React.Children.map(children, (child) => {
        const checked = child.props.value === value
        return React.cloneElement(child, { ...inputProps, checked })
      })}
    </div>
  )
}

export default RadioGroup
