import React from 'react'
import DateTime from 'react-datetime'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import './DateTime.css'

import styles from './base/Input.module.css'

const DateTimePicker = ({
  onChange,
  className = `${styles.base} ${styles.input}`,
  placeholder,
  readOnly,
  name,
  value,
  ...props
}) => {
  const handleChange = (date) => {
    if (typeof ev === 'string') {
      return
    }

    const value = moment(date._d)
      .utc()
      .format()

    const target = { name, value }

    if (onChange) {
      onChange({ target })
    }
  }

  let formatted
  if (value) {
    formatted = moment(value).format(`${props.dateFormat} ${props.timeFormat}`)
  }

  return (
    <DateTime
      inputProps={{
        className,
        placeholder,
        // readOnly
      }}
      value={formatted}
      onChange={handleChange}
      // utc={true}
      // locale={'en-us'}
      {...props}
    />
  )
}

DateTimePicker.defaultProps = {
  dateFormat: 'DD.MM.YYYY',
  timeFormat: 'HH:mm',
}

export default DateTimePicker
