import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'
import FormSocials from '../components/FormSocials'

import { Input, TextArea, FormFiles, SelectCountry } from '../../ui/inputs'

export const PartnerForm = ({
  media,
  bindInput,
  handleUpload,
  handleDelete,
}) => {
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Partner Images">
        <FormFiles
          files={media.image}
          name="image"
          onChange={handleUpload}
          onDelete={handleDelete}
        />
      </FormRow>

      <FormRow label="Name">
        <Input placeholder="Name" {...bindInput('title')} />
      </FormRow>

      <FormRow label="Country">
        <SelectCountry {...bindInput('country')} />
      </FormRow>

      <FormRow label="Company">
        <Input placeholder="Company Name" {...bindInput('company')} />
      </FormRow>

      <FormRow label="Email">
        <Input placeholder="Email" {...bindInput('email')} />
      </FormRow>

      <FormRow label="About partner" inline={false}>
        <TextArea placeholder="About partner" {...bindInput('description')} />
      </FormRow>

      <FormSocials bindInput={bindInput} />
    </>
  )
}

export default PartnerForm
