import * as React from 'react'
import { createPortal } from 'react-dom'

export default class Layer extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    if (document.body) {
      document.body.appendChild(this.el)
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeChild(this.el)
    }
  }

  render() {
    const { children } = this.props
    return createPortal(children, this.el)
  }
}
