import * as actions from './actions'

import {
  register,
  getUserCompanies,
  getUserProfile,
  updateUserProfile,
} from '../../api'

export function fetchUserCompanies() {
  console.log('fetchUserCompanies')

  return async (dispatch) => {
    try {
      dispatch(actions.fetchCompaniesRequest())

      const res = await getUserCompanies()
      dispatch(actions.fetchCompaniesSuccess(res))

      return res
    } catch (error) {
      dispatch(actions.fetchCompaniesFailure(error.message))
      throw error
    }
  }
}

export function fetchUserProfile() {
  console.log('fetchUserProfile')

  return async (dispatch) => {
    try {
      dispatch(actions.fetchProfileRequest())

      const res = await getUserProfile()
      dispatch(actions.fetchProfileSuccess(res))

      return res
    } catch (error) {
      dispatch(actions.fetchProfileFailure(error.message))
      throw error
    }
  }
}

export function updateUserData(data, media) {
  console.log('updateUserData', data, media)

  return async (dispatch) => {
    try {
      dispatch(actions.updateProfileRequest())

      const res = await updateUserProfile(data)
      dispatch(actions.updateProfileSuccess(res))

      return res
    } catch (error) {
      dispatch(actions.updateProfileFailure(error.message))
      throw error
    }
  }
}

export function createNewApp(data, media) {
  console.log('createNewApp', data)

  return async (dispatch) => {
    try {
      dispatch(actions.fetchCompaniesRequest())

      const res = await register({})
      dispatch(actions.createApp(res))

      return res
    } catch (err) {
      console.error(err)
    }
  }
}

export function deleteApp(id) {
  return actions.deleteApp(id)
}

export function sortApps(oldIndex, newIndex) {
  return actions.sortApps(oldIndex, newIndex)
}
