import { getAuthToken } from './storage'

export function getHeaders() {
  const headers = {
    'Content-Type': 'application/json',
  }

  const token = getAuthToken()
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}
