import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import { Checkbox } from '../../../ui/inputs'

export const CheckboxDemo = (props) => {
  return (
    <>
      <FormRow label="Checkbox">
        <Checkbox name="check" label="Option" />
      </FormRow>
      <FormRow label="Checkbox reverse">
        <Checkbox name="check" label="Option" reverse />
      </FormRow>
      <FormRow label="Checkbox inline">
        <Checkbox name="check" label="Option" inline />
      </FormRow>
      <FormRow label="Checkbox inline reverse">
        <Checkbox name="check" label="Option" inline reverse />
      </FormRow>
      <FormRow label="Checkbox reverse justify">
        <Checkbox name="check" label="Option" reverse justify />
      </FormRow>
    </>
  )
}

export default CheckboxDemo
