import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import { TextArea } from '../../../ui/inputs'

const TextareaDemo = (props) => {
  return (
    <>
      <FormRow label="Default">
        <TextArea />
      </FormRow>
      <FormRow label="With Counter">
        <TextArea maxLength="140" />
      </FormRow>
      <FormRow label="With Errors">
        <TextArea />
      </FormRow>
      <FormRow label="Disabled">
        <TextArea disabled />
      </FormRow>
    </>
  )
}

export default TextareaDemo
