import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GoogleMapReact from '../../components/GoogleMapReact'

import FormRow from './FormRow'
import { Box } from '../../ui/index'
import { Input, Button, InputPlace } from '../../ui/inputs'

class FormLocation extends React.Component {
  state = {
    showMap: false,
  }

  toggleMap = (ev) => {
    this.setState({ showMap: !this.state.showMap })
  }

  render() {
    const { state, bindInput, handleChange, setProperty } = this.props
    return (
      <>
        <FormRow label="Venue address">
          <Box display="flex">
            <InputPlace
              placeholder="Search Place"
              {...bindInput('address')}
              onSelect={({ address, lat, lng }) =>
                setProperty('coordinates', { lat, lng })
              }
            />
            <Button
              square
              onClick={this.toggleMap}
              style={{ marginLeft: '8px' }}
            >
              <FontAwesomeIcon icon="map-marker-alt" />
            </Button>
          </Box>
        </FormRow>
        {state.coordinates && (
          <FormRow label="Coordinates">
            <div className="flex">
              <Input placeholder="Latitude" {...bindInput('coordinates.lat')} />
              <Input
                style={{ marginTop: '0', marginLeft: '8px' }}
                placeholder="Longitude"
                {...bindInput('coordinates.lng')}
              />
            </div>
          </FormRow>
        )}
        {this.state.showMap ? (
          <Box height="300px">
            <GoogleMapReact
              name="coordinates"
              onChange={handleChange}
              center={state.coordinates}
            />
          </Box>
        ) : null}
      </>
    )
  }
}

export default FormLocation
