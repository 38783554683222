import React from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

import Input from './base/Input'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './InputPlace.css'

const Suggestion = ({ suggestion, getSuggestionItemProps }) => {
  const { formattedSuggestion } = suggestion
  return (
    <div {...getSuggestionItemProps(suggestion)} className="suggestion-item">
      <FontAwesomeIcon
        icon="map-marker-alt"
        size={'xs'}
        style={{ marginRight: '8px' }}
      />
      {/* <span>{suggestion.description}</span> */}
      <strong>{formattedSuggestion.mainText}</strong>
      <small style={{ marginLeft: '8px', color: '#555' }}>
        {formattedSuggestion.secondaryText}
      </small>
    </div>
  )
}

const onError = (status, clearSuggestions) => {
  console.log(
    'Error happened while fetching suggestions from Google Maps API',
    status
  )
  clearSuggestions()
}

class InputPlace extends React.Component {
  onChange = ({ address, lat, lng }) => {
    const event = {
      target: {
        name: this.props.name,
        value: address,
      },
    }
    this.props.onChange(event)
  }

  handleChange = (address) => {
    // console.log(address)
    // this.setState({ address })
    this.onChange({ address })
  }

  handleSelect = (address) => {
    // this.setState({ loading: true })
    // geocodeByAddress(address, (err, { lat, lng }, results) => {
    //   if (err) {
    //     return
    //   }
    //
    //   console.log(`Yay, got lat and lng for ${address}`, { lat, lng })
    //   console.log('Also, the whole API payload!', results)
    // })

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('Geocode Success', { address, lat, lng })
        // this.setState({ loading: false })
        this.onChange({ address, lat, lng })
        this.props.onSelect({ address, lat, lng })
      })
      .catch((error) => {
        console.log('Geocode Error', { address, error })
        // this.setState({ loading: false })
      })
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: 'relative', width: '100%' }}>
            <Input
              {...getInputProps({
                placeholder: this.props.placeholder || 'Search Place',
              })}
            />
            <div className="input-place-suggestions">
              {loading && <div className="suggestion-item">Loading...</div>}
              {suggestions.map((suggestion) => (
                <Suggestion
                  suggestion={suggestion}
                  getSuggestionItemProps={getSuggestionItemProps}
                  key={suggestion.placeId}
                />
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default InputPlace
