import TYPE from '../../../../api/types'

import { NotificationModal } from '../../../modals'
import notificationsModel from '../../../tables/notificationsModel'

import pageFactory from '../../../factories/pageFactory'

const page = {
  type: TYPE.NOTIFICATION,
  title: 'Notifications',
  table: notificationsModel,
  modal: {
    header: 'Edit Notification',
    component: NotificationModal,
  },
}

export default pageFactory(page)
