import React from 'react'

import { Box } from '../../ui/layout'
import { Heading } from '../../ui'

const MenuLabel = ({ text }) => {
  return (
    <Box height="40px" lineHeight="40px" padding="0 16px">
      <Heading size="md">{text}</Heading>
    </Box>
  )
}

export default MenuLabel
