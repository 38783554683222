import React from 'react'

import styles from './Text.module.css'
import typography from './Typography.module.css'

const Text = ({
  size = 'md',
  bold,
  align,
  italic,
  truncate,
  overflow,
  inline,
  className,
  children,
  ...props
}) => {
  const cls = [
    styles[size],
    align && typography[align],
    typography.lineHeight,
    bold && typography.bold,
    italic && typography.italic,
    truncate && typography.truncate,
    overflow && typography.overflow,
    className,
  ]
    .filter((c) => c)
    .join(' ')
    .trim()

  const Tag = inline ? 'span' : 'div'

  return (
    <Tag className={cls} {...props}>
      {children}
    </Tag>
  )
}

export default Text
