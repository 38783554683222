import React, { useRef, useState, useEffect } from 'react'
import {
  useSpring,
  animated as a,
  // config
} from 'react-spring'

import styles from './SpringSlider.module.css'

const SpringSlider = ({ slides, children, config, ...props }) => {
  const [immediate, isImmediate] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [offset, setOffset] = useState(0)
  const [height, setHeight] = useState(0)
  const slider = useRef()

  const spring = useSpring({
    to: { width: offset, height },
    config: config,
    immediate: immediate,
  })

  const prevSlide = (ev) => {
    if (currentSlide === 0) return
    const index = currentSlide - 1

    goToSlide(index)
  }

  const nextSlide = (ev) => {
    const total = slider.current.children.length
    if (currentSlide === total - 1) return
    const index = currentSlide + 1

    goToSlide(index)
  }

  const goToSlide = (i) => {
    const { offsetWidth } = slider.current
    const item = slider.current.children[i]
    const offset = 0 - offsetWidth * i
    const height = item.offsetHeight

    setOffset(offset)
    setHeight(height)
    setCurrentSlide(i)
    isImmediate(false)
  }

  useEffect(() => {
    if (slides.length && currentSlide === slides.length) {
      goToSlide(slides.length - 1)
    }
  }, [slides, currentSlide])

  useEffect(() => {
    const offsetHeight = slider.current.children[0].offsetHeight
    setHeight(offsetHeight)
    // setMounted(true)
  }, [])

  if (props.handlers) {
    props.handlers({ prevSlide, nextSlide, goToSlide, currentSlide })
  }

  return (
    <>
      <a.div
        className={styles.slider}
        style={{
          height: spring.height.interpolate((h) => `${h}px`),
        }}
      >
        <a.div
          className={styles.slides}
          style={{
            transform: spring.width.interpolate((w) => `translateX(${w}px)`),
          }}
          ref={slider}
        >
          {slides || children}
        </a.div>
      </a.div>
      {slides &&
        children &&
        children({
          prevSlide,
          nextSlide,
          goToSlide,
          currentSlide,
          slides,
        })}
    </>
  )
}

export default SpringSlider
