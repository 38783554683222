import React from 'react'
import cc from 'classcat'

import styles from './Label.module.css'

const Label = ({
  className,
  reverse,
  inline,
  justify,
  style,
  label,
  children,
}) => {
  return (
    <label
      className={cc({
        [styles.label]: true,
        [styles.inline]: inline,
        [styles.reverse]: reverse,
        [styles.justify]: justify,
        className,
      })}
      style={style}
    >
      {children}
      {label && <div className={styles.text}>{label}</div>}
    </label>
  )
}

export default Label
