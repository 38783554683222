import React from 'react'

const FileInput = ({ children, ...props }) => {
  return (
    <label>
      <input hidden type="file" {...props} />
      {children}
    </label>
  )
}

export default FileInput
