import React from 'react'
import { withRouter } from 'react-router-dom'

// import { logout } from '../../store/session'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoundButton from '../ui/RoundButton'

const LogoutButton = (props) => {
  function handleClick(ev) {
    if (window.confirm('Log out?')) {
      props.history.push('/logout')
    }
  }

  return (
    <RoundButton onClick={handleClick}>
      <FontAwesomeIcon icon="power-off" />
    </RoundButton>
  )
}

export default withRouter(LogoutButton)
