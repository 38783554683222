import React from 'react'

import { Toggle } from '../../ui/inputs'
import { Box, Text } from '../../ui/index'
import FormFooter from './FormFooter'
import FormButton from './FormButton'

const PopupFormActions = ({
  state,
  handleChange,

  item,
  deleteItem,
  saveAndAddNew,
  saveAndClose,
}) => {
  const { id, isActive = true } = item
  const active = state.isActive !== undefined ? state.isActive : isActive

  return (
    <FormFooter>
      {id && <FormButton onClick={deleteItem}>Delete</FormButton>}

      <label>
        <Box paddingX={6} display="flex" alignItems="center">
          <Toggle name="isActive" checked={!!active} onChange={handleChange} />
          <Text size="sm" bold style={{ marginLeft: 8 }}>
            Active
          </Text>
        </Box>
      </label>

      <Box display="flex" height={60}>
        {!id && (
          <FormButton onClick={saveAndAddNew}>Save and add new</FormButton>
        )}
        <FormButton type="submit" onClick={saveAndClose}>
          Save and close
        </FormButton>
      </Box>
    </FormFooter>
  )
}

export default PopupFormActions
