import React from 'react'

import './Box.css'

const Box = ({ paddingX, paddingY, children, ...props }) => {
  const cs = [
    'box',
    paddingX && `box--padding-x-${paddingX}`,
    paddingY && `box--padding-y-${paddingY}`,
  ]
    .filter((c) => c)
    .join(' ')
    .trim()
  return (
    <div className={cs} style={{ ...props }}>
      {children}
    </div>
  )
}

export default Box
