export function compose(..._fns) {
  const [fn, ...fns] = _fns.reverse()
  return (...args) => {
    return fns.reduce((acc, f) => f(acc), fn(...args))
  }
}

export function roundTo(val, num) {
  const resto = val % num
  if (resto <= num / 2) {
    return val - resto
  } else {
    return val + num - resto
  }
}

export function createObjectURL(file) {
  return window.URL.createObjectURL(file)
}

export function getIdFromObjectKeys(obj, prefix = '') {
  const ids = Object.keys(obj)
  if (ids.length) {
    const lastId = ids[ids.length - 1]
    const matches = lastId.match(/\d+$/)
    return `${prefix}${++matches[0]}`
  } else {
    return `${prefix}1`
  }
}

export function arrayMove(arr, previousIndex, newIndex) {
  const array = arr.slice(0)
  if (newIndex >= array.length) {
    var k = newIndex - array.length
    while (k-- + 1) {
      array.push(undefined)
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0])
  return array
}

export function objectMove(obj, oldIndex, newIndex) {
  const object = { ...obj }
  for (let prop in object) {
    if (object.hasOwnProperty(prop)) {
      const i = object[prop].index
      if (i === oldIndex) {
        object[prop].index = newIndex
      } else if (i === newIndex) {
        object[prop].index = oldIndex
      }
    }
  }
  return object
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function reverseString(str) {
  return str
    .split('')
    .reverse()
    .join('')
}

// export function stringToColour(str) {
//   var hash = 0;
//   for (var i = 0; i < str.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash);
//   }
//   console.log(hash);
//   var colour = '#';
//   for (var i = 0; i < 3; i++) {
//     var value = (hash >> (i * 8)) & 0xFF;
//     colour += ('00' + value.toString(16)).substr(-2);
//   }
//   return colour;
// }

// export function randomGradient(name) {
//   const color1 = stringToColour(name)
//   const color2 = stringToColour(reverseString(name))
//   // const color1 = `rgb(${getRandomInt(0,255)},${getRandomInt(0,255)},${getRandomInt(0,255)})`
//   // const color2 = `rgb(${getRandomInt(0,255)},${getRandomInt(0,255)},${getRandomInt(0,255)})`
//   return {
//     color: '#fff',
//     backgroundImage: `linear-gradient(${getRandomInt(
//       0,
//       360,
//     )}deg, ${color1}, ${color2})`,
//   }
// }

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

export function isNotEmpty(obj) {
  return Object.keys(obj).length > 0
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export function destructureDate(date) {
  const lng = window.navigator.language || 'en-us'

  const d = new Date(date)
  const day = d.toLocaleString(lng, { day: 'numeric' })
  const weekday = d.toLocaleString(lng, { weekday: 'short' })
  const month = d.toLocaleString(lng, { month: 'short' })
  const time = d.toLocaleString(lng, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
  })
  return { day, weekday, month, time }
}

export function groupBy(items, prop, getter) {
  if (Array.isArray(items)) {
    const group = {}
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const value = getter(item)
      const label = prop(item)
      if (group[label]) {
        group[label].push(value)
      } else {
        group[label] = [value]
      }
    }
    return group
  } else {
    return {}
  }
}

export function isMobile() {
  return /Mobi|Android/i.test(navigator.userAgent)
}

export function mapObjectToArray(obj, arr) {
  return arr.map((item) => obj[item])
}
