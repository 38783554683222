import React from 'react'
import GoogleMapReact from 'google-map-react'

// import img from '../../images/pin-red.svg';
import img from '../../images/spotlight-poi2.png'

export const PinMarker = (props) => (
  <img
    style={{ position: 'absolute', top: '-43px', left: '-12px' }}
    src={img}
    alt="pin"
  />
)

const API = {
  key: 'AIzaSyAYA5ZES8mXEip_vXvfmj_k0G17i2wf9AE',
  // language: 'ru',
  // region: 'ru',
}

class GoogleMap extends React.Component {
  static defaultProps = {
    center: { lat: 59.437, lng: 24.7536 },
    zoom: 10,
  }

  state = {
    center: [60.938043, 30.337157],
    zoom: 10,
    draggable: true,
    lat: 59.437,
    lng: 24.7536,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.draggable && nextProps.center.lat !== prevState.lat) {
      return { ...nextProps.center }
    } else return null
  }

  onChange = ({ lat, lng }) => {
    const event = {
      target: {
        name: this.props.name,
        value: { lat, lng },
      },
    }

    this.props.onChange && this.props.onChange(event)
  }

  handleMouseMove = (childKey, childProps, mouse) => {
    // function is just a stub to test callbacks
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    })

    console.log('handleMouseMove called with', childKey, childProps, mouse)
  }

  handleMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true })
    // function is just a stub to test callbacks
    console.log('handleMouseUp called with', childKey, childProps, mouse)
    const { lat, lng } = mouse
    this.onChange({ lat, lng })
  }

  handleChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    })
  }

  handleClick = ({ x, y, lat, lng, event }) => {
    console.log(x, y, lat, lng, event)
  }

  render() {
    return (
      <GoogleMapReact
        bootstrapURLKeys={API}
        draggable={this.state.draggable}
        center={this.props.center}
        zoom={this.props.zoom}
        onChange={this.handleChange}
        onClick={this.handleClick}
        onChildMouseDown={this.handleMouseMove}
        onChildMouseUp={this.handleMouseUp}
        onChildMouseMove={this.handleMouseMove}
        // onChildClick={() => console.log('child click')}
      >
        {/* <PinMarker name="venue" {...this.props.center} /> */}
        <PinMarker name="venue" lat={this.state.lat} lng={this.state.lng} />
      </GoogleMapReact>
    )
  }
}

export default GoogleMap
