import { useEffect, useRef } from 'react'

export const useEffectOnce = (effect) => {
  useEffect(effect, [])
}

export const useComponentDidMount = (callback) => {
  useEffectOnce(() => {
    callback()
  })
}

export const useComponentWillUnmount = (callback) => {
  useEffectOnce(() => callback)
}

export function useImagePreloader(url, cb) {
  useEffect(() => {
    const img = new Image()

    function onLoad() {
      cb(url)
    }

    function onError(err) {
      console.error(`Error while loading ${img.src}`)
    }

    img.addEventListener('load', onLoad)
    img.addEventListener('error', onError)

    img.src = url

    return function cleanup() {
      img.removeEventListener('load', onLoad)
      img.removeEventListener('error', onError)
    }
  }, [url, cb])
}

export const useUpdateEffect = (effect, deps) => {
  const mounted = useRef(false)
  useEffect(
    !mounted.current
      ? () => {
          mounted.current = true
        }
      : effect,
    deps
  )
}

export const useLogger = (name, ...props) => {
  useEffectOnce(() => {
    console.log(name, 'mounted', ...props)
    return () => {
      console.log(name, 'unmounted')
    }
  })

  useUpdateEffect(() => console.log(name, 'updated', ...props))
}

export const useRefMounted = () => {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  return mounted
}
