import React from 'react'
import cc from 'classcat'

import styles from './Input.module.css'

const Select = ({ children, placeholder, ...props }) => {
  return (
    <select
      className={cc({
        [styles.base]: true,
        [styles.select]: true,
        [styles.selected]: !!props.value,
      })}
      name="select"
      {...props}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </select>
  )
}

export default Select
