import produceReducer from '../immer'
import * as types from './types'

export const initialState = {}

function updateObject(oldObject, data) {
  const { id } = data
  const newObject = { byId: {}, allIds: [], ...oldObject }

  newObject.byId[id] = data
  newObject.allIds.indexOf(id) === -1 && newObject.allIds.push(id)

  return newObject
}

// case redusers
function updateAppChild(state, action) {
  const { data } = action
  const type = data.object
  const update = updateObject(state[type], data)

  state[type] = update
}

function updateAppData(state, action) {
  const { label, data } = action
  state[label] = data
}

function deleteItem(state, action) {
  const { type, id } = action.payload
  state[type].allIds = state[type].allIds.filter((i) => i !== id)
  delete state[type].byId[id]
}

function sortItems(state, action) {
  const { type, items } = action.payload
  state[type].allIds = items
}

const reducer = produceReducer(initialState, {
  [types.FETCH_ITEMS_SUCCESS]: (state, action) => updateAppData(state, action),
  [types.ADD_ITEM_SUCCESS]: (state, action) => updateAppChild(state, action),
  [types.UPDATE_ITEM_SUCCESS]: (state, action) => updateAppChild(state, action),
  [types.DELETE_ITEM_SUCCESS]: (state, action) => deleteItem(state, action),
  [types.SORT_ITEMS_SUCCESS]: (state, action) => sortItems(state, action),
  // [types.CLEAR_APP_DATA]: () => initialState,
})

export default reducer
