import React from 'react'

import Badge from '../../ui/Badge'

// import Frontpage from './Frontpage'
import Artists from './Artists'
import Speakers from './Speakers'
import Venues from './Venues'
import Events from './Events'
import Playlist from './_Playlist'
import Pages from './Pages'
import Offers from './Offers'
import Exhibitors from './Exhibitors'
import Attendees from './Attendees'
import Products from './Products'
import Partners from './Partners'

// export const root = {
//   path: '/',
//   label: 'Root',
//   component: Root,
// }

export const artists = {
  path: '/artists',
  label: 'Artists',
  component: Artists,
}
export const speakers = {
  path: '/speakers',
  label: 'Speakers',
  component: Speakers,
}
export const venues = { path: '/venues', label: 'Venues', component: Venues }
export const events = { path: '/events', label: 'Events', component: Events }
export const pages = { path: '/pages', label: 'Pages', component: Pages }
export const exhibitors = {
  path: '/exhibitors',
  component: Exhibitors,
  label: 'Exhibitors',
  value: (
    <>
      Exhibitors<Badge>PRO</Badge>
    </>
  ),
}
export const attendees = {
  path: '/attendees',
  component: Attendees,
  label: 'Attendees',
  value: (
    <>
      Attendees<Badge>PRO</Badge>
    </>
  ),
}
export const partners = {
  path: '/partners',
  component: Partners,
  label: 'Partners',
  value: (
    <>
      Partners<Badge>PRO</Badge>
    </>
  ),
}

export const playlist = {
  path: '/playlist',
  label: 'Playlist',
  component: Playlist,
}

export const shop = {
  path: '/shop',
  component: Products,
  label: 'Shop',
  value: (
    <>
      Shop
      <Badge style={{ backgroundColor: 'green' }}>$$$</Badge>
    </>
  ),
}
export const offers = {
  path: '/offers',
  component: Offers,
  label: 'Offers',
  value: (
    <>
      Offers
      <Badge style={{ backgroundColor: 'green' }}>$$$</Badge>
    </>
  ),
}

export default [
  artists,
  speakers,
  venues,
  events,
  pages,
  exhibitors,
  partners,
  offers,
  shop,
  // attendees,
  // playlist,
  // frontpage,
]
