const STATE = 'state'

export const loadState = () => {
  try {
    const serializeState = localStorage.getItem(STATE)
    if (!serializeState) {
      return {}
    } else {
      return JSON.parse(serializeState)
    }
  } catch (err) {
    return {}
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(STATE, serializedState)
  } catch (err) {
    // ignore
  }
}
