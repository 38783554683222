import React from 'react'

import { Button } from '../../ui/inputs'

import { Box, Sticky, Header } from '../../ui/layout'
import BannerItem from './BannerItem'

import { ModalButton } from '../../components/Modal'
import { BannerModal } from '../../modals'

import TYPE from '../../../api/types'

import AbstractDataFetcher from '../../components/AbstractDataFetcher'
import { fetchTypeList, mapAppType } from '../../../store/app'
import Spinner from '../../ui/Spinner'

const fetchBanners = () => fetchTypeList(TYPE.BANNER)

const mapStateToProps = (state) => ({
  data: mapAppType(state, TYPE.BANNER),
})

const Banners = () => {
  return (
    <div>
      <Sticky top={0}>
        <Header title="Banners">
          <Box paddingX={2}>
            <ModalButton component={BannerModal}>
              <Button size="sm" rounded>
                Add
              </Button>
            </ModalButton>
          </Box>
        </Header>
      </Sticky>
      <AbstractDataFetcher fetch={fetchBanners} map={mapStateToProps}>
        {({ props, loading }) => {
          if (loading) {
            return <Spinner show={loading} />
          } else if (props.data) {
            return (
              <Box
                paddingX={6}
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                {props.data.map((item, i) => {
                  return <BannerItem item={item} key={item.id || i} />
                })}
              </Box>
            )
          }
        }}
      </AbstractDataFetcher>
    </div>
  )
}

export default Banners
