import React from 'react'
import cc from 'classcat'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Desktop.module.css'

const DesktopDeleteHover = ({ sorting, getRef }) => {
  // const thrash = sorting ? 'apps__thrash show' : 'apps__thrash'
  const thrash = cc({
    [styles.apps__thrash]: true,
    [styles.show]: sorting,
  })
  return (
    <div className={thrash} ref={(ref) => getRef(ref, 'thrash')}>
      {/* <span
        style={{
          background: '#2196f3',
          borderRadius: '20px',
          padding: '4px 12px',
          pointerEvents: 'none',
        }}
      >
        Drop icon here to delete App
      </span> */}
      <div
        style={{
          display: 'inline-block',
          background: '#1565c0',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          lineHeight: '40px',
          verticalAlign: 'middle',
          pointerEvents: 'none',
        }}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </div>
    </div>
  )
}

export default DesktopDeleteHover
