import React from 'react'

import BottomNavigation from '../components/BottomNavigation'
import LogoLink from '../components/LogoLink'

import navbar from '../dashboard/Navbar.module.css'
import layout from '../../ui/Layout.module.css'

const Navigation = (props) => {
  return (
    <div className={`${navbar.navbar} ${navbar.lite} ${layout.fixed}`}>
      <LogoLink />
      <BottomNavigation />
    </div>
  )
}

export default Navigation
