import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './MenuLink.module.css'
import themes from '../../ui/Themes.module.css'
import typography from '../../ui/Typography.module.css'

const MenuLink = ({ url, match, path, label, children }) => {
  return (
    <NavLink
      className={`${styles.item} ${typography.truncate} ${themes.menuItem}`}
      activeClassName={themes.active}
      to={`${url || match.url}${path}`}
    >
      {children || label}
    </NavLink>
  )
}

export default MenuLink
