import React from 'react'

import table from '../Table.module.css'

const TableCell = ({ children, ...props }) => {
  return (
    <div className={table.cell} {...props}>
      {children}
    </div>
  )
}

export default TableCell
