import TYPE from '../../../api/types'

import venuesModel from '../../tables/venuesModel'
import { VenueModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.VENUE,
  title: 'Venues',
  table: venuesModel,
  modal: {
    header: 'Edit Venue',
    component: VenueModal,
  },
}

export default pageFactory(page)
