import React from 'react'

import { connect } from 'react-redux'
import {
  fetchTypeList,
  mapAppType,
  getAllIds,
  sortAppItems,
} from '../../store/app'

import useFetcher from './useFetcher'

function model({ data, id, type }) {
  const values = [...Object.values(data), id, type]
  return values
}

function filterData(data, value) {
  return data.filter(
    (item) =>
      JSON.stringify(model(item))
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
  )
}

const DataProvider = ({ type, children, ...props }) => {
  const { loading, error, fetchData } = useFetcher(() =>
    props.dispatch(fetchTypeList(type))
  )

  const [state, setState] = React.useState({ filter: '', filtered: [] })

  const filterItems = (ev) => {
    const { value } = ev.target
    const { data } = props

    const filtered = filterData(data, value)

    setState({ filter: value, filtered })
  }

  const sortItems = (oldIndex, newIndex) => {
    const { all: items } = props
    props.dispatch(sortAppItems(type, oldIndex, newIndex, items))
  }

  const methods = {
    filterItems: filterItems,
    sortItems: sortItems,
    onRetry: fetchData,
  }

  const nextProps = {
    ...props,
    data: state.filter ? state.filtered : props.data,
    loading: loading,
    error: error,
  }

  return children(nextProps, methods)
}

function mapStateToProps(state, props) {
  const { type } = props
  return {
    data: mapAppType(state, type),
    all: getAllIds(state, type),
  }
}

export default connect(mapStateToProps)(DataProvider)
