import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import { Checkbox, RadioButton, Toggle } from '../../../ui/inputs'

export const BaseInputsDemo = (props) => {
  return (
    <>
      <FormRow label="Toggle">
        <div>
          <Toggle />
        </div>
        <div>
          <Toggle defaultChecked />
        </div>
        <div>
          <Toggle disabled />
        </div>
        <div>
          <Toggle checked disabled />
        </div>
      </FormRow>

      <FormRow label="Checkbox">
        <div>
          <Checkbox />
        </div>
        <div>
          <Checkbox defaultChecked />
        </div>

        <div>
          <Checkbox disabled />
        </div>
        <div>
          <Checkbox checked disabled />
        </div>
      </FormRow>

      <FormRow label="Radio">
        <div>
          <RadioButton />
        </div>
        <div>
          <RadioButton defaultChecked />
        </div>

        <div>
          <RadioButton disabled />
        </div>
        <div>
          <RadioButton checked disabled />
        </div>
      </FormRow>
    </>
  )
}

export default BaseInputsDemo
