import React, { useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import cc from 'classcat'

import table from './Table.module.css'

const SortableTableItem = SortableElement((props) => props.children)
const SortableTableBody = SortableContainer((props) => props.children)

const settings = {
  pressDelay: 250,
  helperClass: table.dragging,
  lockAxis: 'y',
  lockToContainerEdges: true,
  lockOffset: 0,
}

const TableBodySortable = ({ data, component, onSortEnd }) => {
  const [sorting, isSorting] = useState(false)

  const handleSortStart = ({ node, index, collection }, ev) => {
    ev.preventDefault()
    isSorting(true)
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    onSortEnd(oldIndex, newIndex)
    isSorting(false)
  }

  return (
    <SortableTableBody
      {...settings}
      onSortEnd={handleSortEnd}
      onSortStart={handleSortStart}
    >
      <div
        className={cc({
          [table.body]: true,
          [table.sorting]: sorting,
        })}
      >
        {data.map((item, i) => (
          <SortableTableItem index={i} key={item.id || i}>
            {React.cloneElement(component, { item })}
          </SortableTableItem>
        ))}
      </div>
    </SortableTableBody>
  )
}

export default TableBodySortable
