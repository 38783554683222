import React from 'react'

import MenuLabel from './MenuLabel'
import MenuLink from './MenuLink'
import { Box } from '../../ui'

const Menu = ({ label, routes, url }) => {
  return (
    <Box paddingY={2}>
      {label && <MenuLabel text={label} />}
      {routes.map((route) => {
        if (route.hidden === true) {
          return null
        }
        return (
          <MenuLink url={url} path={route.path} key={route.path}>
            {route.value || route.label}
          </MenuLink>
        )
      })}
    </Box>
  )
}

export default Menu
