export const initialState = {}

// Actions
const SYNC = 'SYNC_FORM'
const CLEAR = 'CLEAR_FORM'

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SYNC: {
      return { ...state, ...action.data }
    }
    case CLEAR: {
      return initialState
    }
    default:
      return state
  }
}

// Selectors
export const getTemp = (state) => state.temp
export const getTempType = (state, type) => state.temp[type]

export function syncChanges(data) {
  return { type: SYNC, data }
}

export function clearTemp() {
  return { type: CLEAR }
}
