import { getItem, postItem, uploadFile, deleteFile } from './data'
// import { isEmpty, isNotEmpty, isObject } from '../utils'

function uploadFiles(res, files) {
  const { object: type, id } = res

  const promises = files.map(({ file, label = 'file' }) => {
    return uploadFile({ type, id, file, label })
  })

  return Promise.all(promises)
}

function deleteFiles(filesToDelete) {
  const promises = filesToDelete.map((url) => {
    return deleteFile(url)
  })

  return Promise.all(promises)
}

// TODO check for arrays
async function postMultipartData(data, upload = [], remove = []) {
  let response = await postItem(data)

  if (remove.length) await deleteFiles(remove)

  if (upload.length) {
    const uploads = await uploadFiles(response, upload)
    response = await getItem(response.object, response.id)
  }

  return response
}

export default postMultipartData
