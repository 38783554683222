import React from 'react'
import { sortBy } from 'lodash'

import TableBody from './TableBody'
import TableBodySortable from './TableBodySortable'

import { createTableHead, createTableItem } from './utils'

import styles from './Table.module.css'

class Table extends React.Component {
  state = {
    sorting: null,
    reverse: false,
    page: 0,
  }

  static defaultProps = {
    data: [],
    model: [],
    // rows: 5,
  }

  componentDidMount() {
    this.makeTable()
    this.forceUpdate()
  }

  makeTable() {
    const { model } = this.props
    if (Array.isArray(model)) {
      this.tableHead = createTableHead(model)
      this.tableItem = createTableItem(model)
    }
  }

  sortItems = (ev, sorting) => {
    let reverse = this.state.reverse

    if (this.state.sorting === sorting) {
      reverse = !reverse
    } else {
      reverse = false
    }

    this.setState({ sorting, reverse })
  }

  resetSorting = () => {
    this.setState({
      sorting: null,
      reverse: false,
    })
  }

  bindSort = (prop) => {
    let sorting
    if (this.state.sorting === prop) {
      sorting = this.state.reverse ? 0 : 1
    }
    return {
      onClick: (ev) => this.sortItems(ev, prop),
      sorting,
    }
  }

  // selectAll = (ev, i) => {
  //   // console.log('selectAll', i)
  //   const { checked } = ev.target
  //   let selected = []
  //   if (checked) {
  //     selected = this.props.data.map((item) => item.id)
  //   }
  //   // console.log(selected)
  //   this.setState({ checked: selected })
  // }

  // selectItem = (ev, i) => {
  //   // console.log('select', i)
  //   const { name, checked } = ev.target

  //   const oldCheckboxValue = this.state[name] || []
  //   const newCheckboxValue = checked
  //     ? oldCheckboxValue.concat(i)
  //     : oldCheckboxValue.filter((v) => v !== i)

  //   // console.log(name, newCheckboxValue)

  //   this.setState({ [name]: newCheckboxValue })
  // }

  prevPage = () => {
    if (this.state.page !== 0) {
      this.setState({ page: this.state.page - 1 })
    }
  }

  nextPage = () => {
    const { data, rows } = this.props
    if ((this.state.page + 1) * rows < data.length) {
      this.setState({ page: this.state.page + 1 })
    }
  }

  getDataSlice = () => {
    const { page } = this.state
    const { data, rows } = this.props

    const from = page * rows

    return data.slice(from, from + rows)
  }

  sortData = () => {
    const { sorting, reverse } = this.state
    const { data } = this.props

    const sorted = sortBy(data, [sorting])
    if (reverse) sorted.reverse()

    return sorted
  }

  updateList = (data) => {
    let list = data

    if (this.props.rows !== undefined) {
      list = this.getDataSlice()
    }

    if (this.state.sorting) {
      list = this.sortData()
    }

    return list
  }

  isSortable = () => {
    return typeof this.props.onSortEnd === 'function' && !this.state.sorting
  }

  render() {
    if (this.tableHead === undefined || this.tableItem === undefined)
      return null

    const TableHead = this.tableHead
    const TableItem = this.tableItem

    const { onSortEnd, itemEvents } = this.props

    const data = this.updateList(this.props.data)

    const tableProps = {
      data,
      component: <TableItem itemEvents={itemEvents} />,
    }

    let tableBody
    if (this.isSortable()) {
      tableBody = <TableBodySortable {...tableProps} onSortEnd={onSortEnd} />
    } else {
      tableBody = <TableBody {...tableProps} />
    }

    return (
      <div className={styles.table}>
        <TableHead sortItems={this.bindSort} />
        {tableBody}
      </div>
    )
  }
}

export default Table
