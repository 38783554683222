import React from 'react'

import styles from './Shimmer.module.css'

const Shimmer = ({ children, ...props }) => {
  return (
    <div className={styles.background}>
      <div className={styles.placeholder}>{children}</div>
    </div>
  )
}

export default Shimmer
