import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'
import FormSocials from '../components/FormSocials'

import { Box } from '../../ui/layout'
import { Input, Select, TextArea, FormFiles, DateTime } from '../../ui/inputs'

import PopupTabs from '../../ui/PopupTabs'
import RelatedItemsForm from '../../forms/related/RelatedItemsForm'
import relatedArtistsModel from '../../tables/relatedArtistsModel'
import relatedVenuesModel from '../../tables/relatedVenuesModel'

import TYPE from '../../../api/types'

export const EventForm = ({ handleUpload, handleDelete, bindInput, media }) => {
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Event Images">
        <FormFiles
          files={media.image}
          name="image"
          onChange={handleUpload}
          onDelete={handleDelete}
        />
      </FormRow>

      <FormRow label="Event Name">
        <Input placeholder="Name" {...bindInput('eventName')} />
      </FormRow>

      <FormRow label="Event Start/End">
        <Box display="flex" justifyContent="space-between">
          <DateTime
            // timeFormat={false}
            placeholder="Event start"
            {...bindInput('eventStart')}
          />
          <DateTime
            // timeFormat={false}
            placeholder="Event end"
            {...bindInput('eventEnd')}
          />
        </Box>
      </FormRow>

      <FormRow label="Event Type">
        <Select placeholder="Select event type" {...bindInput('eventType')}>
          <option value="music">Music event</option>
        </Select>
      </FormRow>

      <FormRow label="About Event" inline={false}>
        <TextArea
          placeholder="Event description"
          {...bindInput('description')}
        />
      </FormRow>

      {/* <FormRow label="Ticket Price">
        <Input type="number" placeholder="100" {...bindInput('price')} />
      </FormRow> */}

      <FormRow label="Ticket URL">
        <Input placeholder="https://" {...bindInput('ticket')} />
      </FormRow>

      <FormSocials bindInput={bindInput} />
    </>
  )
}

const EventFormWithTabs = (props) => {
  return (
    <PopupTabs
      tabs={[
        {
          icon: 'calendar',
          label: 'Event',
          component: () => <EventForm {...props} />,
        },
        {
          icon: 'microphone',
          label: 'Artists',
          component: () => (
            <RelatedItemsForm
              type={TYPE.ARTIST}
              model={relatedArtistsModel}
              {...props}
            />
          ),
        },
        {
          icon: 'map-marker-alt',
          label: 'Venue',
          component: () => (
            <RelatedItemsForm
              type={TYPE.VENUE}
              model={relatedVenuesModel}
              {...props}
            />
          ),
        },
      ]}
    />
  )
}

export default EventFormWithTabs
