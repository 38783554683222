import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import NoMatch from '../NoMatch'

export function mapRoutes(routes, match) {
  return routes.map((route) => {
    return (
      <Route
        path={`${match.url}${route.path}`}
        component={route.component}
        key={route.path}
      />
    )
  })
}

const Routes = ({ match, exact, redirect, routes, ...props }) => {
  return (
    <Switch>
      {exact && <Route exact path={match.url} component={exact} />}
      {redirect && <Redirect exact from={redirect.from} to={redirect.to} />}

      {props.children}

      {routes &&
        routes.map((route) => {
          return (
            <Route
              path={`${match.url}${route.path}`}
              component={route.component}
              key={route.path}
            />
          )
        })}

      <Route component={NoMatch} />
    </Switch>
  )
}

export default Routes
