import React from 'react'

import Popup from '../ui/Popup'
import AppForm from '../forms/AppForm'

const AppModal = (props) => {
  return (
    <Popup header="App Settings" hideModal={props.hideModal}>
      <AppForm hideModal={props.hideModal} />
    </Popup>
  )
}

export default AppModal
