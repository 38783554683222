import React from 'react'
import { ListItem } from '../templates'
import mapType from '../mapType'
import TYPE from '../../../api/types'

import styles from '../templates/ListItem.module.css'

const Venues = ({ data }) => {
  return (
    <div>
      {data.map(({ data, media, id }) => (
        <ListItem
          image={media[0]}
          imageClassName={styles.square}
          title={data.title}
          description={`${data.address}`}
          key={id}
        />
      ))}
    </div>
  )
}

Venues.defaultProps = {
  data: [],
}

export default mapType(TYPE.VENUE)(Venues)
