import React from 'react'

import TableFirstColumn from './components/TableFirstColumn'
import { HideItemButton, DeleteItemButton } from './actions'

import ExternalLink from '../ui/ExternalLink'

export default [
  {
    header: 'Artist',
    prop: 'data.title',
    render: ({ id, data, media }) => (
      <TableFirstColumn image={media[0]} title={data.title} link={id} />
    ),
    width: '50%',
  },
  { header: 'Genre', prop: 'data.genre' },
  { header: 'Country', prop: 'data.country' },
  {
    header: 'Website',
    prop: 'data.website',
    render: ({ data }) => (
      <ExternalLink href={data.website}>{data.website}</ExternalLink>
    ),
  },
  {
    header: 'ID',
    prop: 'id',
    render: (item) => <span style={{ color: '#aaa' }}>{item.id}</span>,
    width: 60,
  },
  HideItemButton,
  DeleteItemButton,
]
