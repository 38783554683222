// import React from 'react'
// import { Redirect } from 'react-router-dom'

import { userLogout } from '../../../store/session'

const Logout = (props) => {
  userLogout(props.history)
  return null
}

export default Logout
