import React from 'react'
import deepEqual from 'deep-equal'
import dotProp from 'dot-prop-immutable'
import 'element-closest'

// TODO remove normalized media from Forma use Img component to get media link
import { groupMedia } from '../../../utils/normalize'

// const inputParsers = {
//   date(input) {
//     const [month, day, year] = input.split('/');
//     return `${year}-${month}-${day}`;
//   },
//   uppercase(input) {
//     return input.toUpperCase();
//   },
//   number(input) {
//     return parseFloat(input);
//   },
// };

function handleInitialState(initialState) {
  if (Array.isArray(initialState)) return { ...initialState }
  else if (typeof initialState === 'object') return initialState
  else return {}
}

class Forma extends React.Component {
  state = {
    form: handleInitialState(this.props.initialState),
    media: groupMedia(this.props.initialMedia),
    remove: [],
  }

  static defaultProps = {
    initialState: {},
    initialMedia: {},
  }

  setInitialState = (state) => {
    this.setState({ form: state })
  }

  setProperty = (prop, value) => {
    const form = dotProp.set(this.state.form, prop, value)
    // const isTouched = this.checkForChanges()
    this.setState({ form })
  }

  resetForm = (ev) => {
    this.setState({ form: {}, media: {} })
  }

  checkValidity = (target) => {
    const form = target.closest('form')
    const isValid = form.checkValidity()

    if (!isValid) {
      for (let i = form.elements.length - 1; i >= 0; i--) {
        const formElement = form.elements[i]
        if (!formElement.checkValidity()) {
          formElement.scrollIntoView()
          formElement.focus()
          break
        }
      }
    }

    this.setState({ isValid })
    return isValid
  }

  checkForChanges(form) {
    const isTouched = !deepEqual(
      this.props.initialState,
      form || this.state.form
    )
    return isTouched
  }

  handleUpload = (ev) => {
    const { name, files, multiple } = ev.target

    const old_files = this.state.media[name] || []
    const media = {
      ...this.state.media,
      [name]: multiple ? [...old_files, ...files] : [...files],
    }

    this.setState({ media })

    ev.target.value = ''
  }

  handleDelete = (ev, name, i) => {
    const files = [...this.state.media[name]]
    const update = files.filter((file, index) => file !== i)

    const media = { ...this.state.media, [name]: update }
    const remove =
      typeof i === 'string' ? [...this.state.remove, i] : this.state.remove

    this.setState({ media, remove })
  }

  handleCheckbox = (ev) => {
    const { name, value, checked } = ev.target

    const oldCheckboxValue = dotProp.get(this.state.form, name, []) || []

    const newCheckboxValue = checked
      ? oldCheckboxValue.concat(value)
      : oldCheckboxValue.filter((v) => v !== value)

    this.setProperty(name, newCheckboxValue)
  }

  handleChange = (ev) => {
    const target = ev.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value

    this.setProperty(name, value)
  }

  handleBlur = (ev) => {
    if (ev.target.type !== 'text') return
    const { name, value } = ev.target

    const trimmed = value.trim()
    const form = { ...this.state.form }

    if (value !== trimmed) {
      if (trimmed === '' && this.props.initialState[name] === undefined) {
        delete form[name]
      } else {
        form[name] = trimmed
      }

      ev.target.value = trimmed
    }

    const isTouched = this.checkForChanges(form)
    this.setState({ form, isTouched })
  }

  bindInput = (name) => {
    const value = dotProp.get(this.state.form, name, '') || ''
    return {
      name,
      value,
      onChange: this.handleChange,
    }
  }

  render() {
    const { form, media, remove } = this.state

    const nextProps = {
      ...this.props,
      state: form,
      media: media,
      remove: remove,
      handleChange: this.handleChange,
      handleUpload: this.handleUpload,
      handleDelete: this.handleDelete,
      handleCheckbox: this.handleCheckbox,
      handleBlur: this.handleBlur,
      bindInput: this.bindInput,
      resetForm: this.resetForm,
      checkValidity: this.checkValidity,
      setInitialState: this.setInitialState,
      setProperty: this.setProperty,
      isTouched: this.state.isTouched,
    }

    return this.props.children(nextProps)
  }
}

export default Forma
