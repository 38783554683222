import TYPE from '../../../api/types'

import productsModel from '../../tables/productsModel'
import { ProductModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.PRODUCT,
  title: 'Products',
  table: productsModel,
  modal: {
    header: 'Edit Item',
    component: ProductModal,
  },
}

export default pageFactory(page)
