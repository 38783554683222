import React from 'react'

import Img from '../Img'
import { Button, FileInput } from '.'
import { Box } from '..'
import { Del } from './FormFiles'

import styles from './FormFiles.module.css'
import layout from '../Layout.module.css'

const FormImage = ({
  label = 'Select File',
  src,
  accept,
  onDelete,
  onChange,
  width,
  height,
  ...props
}) => {
  return (
    <Box>
      {src && (
        <div className={layout.relative}>
          <Img
            src={src}
            className={styles.img}
            style={{ width: '100%', height }}
            fit="cover"
          />
          <Del onClick={(ev) => onDelete(ev, props.name, src)} />
        </div>
      )}
      <Box paddingY={2}>
        <FileInput accept={accept} onChange={onChange} {...props}>
          <Button tag="div">{label}</Button>
        </FileInput>
      </Box>
    </Box>
  )
}

export default FormImage
