import React from 'react'

import FormRow from '../components/FormRow'
import { Input } from '../../ui/inputs'

const PasswordForm = ({ bindInput }) => {
  return (
    <>
      <FormRow label="Change Email">
        <Input
          style={{ marginBottom: '8px' }}
          type="email"
          value="useremail@email.com"
          // {...bindInput('newEmail')}
          disabled
        />
        <Input
          type="email"
          placeholder="New email"
          {...bindInput('newEmail')}
        />
      </FormRow>

      <FormRow label="Change Password">
        <Input
          style={{ marginBottom: '8px' }}
          type="password"
          placeholder="New Password"
          {...bindInput('newPassword')}
        />
        <Input
          style={{ marginBottom: '8px' }}
          type="password"
          placeholder="Verify password"
          {...bindInput('verifyPassword')}
        />
        <Input
          type="password"
          placeholder="Current password"
          {...bindInput('currentPassword')}
        />
      </FormRow>
    </>
  )
}

export default PasswordForm
