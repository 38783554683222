import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'
import FormSocials from '../components/FormSocials'

import { Box } from '../../ui/layout'
import { Input, Select, TextArea, FormFiles, DateTime } from '../../ui/inputs'

export const OfferForm = ({ bindInput, handleUpload, handleDelete, media }) => {
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Offer Images">
        <FormFiles
          name="image"
          files={media.image}
          onChange={handleUpload}
          onDelete={handleDelete}
        />
      </FormRow>

      <FormRow label="Offer Title">
        <Input placeholder="Special Offer title" {...bindInput('title')} />
      </FormRow>

      <FormRow label="Offer Start/End">
        <Box display="flex">
          <Box>
            <DateTime
              // timeFormat={false}
              placeholder="Event start"
              {...bindInput('eventStart')}
            />
          </Box>

          <Box marginLeft="8px">
            <DateTime
              // timeFormat={false}
              placeholder="Event end"
              {...bindInput('eventEnd')}
            />
          </Box>
        </Box>
      </FormRow>

      <FormRow label="Offer Price">
        <Box display="flex">
          <Input
            type="number"
            min="0"
            placeholder="100"
            {...bindInput('price')}
          />
          <Select
            style={{ marginLeft: '8px' }}
            placeholder="Select currency"
            {...bindInput('currency')}
          >
            <option value="EUR">EUR</option>
          </Select>
        </Box>
      </FormRow>

      <FormRow label="Offer description" inline={false}>
        <TextArea
          placeholder="Offer description"
          {...bindInput('description')}
        />
      </FormRow>

      <FormRow label="Disclaimer Text">
        <Input
          placeholder="eg. offer valid until XX date"
          {...bindInput('disclaimer')}
        />
      </FormRow>

      <FormSocials bindInput={bindInput} />
    </>
  )
}

export default OfferForm
