import { sessionService } from 'redux-react-session'
import * as sessionApi from '../api/auth'
import store, { resetState } from './index'

// TODO: fix error handling
export async function userRegister(user, history) {
  try {
    const res = await sessionApi.register(user)

    return res
  } catch (err) {
    return err
  }
}

export async function userLogin(user, history) {
  try {
    const res = await sessionApi.login({})

    if (res.error || res.email || res.password) {
      alert(res)
    }

    sessionService.saveSession('bearerToken')
    sessionService.saveUser(res)

    history.push('/')

    return res
  } catch (err) {
    return err
  }
}

export async function userLogout(history) {
  try {
    const res = await sessionApi.logout()
    return res
  } catch (err) {
    return err
  } finally {
    await sessionService.deleteSession()
    await sessionService.deleteUser()
    store.dispatch(resetState())
    history.push('/')
  }
}
