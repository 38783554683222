import React from 'react'

import Undraw from '../ui/Undraw'
import { page_not_found } from '../../images/undraw'

const NoMatch = (props) => {
  return <Undraw svg={page_not_found}>{props.children}</Undraw>
}

export default NoMatch
