import React from 'react'
import cc from 'classcat'
import { withRouter } from 'react-router-dom'

import Emoji from '../../ui/Emoji'
import RoundButton from '../../ui/RoundButton'

import { ModalButton } from '../../components/Modal'
import AppModal from '../../modals/AppModal'
import DevModal from '../../modals/DevModal'
import NavbarButtonEmoji from '../components/NavbarButtonEmoji'

import styles from '../dashboard/Navbar.module.css'

import routes from './routes'

const Developing = ({ match }) => {
  return (
    <>
      {routes.map((route) => {
        return (
          <NavbarButtonEmoji
            url={match.url + '/setup'}
            path={route.path}
            label={route.label}
            value={route.value}
            key={route.label}
          />
        )
      })}
    </>
  )
}

const Deploying = (props) => {
  return (
    <ModalButton component={AppModal}>
      <RoundButton>
        <Emoji label="Deploy" value="🚀" />
      </RoundButton>
    </ModalButton>
  )
}

const Deployed = ({ match }) => {
  return (
    <>
      <NavbarButtonEmoji
        url={match.url}
        path="/promote"
        label="Promote"
        value="📢"
      />
    </>
  )
}

const SetupNavigation = ({
  location,
  match,
  togglePreview,
  preview,
  status,
}) => {
  const isSidebar = /(setup)/.test(location.pathname)

  if (isSidebar && !preview) togglePreview()

  const cls = cc({
    [styles.navbar]: true,
    [styles.active]: isSidebar,
  })

  return (
    <div className={cls}>
      <div className={styles.navbarTop}>
        {status === 1 && <Developing match={match} />}
        {status === 2 && <Deploying />}
        {status === 3 && <Deployed match={match} />}
      </div>

      <ModalButton component={DevModal}>
        <RoundButton>
          <Emoji label="Dev" value="🐞" />
        </RoundButton>
      </ModalButton>
    </div>
  )
}

export default withRouter(SetupNavigation)
