import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import Button from '../../../ui/inputs/Button'
import Tooltip from '../../../ui/Tooltip'

const TooltipDemo = (props) => {
  return (
    <FormRow label="Tooltip">
      <Tooltip text="Tooltip content" delay={0} placement={0} status="warning">
        <Button type="outline">Tooltip</Button>
      </Tooltip>
      <Tooltip
        text="Tooltip content"
        delay={100}
        placement={1}
        status="warning"
      >
        <Button type="outline">Tooltip</Button>
      </Tooltip>
      <Tooltip
        text="Tooltip content"
        delay={500}
        placement={2}
        status="warning"
      >
        <Button type="outline">Tooltip</Button>
      </Tooltip>
      <Tooltip
        text="Tooltip content"
        delay={1000}
        placement={3}
        status="warning"
      >
        <Button type="outline">Tooltip</Button>
      </Tooltip>
    </FormRow>
  )
}

export default TooltipDemo
