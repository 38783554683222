export const isFetching = (state) => state.api.isFetching

export const getAppType = (state, type) => state.app[type]
export const getAllIds = (state, type) => {
  const data = state.app[type]
  return data ? data.allIds : []
}

export const mapAppType = (state, type) => {
  const data = state.app[type]
  return data ? data.allIds.map((id) => data.byId[id]) : []
}
