import React from 'react'

import TableFirstColumn from './components/TableFirstColumn'
import { HideItemButton, DeleteItemButton } from './actions'

export default [
  {
    prop: 'data.title',
    header: 'Name',
    render: ({ id, data, media }) => (
      <TableFirstColumn image={media[0]} title={data.title} link={id} />
    ),
  },
  { prop: 'data.quantity', header: 'Quantity' },
  { prop: 'data.price', header: 'Price' },
  { prop: 'data.productType', header: 'Type' },
  HideItemButton,
  DeleteItemButton,
]
