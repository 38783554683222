import React from 'react'

import styles from './FormFooter.module.css'

const FormFooter = ({ children, ...props }) => {
  return (
    <div className={styles.footer} {...props}>
      {children}
    </div>
  )
}

export default FormFooter
