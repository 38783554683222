import React from 'react'

import SearchInput from '../../../ui/SearchInput'
import GoogleMapReact from '../../../components/GoogleMapReact'
import { DateTime } from '../../../ui/inputs'
import { Header, Box } from '../../../ui/layout'

class Heatmap extends React.Component {
  state = {
    start: new Date(),
    end: new Date(),
    mapType: 'heatmap',
  }

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({ [name]: value })
  }

  render() {
    return (
      <div>
        <Header>
          <Box paddingX={2} flexGrow={1}>
            <SearchInput />
          </Box>
          <Box paddingX={2} flexGrow={1}>
            <DateTime
              className="header-input"
              name="start"
              placeholder="From Date/Time"
              value={this.state.start}
              onChange={this.handleChange}
            />
          </Box>
          <Box paddingX={2} flexGrow={1}>
            <DateTime
              className="header-input"
              name="end"
              placeholder="To Date/Time"
              value={this.state.end}
              onChange={this.handleChange}
            />
          </Box>
          <Box paddingX={2} flexGrow={1}>
            <select
              className="header-select"
              name="mapType"
              value={this.state.mapType}
              onChange={this.handleChange}
            >
              <option value="heatmap">Heatmap</option>
              <option value="passedby">Passed by Places</option>
              <option value="origin">Origin of Users</option>
            </select>
          </Box>
        </Header>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: 'auto',
          }}
        >
          <GoogleMapReact />
        </div>
      </div>
    )
  }
}

export default Heatmap
