import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import PopupTab from '../ui/PopupTab'
import popup from './Popup.module.css'

const PopupTabs = ({ tabs, ...props }) => {
  const tabList = (
    <TabList className={popup.tabs}>
      {tabs.map(({ icon, label }) => {
        return (
          <Tab className={popup.button} key={icon + label}>
            <PopupTab icon={icon} label={label} />
          </Tab>
        )
      })}
    </TabList>
  )

  const tabPanels = tabs.map(({ icon, label, component }) => {
    return <TabPanel key={icon + label}>{component(props)}</TabPanel>
  })

  return (
    <Tabs selectedTabClassName={popup.buttonActive}>
      {tabList}
      {tabPanels}
    </Tabs>
  )
}

export default PopupTabs
