import React from 'react'

import styles from './Square.module.css'

const Square = ({ children, ...props }) => {
  return (
    <div className={styles.square} {...props}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default Square
