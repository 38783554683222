import React from 'react'

import styles from './Heading.module.css'
import typography from './Typography.module.css'

const Heading = ({
  size = 'md',
  truncate,
  overflow,
  className,
  children,
  ...props
}) => {
  const cs = [
    styles[size],
    styles.heading,
    truncate && typography.truncate,
    overflow && typography.overflow,
    className,
  ]
    .filter((c) => c)
    .join(' ')
    .trim()

  return (
    <div className={cs} {...props}>
      {children}
    </div>
  )
}

export default Heading
