import React from 'react'

import Square from '../../ui/Square'
import Img from '../../ui/Img'

import { destructureDate } from '../../../utils'
import { getMedia } from '../../../utils/normalize'

import styles from '../App.module.css'

const DatePreview = ({ image, date }) => {
  const { day, month } = destructureDate(date)
  return (
    <div className={styles.date}>
      <Square>
        <Img src={getMedia(image, 'tiny')} fit="cover" fill />
      </Square>
      <div className={styles.dateOverlay}>
        <div className={styles.dateDay}>{day}</div>
        <div className={styles.dateWeekday}>{month}</div>
      </div>
    </div>
  )
}

export default DatePreview
