import store from '../store'
import { addNotification } from '../store/notification'
import { getHeaders } from './headers'

function handleErrors(response) {
  if (response.ok) {
    return response
  }
  throw Error(response.statusText)
}

function readResponse(response) {
  return response
    .clone()
    .json()
    .catch(() => response.text())
}

function handleResult(res) {
  if (typeof res === 'string') {
    return res
  }

  const { auth, response, message, error } = res

  if (res.status === 200) {
    if (auth !== undefined && auth.api === false) {
      window.location.replace('/logout')
    }

    // if (res.size === 0) {
    //   throw Error('Response size: 0')
    // }

    if (response !== undefined) {
      if (response.error !== undefined) {
        throw Error(response.error)
      }
      return response
    } else if (message !== undefined) {
      throw Error(message)
    } else {
      throw Error(JSON.stringify(res))
    }
  } else if (error) {
    throw Error(error)
  } else {
    throw Error(JSON.stringify(res))
  }
}

function catchErrors(error) {
  console.error('Looks like there was a problem: \n', error)
  store.dispatch(addNotification({ title: `Error`, text: error.message }))
  throw error
}

export const BASE_URL = 'https://saas.festivality.co/api'

export default function api(url, options) {
  const headers = getHeaders()
  const params = {
    headers,
    ...options,
  }
  return fetch(url, params)
    .then(handleErrors)
    .then(readResponse)
    .then(handleResult)
    .catch(catchErrors)
}
