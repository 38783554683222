import Design from './Design'
import Settings from './Settings'
import AppStore from './Appearance'
import Deploy from './Deploy'
// import Promote from './Promote'
import Banners from './Banners'
import Frontpage from './Frontpage'

export const config = {
  prefix: '/setup',
}

export default [
  { path: '/design', component: Design, label: 'Design', value: '🎨' },
  {
    path: '/settings',
    component: Settings,
    label: 'Settings',
    value: '⚙️',
  },
  {
    path: '/frontpage',
    component: Frontpage,
    label: 'Frontpage',
    value: '📱',
  },
  { path: '/banners', component: Banners, label: 'Banners', value: '💲' },
  {
    path: '/appearance',
    component: AppStore,
    label: 'Appearance',
    value: '📦',
  },
  { path: '/deploy', component: Deploy, label: 'Deploy', value: '🚀' },
  // { path: '/setup/promote', component: Promote, label: 'Promote', value: '🚀' },
]
