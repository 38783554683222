export const FETCH_ITEMS_REQUEST = 'app/FETCH_ITEMS_REQUEST'
export const FETCH_ITEMS_SUCCESS = 'app/FETCH_ITEMS_SUCCESS'
export const FETCH_ITEMS_FAILURE = 'app/FETCH_ITEMS_FAILURE'

export const ADD_ITEM_REQUEST = 'app/ADD_ITEM_REQUEST'
export const ADD_ITEM_SUCCESS = 'app/ADD_ITEM_SUCCESS'
export const ADD_ITEM_FAILURE = 'app/ADD_ITEM_FAILURE'

export const UPDATE_ITEM_REQUEST = 'app/UPDATE_ITEM_REQUEST'
export const UPDATE_ITEM_SUCCESS = 'app/UPDATE_ITEM_SUCCESS'
export const UPDATE_ITEM_FAILURE = 'app/UPDATE_ITEM_FAILURE'

export const DELETE_ITEM_REQUEST = 'app/DELETE_ITEM_REQUEST'
export const DELETE_ITEM_SUCCESS = 'app/DELETE_ITEM_SUCCESS'
export const DELETE_ITEM_FAILURE = 'app/DELETE_ITEM_FAILURE'

export const SORT_ITEMS_REQUEST = 'app/SORT_ITEMS_REQUEST'
export const SORT_ITEMS_SUCCESS = 'app/SORT_ITEMS_SUCCESS'
export const SORT_ITEMS_FAILURE = 'app/SORT_ITEMS_FAILURE'

// TODO
export const CLEAR_APP_DATA = 'app/CLEAR_APP_DATA'
