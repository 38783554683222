import React from 'react'

import Square from '../ui/Square'
import Mask from './Mask'
import Img from './Img'

// TODO: Add placeholder
const Avatar = ({ size, src, className, ...props }) => {
  return (
    <Mask shape="circle" className={className}>
      <Square style={{ width: size }}>
        {src && <Img src={src} fit="cover" fill {...props} />}
      </Square>
    </Mask>
  )
}

export default Avatar
