import React from 'react'

import table from './Table.module.css'

const TableBody = ({ data, component, ...props }) => {
  return (
    <div className={table.body}>
      {data.map((item, i) => {
        return React.cloneElement(component, {
          ...props,
          item,
          key: item.id || i,
        })
      })}
    </div>
  )
}

export default TableBody
