import Featured from './views/Featured'
import Grid from './views/Grid'
import HSlider from './views/HSlider'
import Nearby from './views/Nearby'
import WhatsNext from './views/WhatsNext'

export const featured = {
  path: '/featured',
  label: 'Featured',
  component: Featured,
}

export const grid = {
  path: '/grid',
  label: 'Grid',
  component: Grid,
}

export const hslider = {
  path: '/slider',
  label: 'Slider',
  component: HSlider,
}

export const nearby = {
  path: '/nearby',
  label: 'Nearby',
  component: Nearby,
}

export const whatsnext = {
  path: '/whatsnext',
  label: 'Whats Next',
  component: WhatsNext,
}

export default [whatsnext, hslider, featured, grid, nearby]
