import React from 'react'

import styles from './SearchInput.module.css'

const SearchInput = (props) => {
  return (
    <input
      className={styles.search}
      type="text"
      placeholder="Search"
      name="filter"
      {...props}
    />
  )
}

export default SearchInput
