import React from 'react'

import store from '../../../store'
import { addAppChild, updateAppChild } from '../../../store/app'

function useSubmit(type) {
  const [isSubmitting, setSubmitting] = React.useState(false)

  const handleSubmit = (forma, item) => (ev) => {
    ev.preventDefault()
    if (forma.checkValidity(ev.target)) {
      const { state, media, remove } = forma

      setSubmitting(true)

      let callback
      if (item && item.id) {
        callback = updateAppChild(item, state, media, remove)
      } else {
        callback = addAppChild(type, state, media)
      }

      return store
        .dispatch(callback)
        .then(() => setSubmitting(false))
        .catch((err) => setSubmitting(false))
    } else {
      throw new Error('Check required fields')
    }
  }

  return [handleSubmit, isSubmitting]
}

export default useSubmit
