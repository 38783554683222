import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FormRow from '../../../forms/components/FormRow'
import Button, { css } from '../../../ui/inputs/Button'
import Square from '../../../ui/Square'
import { Text } from '../../../ui'
import FileInput from '../../../ui/helpers/FileInput'

const demo = {
  margin: 20,
}
const ButtonDemo = (props) => {
  return (
    <>
      <FormRow label="Default">
        <div style={{ margin: '0 -20px' }}>
          <div>
            <Button variant="default" size="sm" style={demo}>
              Button sm
            </Button>
            <Button variant="default" size="md" style={demo}>
              Button md
            </Button>
            <Button variant="default" size="lg" style={demo}>
              Button lg
            </Button>
          </div>
          <div>
            <Button variant="default" disabled style={demo}>
              Button
            </Button>
          </div>
        </div>
      </FormRow>

      <FormRow label="Outline">
        <div style={{ margin: '0 -20px' }}>
          <div>
            <Button variant="outline" size="sm" style={demo}>
              Button sm
            </Button>
            <Button variant="outline" size="md" style={demo}>
              Button md
            </Button>
            <Button variant="outline" size="lg" style={demo}>
              Button lg
            </Button>
          </div>
          <div>
            <Button variant="outline" disabled style={demo}>
              Button
            </Button>
          </div>
        </div>
      </FormRow>

      <FormRow label="Flat">
        <div style={{ margin: '0 -20px' }}>
          <div>
            <Button variant="flat" size="sm" style={demo}>
              Button sm
            </Button>
            <Button variant="flat" size="md" style={demo}>
              Button md
            </Button>
            <Button variant="flat" size="lg" style={demo}>
              Button lg
            </Button>
          </div>
          <div>
            <Button variant="flat" disabled style={demo}>
              Button
            </Button>
          </div>
        </div>
      </FormRow>

      <FormRow label="Other">
        <div style={{ margin: '0 -20px' }}>
          <div>
            <Button style={demo}>Button with custom class</Button>
            <Button rounded style={demo}>
              Rounded button
            </Button>
            <Button icon={<FontAwesomeIcon icon="eye" />} rounded style={demo}>
              Button with Icon
            </Button>

            <Button variant="outline" size="sm" rounded style={demo}>
              Add artist
            </Button>
          </div>
        </div>
      </FormRow>

      <FormRow label="Add button">
        <div>
          <Square>
            <Button variant="default" size="lg" square fill rounded={false}>
              <FileInput>
                <div
                  style={{ height: '100%', fontSize: 32, lineHeight: '64px' }}
                >
                  +
                </div>
              </FileInput>
            </Button>
          </Square>
        </div>
      </FormRow>

      <FormRow label="Button fit">
        <div style={{ height: 80 }}>
          <Button variant="flat" size="md" fit rounded={false}>
            <Text bold>Save and close</Text>
          </Button>
        </div>
      </FormRow>
      <FormRow label="Button fill">
        <div style={{ height: 80 }}>
          <Button variant="flat" size="md" fill rounded={false}>
            <Text bold>Save and close</Text>
          </Button>
        </div>
      </FormRow>
      <FormRow label="Block button">
        <Button block>Block button</Button>
      </FormRow>
      <FormRow label="Circle button">
        <Button
          icon={<FontAwesomeIcon icon="eye" />}
          variant="flat"
          rounded
          square
          size="lg"
        />
        <div className={css.active}>
          <Button
            icon={<FontAwesomeIcon icon="eye" />}
            variant="flat"
            rounded
            square
            size="lg"
          />
        </div>
      </FormRow>
    </>
  )
}

export default ButtonDemo
