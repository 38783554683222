import React from 'react'

import { Heading, Text } from '../../../ui'
import {
  floating,
  ethereum,
  website_setup,
  movie_night,
} from '../../../../images/undraw'

import styles from './Stats.module.css'

const StatItem = ({ title, description, image }) => {
  return (
    <div
      className={styles.card}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <Heading>{title}</Heading>
      <Text size="sm">{description}</Text>
    </div>
  )
}

const Stats = (props) => (
  <div className={styles.stats}>
    <StatItem
      image={floating}
      title="Total Installs"
      description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut blanditiis deserunt voluptatem aspernatur, ratione numquam illo nostrum."
    />
    <StatItem
      image={movie_night}
      title="Total Users"
      description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum
      ducimus reprehenderit velit voluptatem sunt tempora unde vel!"
    />
    <StatItem
      image={ethereum}
      title="Telemetry Enabled"
      description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non
      recusandae inventore asperiores, minima deserunt facere at dolorum."
    />
    <StatItem
      image={website_setup}
      title="Push Notifications"
      description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit fugit
      perferendis nulla velit cupiditate, aspernatur repudiandae, iure iusto
      magni rem quia saepe officiis suscipit!"
    />
  </div>
)

export default Stats
