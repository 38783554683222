import api, { BASE_URL } from './api'
import { getHeaders } from './headers'

export function getAllTypes() {
  return api(`${BASE_URL}/type`)
}

export function getTypeList(type) {
  return api(`${BASE_URL}/${type}`)
}

export function getItem(type, id) {
  return api(`${BASE_URL}/${type}/${id}`)
}

export function postItem(data) {
  const body = JSON.stringify(data)
  return api(`${BASE_URL}/item`, { method: 'POST', body })
}

export function deleteItem(type, id) {
  const body = JSON.stringify({ type, id })
  return api(`${BASE_URL}/delete`, { method: 'POST', body })
}

export function sortItems(type, order) {
  const body = JSON.stringify({ type, order })
  return api(`${BASE_URL}/order`, { method: 'POST', body })
}

export function uploadFile({ id, file, type, label }) {
  const headers = getHeaders()
  delete headers['Content-Type']

  // const type = !!file.type.match('image') ? 'image' : 'file'

  const body = new FormData()
  body.append('targetId', id)
  body.append('targetType', type)
  body.append('file', file)
  body.append('title', file.name)
  body.append('state', 'draft')
  // body.append('object', label)
  // body.append('type', label)

  return api(`${BASE_URL}/upload`, {
    method: 'POST',
    headers,
    body,
  })
}

export function uploadBase64(uuid, base, label) {
  const body = JSON.stringify({
    referenceId: uuid,
    base64image: base,
    targetType: label,
  })
  return api(`${BASE_URL}/upload`, { method: 'POST', body })
}

function deleteFileByCode(code) {
  const body = JSON.stringify({ code, isActive: false })
  return api(`${BASE_URL}/delete`, { method: 'POST', body })
}

export function deleteFile(url) {
  const body = JSON.stringify({ url })
  return api(`${BASE_URL}/delete`, { method: 'POST', body }).then(
    deleteFileByCode
  )
}
