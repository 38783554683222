import React from 'react'
import TYPE from '../../../api/types'

import { Box } from '../../ui/layout'
import { ColorInput } from '../../ui/inputs'
import FormRow from '../components/FormRow'
import FormImage from '../../ui/inputs/FormImage'

import { getLastImage } from '../../../utils/normalize'

import appSetupFormFactory from '../../factories/appSetupFormFactory'

const DesignForm = ({
  state,
  media,
  bindInput,
  handleUpload,
  handleDelete,
}) => {
  return (
    <>
      <FormRow label="Primary Colors" inline={false}>
        <Box>
          <ColorInput placeholder="Primary" {...bindInput('primary')} />
          <ColorInput
            placeholder="Primary variant"
            {...bindInput('primaryVariant')}
            style={{ marginTop: '8px' }}
          />
          <ColorInput
            placeholder="On primary"
            {...bindInput('onPrimary')}
            style={{ marginTop: '8px' }}
          />
        </Box>
      </FormRow>

      <FormRow label="Secondary Colors" inline={false}>
        <Box>
          <ColorInput placeholder="Secondary" {...bindInput('secondary')} />
          <ColorInput
            placeholder="Secondary variant"
            {...bindInput('secondaryVariant')}
            style={{ marginTop: '8px' }}
          />
          <ColorInput
            placeholder="On secondary"
            {...bindInput('onSecondary')}
            style={{ marginTop: '8px' }}
          />
        </Box>
      </FormRow>

      <FormRow label="Splash Screen Image" inline={false}>
        <FormImage
          label="Select file"
          name="splash"
          onChange={handleUpload}
          onDelete={handleDelete}
          src={getLastImage(media.splash)}
          height={500}
        />
      </FormRow>

      <FormRow label="Menu Header Image" inline={false}>
        <FormImage
          label="Select file"
          name="cover"
          onChange={handleUpload}
          onDelete={handleDelete}
          src={getLastImage(media.cover)}
          height={160}
        />
      </FormRow>
    </>
  )
}

export default appSetupFormFactory(TYPE.APP_DESIGN, DesignForm)
