import React from 'react'
import { Timer } from './utils'

import { Box } from '../../ui/layout'
import { Heading, Text } from '../../ui'

import styles from './Notification.module.css'

function createTimer(dismissAfter, callback) {
  if (dismissAfter > 0) {
    return new Timer(dismissAfter, callback)
  }
  return null
}

class Notification extends React.Component {
  state = {}
  componentDidMount() {
    const { notification, remove } = this.props
    const timer = createTimer(notification.dismissAfter, (ev) =>
      remove(notification.id)
    )
    timer.resume()
    this.setState({
      _timer: timer,
    })
  }

  remove = () => {
    const { notification, remove } = this.props
    remove(notification.id)
  }

  pauseTimer = (ev) => {
    const { _timer } = this.state
    _timer.pause()
  }

  resumeTimer = (ev) => {
    const { _timer } = this.state
    _timer.resume()
  }

  render() {
    const { _timer } = this.state
    const { notification } = this.props

    if (notification.component) {
      return React.cloneElement(
        notification.component(notification, this.remove),
        {
          onClick: notification.dismissible ? this.remove : null,
          onMouseEnter: _timer ? this.pauseTimer : null,
          onMouseLeave: _timer ? this.resumeTimer : null,
        }
      )
      // return notification.component(notification, this.remove)
    } else {
      return (
        <div
          className={styles.notification}
          onClick={notification.dismissible ? this.remove : null}
          onMouseEnter={_timer ? this.pauseTimer : null}
          onMouseLeave={_timer ? this.resumeTimer : null}
        >
          <Box paddingX={4} paddingY={2}>
            {notification.title && (
              <Heading size="sm">{notification.title}</Heading>
            )}
            <Text size="xs">{notification.text}</Text>
            {/* <Box display="flex" justifyContent="space-between">
              <Heading size="sm">{notification.id}</Heading>
              {notification.dismissible && <div>×</div>}
            </Box> */}
            {/* <Box paddingY={2}>
              <Text size="xs">{notification.text}</Text>
            </Box> */}
          </Box>
        </div>
      )
    }
  }
}

export default Notification
