export const USER = '_user'
export const APP = '_app'

export const ARTIST = '_artist'
export const SPEAKER = '_speaker'
export const VENUE = '_venue'
export const EVENT = '_event'
export const PAGE = '_page'
export const PRODUCT = '_product'
export const OFFER = '_offer'
export const EXHIBITOR = '_exhibitor'
export const ATTENDEE = '_attendee'
export const PARTNER = '_partner'

export const BANNER = '_banner'

export const NOTIFICATION = '_notification'

export const APP_DESIGN = '_app_design'
export const APP_SETTINGS = '_app_settings'
export const APP_FRONTPAGE = '_app_frontpage'
export const APP_APPEARANCE = '_app_appearance'

export default {
  USER,
  APP,

  ARTIST,
  SPEAKER,
  VENUE,
  EVENT,
  PAGE,
  PRODUCT,
  OFFER,
  EXHIBITOR,
  ATTENDEE,
  PARTNER,

  BANNER,

  NOTIFICATION,

  APP_DESIGN,
  APP_SETTINGS,
  APP_FRONTPAGE,
  APP_APPEARANCE,
}
