import React from 'react'

import FormFooter from './FormFooter'
import FormButton from './FormButton'
import Spinner from '../../ui/Spinner'

import { connect } from 'react-redux'
import { updateUserData, isFetching } from '../../../store/user'

class ProfileFormActions extends React.Component {
  handleSubmit = async (ev) => {
    ev.preventDefault()
    const { state, media } = this.props
    try {
      await this.props.dispatch(updateUserData(state, media))
      this.props.hideModal()
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <>
        <FormFooter>
          <FormButton
            style={{ marginLeft: 'auto' }}
            onClick={this.handleSubmit}
          >
            Save
          </FormButton>
        </FormFooter>
        <Spinner show={this.props.isFetching} />
      </>
    )
  }
}

function mapStateToProps(state) {
  return { isFetching: isFetching(state) }
}

export default connect(mapStateToProps)(ProfileFormActions)
