import React, { useState, useMemo } from 'react'
import cc from 'classcat'
import { useComponentWillUnmount, useImagePreloader } from '../../utils/hooks'

import styles from './Image.module.css'
import shimmer from './Shimmer.module.css'

export function getKey(image) {
  return typeof image === 'string' ? image.slice(-8) : image.name
}

function createObjectURL(src) {
  if (src instanceof File) {
    console.log('create', src.name)
    return window.URL.createObjectURL(src)
  } else {
    return src
  }
}

function revokeObjectURL(url) {
  if (url.startsWith('blob:')) {
    console.log('revoke', url)
    window.URL.revokeObjectURL(url)
  }
}

const Img = ({ src = '', fit, fill, className, ...props }) => {
  const url = useMemo(() => createObjectURL(src), [src])
  useComponentWillUnmount(() => revokeObjectURL(url))

  const [image, setImage] = useState(null)
  useImagePreloader(url, setImage)

  const cls = cc({
    [styles.image]: true,
    [styles.fill]: fill,
    [styles[fit]]: fit,
    [className]: className,
  })

  return image ? (
    <img className={cls} src={image} alt="" {...props} />
  ) : (
    <div
      className={cls}
      style={{ width: props.width, height: props.height, ...props.style }}
    >
      <div
        className={shimmer.background}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  )
}

export default Img
