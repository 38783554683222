import React from 'react'

import { Box } from '../../../ui/layout'
import { Toggle, Input } from '../../../ui/inputs'
import FormRow from '../../components/FormRow'

export const WhatsNext = ({ state, bindInput }) => {
  return (
    <FormRow label="What's Next" inline={false}>
      <Toggle
        label="Show What's Next"
        reverse
        justify
        checked={!!state.showWhatsNext}
        {...bindInput('showWhatsNext')}
      />
      <Box marginTop="12px">
        <Input
          placeholder="Edit title"
          disabled={!state.showWhatsNext}
          {...bindInput('whatsNextTitle')}
        />
      </Box>
      <Box marginTop="12px">
        <Input
          type="number"
          min="2"
          max="100"
          placeholder="Number of events shown"
          disabled={!state.showWhatsNext}
          {...bindInput('whatsNextCount')}
        />
      </Box>
      <Box marginTop="12px">
        <Input
          type="number"
          min="0"
          max="10000"
          placeholder="Speed of shown events"
          disabled={!state.showWhatsNext}
          {...bindInput('whatsNextSpeed')}
        />
      </Box>
    </FormRow>
  )
}

export const Slider = ({ state, bindInput }) => {
  return (
    <FormRow label="Horizontally scrollable tiles" inline={false}>
      <Toggle
        label="Enable horizontally scrollable tiles"
        reverse
        justify
        checked={!!state.showSlider}
        {...bindInput('showSlider')}
      />
    </FormRow>
  )
}

export const GridView = ({ state, bindInput }) => {
  return (
    <FormRow label="Grid view tiles" inline={false}>
      <Toggle
        label="Enable grid system"
        reverse
        justify
        checked={!!state.showGridLayout}
        {...bindInput('showGridLayout')}
      />
    </FormRow>
  )
}

export const Featured = ({ state, bindInput }) => {
  return (
    <FormRow label="Featured" inline={false}>
      <Toggle
        label="Show Featured section"
        reverse
        justify
        checked={!!state.showFeatured}
        {...bindInput('showFeatured')}
      />

      <Box marginTop="12px">
        <Input
          placeholder="Edit title"
          disabled={!state.showFeatured}
          {...bindInput('featuredTitle')}
        />
      </Box>
    </FormRow>
  )
}

export const Nearby = ({ state, bindInput }) => {
  return (
    <FormRow label="Nearby" inline={false}>
      <Toggle
        label="Show Events Nearby"
        reverse
        justify
        checked={!!state.showNearby}
        {...bindInput('showNearby')}
      />
      <Box marginTop="12px">
        <Input
          placeholder="Edit title"
          disabled={!state.showNearby}
          {...bindInput('nearbyTitle')}
        />
      </Box>
      <Box marginTop="12px">
        <Input
          type="number"
          min="2"
          max="100"
          placeholder="Number of events shown"
          disabled={!state.showNearby}
          {...bindInput('nearbyCount')}
        />
      </Box>
      <Box marginTop="12px">
        <Input
          type="number"
          min="0"
          max="1000"
          placeholder="Nearby radius (km)"
          disabled={!state.showNearby}
          {...bindInput('nearbyRadius')}
        />
      </Box>
    </FormRow>
  )
}

export const sections = {
  WhatsNext,
  Slider,
  GridView,
  Featured,
  Nearby,
}

export const order = ['WhatsNext', 'Slider', 'GridView', 'Featured', 'Nearby']

export default [WhatsNext, Slider, GridView, Featured, Nearby]
