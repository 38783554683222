import React from 'react'

import TableFirstColumn from './components/TableFirstColumn'
import { HideItemButton, DeleteItemButton } from './actions'

export default [
  {
    prop: 'data.eventName',
    header: 'Event name',
    render: ({ id, data, media }) => (
      <TableFirstColumn image={media[0]} title={data.eventName} link={id} />
    ),
    width: '50%',
  },
  { prop: 'data.eventType', header: 'Event type' },
  { prop: 'data.eventStart', header: 'Event start' },
  { prop: 'data.eventEnd', header: 'Event end' },
  HideItemButton,
  DeleteItemButton,
]
