import React from 'react'
import cc from 'classcat'

import styles from './Button.module.css'
import themes from './Themes.module.css'

export const css = themes

const Button = ({
  children,
  className,
  icon,
  rounded,
  square,
  block,
  fill,
  fit,
  variant = 'default',
  size = 'md',
  tag,
  ...props
}) => {
  const classes = cc({
    [styles.button]: true,
    [styles[size]]: true,
    [themes[variant]]: true,
    [styles.rounded]: rounded,
    [styles.square]: square || !children,
    [styles.block]: block,
    [styles.fill]: fill,
    [styles.fit]: fit,
    [className]: className,
  })

  const Tag = tag || 'button'

  return (
    <Tag type="button" className={classes} {...props}>
      {children}
      {icon && (
        <span className={children ? styles.icon : undefined}>{icon}</span>
      )}
    </Tag>
  )
}

export default Button
