import React from 'react'

import FormRow from '../components/FormRow'
import { Text } from '../../ui'
import { Box } from '../../ui/layout'
import { Input } from '../../ui/inputs'

const BillingForm = ({ bindInput }) => {
  return (
    <>
      <Box paddingX={6} paddingY={6}>
        <Text size="sm">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi iste
          laudantium corporis voluptatibus expedita distinctio magnam, deserunt
          voluptas dolor hic qui quae nesciunt veniam eius!
        </Text>
      </Box>

      <FormRow label="Cardholder Name">
        <Input placeholder="John Doe" {...bindInput('cardHolder')} />
      </FormRow>
      <FormRow label="Card Number">
        <Input placeholder="1234 5678 9012 3456" {...bindInput('cardNumber')} />
      </FormRow>
      <FormRow label="Expiration Date / CVC">
        <Box display="flex">
          <Input placeholder="10/12" {...bindInput('expirationDate')} />
          <Input
            placeholder="353"
            {...bindInput('cvc')}
            style={{ marginLeft: '16px' }}
          />
        </Box>
      </FormRow>
    </>
  )
}

export default BillingForm
