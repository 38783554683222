import TYPE from '../../../api/types'

import speakersModel from '../../tables/speakersModel'
import { SpeakerModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.SPEAKER,
  title: 'Speakers',
  table: speakersModel,
  modal: {
    header: 'Edit Speaker',
    component: SpeakerModal,
  },
}

export default pageFactory(page)
