import React from 'react'

import Menu from '../components/Menu'
import Routes from '../components/Routes'

import routes from './routes'

export const FrontpageMenu = ({ match }) => {
  return <Menu label="Frontpage" routes={routes} url={match.url} />
}

const FrontpageRoutes = ({ match }) => (
  <Routes
    match={match}
    routes={routes}
    redirect={{ from: match.url, to: match.url + routes[0].path }}
  />
)

export default FrontpageRoutes
