import React from 'react'
import cc from 'classcat'

import Square from '../../ui/Square'
import Img from '../../ui/Img'
import { getMedia } from '../../../utils/normalize'

import { ReactComponent as BookmarkIcon } from '../icons/baseline-bookmark_border-24px.svg'

// import styles from '../App.module.css'
import styles from './ListItem.module.css'

const ListItem = ({
  preview,
  reverse,
  image,
  title,
  description,
  imageClassName,
  showBookmark,
  ...props
}) => {
  const list_item = cc({
    [styles.item]: true,
    [styles.reverse]: reverse,
  })

  const previewClassName = imageClassName || styles.avatar

  return (
    <div className={list_item}>
      <div className={styles.preview}>
        {preview ? (
          preview
        ) : (
          <div className={previewClassName}>
            <Square>
              <Img src={getMedia(image, 'tiny')} fit="cover" fill />
            </Square>
          </div>
        )}
      </div>
      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        {description && <div className={styles.descr}>{description}</div>}
      </div>
      {showBookmark !== false && <BookmarkIcon style={{ fill: '#757575' }} />}
    </div>
  )
}

export default ListItem
