import React from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'

import routes from './routes'

import './index.css'

const Setup = (props) => {
  return (
    <Route
      path={`${props.match.url}/setup`}
      render={({ match }) => {
        return (
          <div className="rightside">
            <Switch>
              {routes.map((route) => {
                return (
                  <Route
                    path={`${match.url}${route.path}`}
                    component={route.component}
                    key={route.label}
                  />
                )
              })}
              <Redirect
                exact
                path={`${match.url}`}
                to={`${match.url}${routes[0].path}`}
              />
            </Switch>
          </div>
        )
      }}
    />
  )
}

export default withRouter(Setup)
