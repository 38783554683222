import React from 'react'
import {
  // NavLink,
  Switch,
  Route,
  // Redirect,
  withRouter,
} from 'react-router-dom'

import Navbar from './Navbar'

import styles from './Sidebar.module.css'

import routes from './routes'

class Sidebar extends React.Component {
  render() {
    const { match, showSidebar, toggleSidebar } = this.props
    return (
      <div className={styles.sidebar}>
        <Navbar />
        <div
          className={styles.menu}
          style={{ width: showSidebar ? '240px' : '0' }}
        >
          <Switch>
            {routes.map((route) => {
              return (
                <Route
                  path={`${match.url}${route.path}`}
                  component={route.links}
                  key={route.label}
                />
              )
            })}
          </Switch>
        </div>
        <div
          className="preview__resize preview__resize--right"
          onClick={toggleSidebar}
        />
      </div>
    )
  }
}

export default withRouter(Sidebar)
