import React from 'react'
import TYPE from '../../../../api/types'

import SortableMenu from './SortableMenu'
// import NetworkingSettings from './NetworkingSettings'

import FormRow from '../../components/FormRow'
import { RadioGroup, RadioButton } from '../../../ui/inputs'

import appSetupFormFactory from '../../../factories/appSetupFormFactory'

const AppSettingsForm = ({ state, bindInput, setProperty }) => {
  return (
    <>
      <FormRow
        label="Menu Order"
        description="Drag n drop menu items to change their order"
        inline={false}
      >
        <SortableMenu
          name="menu"
          items={state.menu}
          setProperty={setProperty}
        />
      </FormRow>

      <FormRow label="Menu Template" inline={false}>
        <RadioGroup {...bindInput('menuTemplate')}>
          <RadioButton value="With icons" label="With icons" />
          <RadioButton value="Without icons" label="Without icons" />
        </RadioGroup>
      </FormRow>

      <FormRow label="Artists Template" inline={false}>
        <RadioGroup {...bindInput('artistsTemplate')}>
          <RadioButton value="Listview" label="Listview" />
          <RadioButton value="Tiles" label="Tiles" />
        </RadioGroup>
      </FormRow>

      <FormRow label="Venues Template" inline={false}>
        <RadioGroup {...bindInput('venuesTemplate')}>
          <RadioButton value="Listview" label="Listview" />
          <RadioButton value="Tiles" label="Tiles" />
        </RadioGroup>
      </FormRow>

      <FormRow label="Events Template" inline={false}>
        <RadioGroup {...bindInput('eventsTemplate')}>
          <RadioButton value="Listview" label="Listview" />
          <RadioButton
            value="Listview and calendar view"
            label="Listview and calendar view"
          />
        </RadioGroup>
      </FormRow>

      <FormRow label="Pages Template" inline={false}>
        <RadioGroup {...bindInput('pagesTemplate')}>
          <RadioButton value="With Image" label="With Image" />
          <RadioButton value="Without Image" label="Without Image" />
        </RadioGroup>
      </FormRow>

      {/* <NetworkingSettings
        bindInput={bindInput}
        handleCheckbox={handleCheckbox}
        state={state}
      /> */}
    </>
  )
}

export default appSetupFormFactory(TYPE.APP_SETTINGS, AppSettingsForm)
