import React from 'react'

import FormRow from '../components/FormRow'
import { Input, Select } from '../../ui/inputs'
import Img from '../../ui/Img'
import CoverUpload from '../components/CoverUpload'

import popup from '../../ui/Popup.module.css'

const screens = [
  { value: 'frontpage', text: 'Front page' },
  { value: 'innerpage', text: 'Inner page' },
]

export const BannerForm = ({ media, handleUpload, bindInput }) => {
  return (
    <>
      <div className={popup.contentHeader} style={{ height: '200px' }}>
        <CoverUpload name="image" onChange={handleUpload}>
          {media.image && (
            <Img
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              src={media.image[media.image.length - 1]}
            />
          )}
        </CoverUpload>
      </div>

      <FormRow label="Link">
        <Input required placeholder="https://" {...bindInput('url')} />
      </FormRow>
      <FormRow label="Appearance">
        <Select>
          {screens.map((option) => (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormRow>
    </>
  )
}

export default BannerForm
