import React from 'react'
import { connect } from 'react-redux'
import { removeNotification } from '../../../store/notification'

import Layer from '../../ui/Layer'
import Notification from './Notification'

import styles from './Notification.module.css'

class Notifications extends React.Component {
  remove = (ev, notification) => {
    this.props.dispatch(removeNotification(notification.id))
  }
  render() {
    const { notifications } = this.props
    return (
      <Layer>
        <div className={styles.notifications}>
          {notifications.map((notification) => (
            <Notification
              remove={(ev) => this.remove(ev, notification)}
              notification={notification}
              key={notification.id}
            />
          ))}
        </div>
      </Layer>
    )
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
})

export default connect(mapStateToProps)(Notifications)
