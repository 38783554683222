/*eslint-disable */
import frontpage from './images/frontpage.png'
// import menu from './images/menu.png'
import place from './images/place.png'
import schedule from './images/schedule.png'
import offer from './images/offer.png'
import artist from './images/artist.png'
import event_page from './images/event.png'
import map from './images/map.png'
import attendees from './images/attendees.png'
import playlist from './images/playlist.png'
import profile from './images/profile.png'
import socials from './images/socials.png'
import discover from './images/discover.png'
/*eslint-enable */

const images = {
  frontpage: discover,
  artists: frontpage,
  events: schedule,
  venues: place,
  playlist: playlist,
  socials: socials,
  pages: event_page,
  profilepages: event_page,
  offers: offer,
  shop: offer,
  exhibitors: attendees,
  attendees: attendees,
  networking: attendees,
}

export default images
