import React from 'react'

import Slider from '../../components/Slider'
import Img, { getKey } from '../../ui/Img'
import CoverUpload from './CoverUpload'

import styles from './ImageSlider.module.css'
import popup from '../../ui/Popup.module.css'

const SliderContainer = ({ files, height }) => {
  const slides = files.map((image, i) => {
    const key = getKey(image)
    return (
      <Img
        className={styles.img}
        height={height}
        src={image}
        alt={key}
        key={key}
      />
    )
  })

  const controls = ({ nextSlide, prevSlide, currentSlide, slides }) => {
    return (
      <div>
        {currentSlide > 0 && (
          <button
            type="button"
            className={`${styles.arrow} ${styles.prev}`}
            onClick={prevSlide}
          >
            Back
          </button>
        )}

        {currentSlide < slides.length - 1 && (
          <button
            type="button"
            className={`${styles.arrow} ${styles.next}`}
            onClick={nextSlide}
          >
            Next
          </button>
        )}
      </div>
    )
  }

  return <Slider slides={slides}>{controls}</Slider>
}

const ImageSlider = ({ files = [], height = 300, ...props }) => {
  return (
    <div className={popup.contentHeader} style={{ height }}>
      {files.length ? (
        <SliderContainer files={files} height={height} />
      ) : (
        <CoverUpload multiple name={props.name} onChange={props.onChange} />
      )}
    </div>
  )
}

export default ImageSlider
