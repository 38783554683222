import TYPE from '../../../api/types'

import attendeesModel from '../../tables/attendeesModel'
import { AttendeeModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.ATTENDEE,
  title: 'Attendees',
  table: attendeesModel,
  modal: {
    header: 'Edit Attendee',
    component: AttendeeModal,
  },
}

export default pageFactory(page)
