import React from 'react'

import { Box } from '../../ui/layout'
import { Heading } from '../../ui'

import { maker_launch } from '../../../images/undraw'

const Deploy = (props) => {
  return (
    <>
      <Box
        height="58px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={6}
        paddingY={3}
      >
        <Heading>Deploy</Heading>
      </Box>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${maker_launch})`,
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </>
  )
}

export default Deploy
