import React from 'react'

import { Button } from '../../ui/inputs'
import { Text } from '../../ui'

const FormButton = ({ children, ...props }) => {
  return (
    <Button
      variant="flat"
      size="lg"
      fit
      rounded={false}
      type="button"
      {...props}
    >
      <Text size="sm" bold>
        {children}
      </Text>
    </Button>
  )
}

export default FormButton
