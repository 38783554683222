import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import SearchInput from '../../../ui/SearchInput'
import { Button } from '../../../ui/inputs'

const HeaderDemo = (props) => {
  return (
    <>
      <FormRow label="Header search">
        <SearchInput />
      </FormRow>
      <FormRow label="Header button">
        <Button variant="outline" size="sm" rounded>
          Button
        </Button>
      </FormRow>
    </>
  )
}

export default HeaderDemo
