import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'
import FormSocials from '../components/FormSocials'

import { Input, Select, TextArea, FormFiles } from '../../ui/inputs'

export const PageForm = ({
  handleUpload,
  handleDelete,
  bindInput,
  state,
  media,
}) => {
  function changePlaceholder(value) {
    switch (state.pageType) {
      case 'default':
        return 'Page'
      case 'sponsor':
        return 'Brand or company'
      case 'merchant':
        return 'Brand or venue'
      case 'exhibitor':
        return "Exhibitor's brand or company"
      default:
        return 'Page'
    }
  }
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Page Images">
        <FormFiles
          name="image"
          files={media.image}
          onChange={handleUpload}
          onDelete={handleDelete}
        />
      </FormRow>

      <FormRow label="Page Name">
        <Input
          placeholder={changePlaceholder() + ' name'}
          {...bindInput('title')}
        />
      </FormRow>

      <FormRow label="Page Type">
        <Select placeholder="Select page type" {...bindInput('pageType')}>
          <option value="default">Simple page</option>
          <option value="sponsor">Sponsor/Brand</option>
          <option value="merchant">Merchant/Vendor</option>
          <option value="exhibitor">Exhibitor</option>
        </Select>
      </FormRow>

      <FormRow label="Page description" inline={false}>
        <TextArea
          placeholder={changePlaceholder() + ' description'}
          {...bindInput('description')}
        />
      </FormRow>

      <FormSocials bindInput={bindInput} />
    </>
  )
}

export default PageForm
