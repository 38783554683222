import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import { Toggle } from '../../../ui/inputs'

const ToggleDemo = (props) => {
  return (
    <>
      <FormRow label="Toggle">
        <Toggle name="system_toggle" label="Toggle input" />
      </FormRow>
      <FormRow label="Toggle reverse">
        <Toggle name="system_toggle" label="Toggle input" reverse />
      </FormRow>
      <FormRow label="Toggle reverse justify">
        <Toggle name="system_toggle" label="Toggle input" reverse justify />
      </FormRow>
      <FormRow label="Toggle inline">
        <Toggle name="system_toggle" label="Toggle input" inline />
      </FormRow>
      <FormRow label="Toggle inline reverse">
        <Toggle name="system_toggle" label="Toggle input" inline reverse />
      </FormRow>
    </>
  )
}

export default ToggleDemo
