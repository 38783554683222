import React from 'react'

class Emoji extends React.PureComponent {
  render() {
    const { children, label, value, ...props } = this.props
    return (
      <span role="img" aria-label={label} {...props}>
        {value || children}
      </span>
    )
  }
}

export default Emoji
