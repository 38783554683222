import React from 'react'
import { Tile } from '../templates'
import mapType from '../mapType'
import TYPE from '../../../api/types'

const Offers = ({ data }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {data.map(({ data, media, id }) => (
        <Tile
          square
          image={media[0]}
          title={data.title}
          description={`${data.position}, ${data.country}`}
          key={id}
        />
      ))}
    </div>
  )
}

Offers.defaultProps = {
  data: [],
}

export default mapType(TYPE.OFFER)(Offers)
