import React from 'react'

import { connect } from 'react-redux'
import { fetchLastItem, getAppType } from '../../store/app'

import Spinner from '../ui/Spinner'

import useFetcher from './useFetcher'

const DataFetcher = (type) => (Component) => {
  const DataFetcher = ({ data, ...props }) => {
    const { loading, fetchData } = useFetcher(() => {
      props.dispatch(fetchLastItem(type))
    })

    return (
      <>
        {loading === false &&
          data &&
          React.createElement(Component, {
            ...props,
            item: data,
            onRetry: () => fetchData(),
          })}
        <Spinner show={loading} />
      </>
    )
  }

  function mapStateToProps(state) {
    return {
      data: getAppType(state, type),
    }
  }

  return connect(mapStateToProps)(DataFetcher)
}

export default DataFetcher
