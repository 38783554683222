import reducer from './reducers'

export { initialState } from './reducers'

export * from './selectors'
export * from './operations'
export * from './types'

export default reducer

// import * as appSelectors from './selectors'
// import * as appOperations from './operations'
// import * as appTypes from './types'

// export { appSelectors, appOperations, appTypes }
