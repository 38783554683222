import TYPE from '../../../api/types'

import artistsModel from '../../tables/artistsModel'
import { ArtistModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.ARTIST,
  title: 'Artists',
  table: artistsModel,
  modal: {
    // button: 'Add Artist',
    header: 'Edit Artist',
    component: ArtistModal,
  },
}

export default pageFactory(page)
