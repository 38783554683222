import React from 'react'

import Label from './Label'
import Toggle from './base/Toggle'

const ToggleButton = ({ justify, reverse, inline, label, style, ...props }) => {
  if (label) {
    return (
      <Label
        {...{
          justify,
          reverse,
          inline,
          label,
          style,
        }}
      >
        <Toggle {...props} />
      </Label>
    )
  } else {
    return <Toggle {...props} />
  }
}

export default ToggleButton
