import React from 'react'
import cc from 'classcat'

import styles from './Badge.module.css'

const Badge = ({ text, position = 'middle', children, ...props }) => {
  const cls = cc([styles.badge, styles.default, styles[position]])

  return (
    <span className={cls} {...props}>
      {text || children}
    </span>
  )
}

export default Badge
