import React from 'react'

import styles from './Popup.module.css'

const Popup = ({ hideModal, header, style, children }) => {
  return (
    <div className={styles.popup}>
      <div className={styles.header}>
        {header}
        <div className={styles.close} onClick={hideModal}>
          ×
        </div>
      </div>
      <div className={styles.content} style={style}>
        {children}
      </div>
    </div>
  )
}

export default Popup
