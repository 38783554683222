import TYPE from '../../../api/types'

import pagesModel from '../../tables/pagesModel'
import { PageModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.PAGE,
  title: 'Pages',

  table: pagesModel,
  modal: {
    header: 'Edit Page',
    component: PageModal,
  },
}

export default pageFactory(page)
