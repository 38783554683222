import React from 'react'

import AppSettingsForm from '../../forms/app/AppSettingsForm'
import DataFetcher from '../../components/DataFetcher'
import TYPE from '../../../api/types'

const Settings = ({ item }) => {
  return (
    <AppSettingsForm
      title="App Settings"
      initialState={item.data}
      initialMedia={item.media}
      item={item}
    />
  )
}

Settings.defaultProps = {
  item: {
    data: {},
    media: {},
  },
}

export default DataFetcher(TYPE.APP_SETTINGS)(Settings)
