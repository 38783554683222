import React from 'react'

import Img from '../../ui/Img'
import { getMedia } from '../../../utils/normalize'

import { ReactComponent as BookmarkIcon } from '../icons/baseline-bookmark_border-24px.svg'

import styles from './Tile.module.css'

const Tile = ({ image, title, square, showBookmark, style }) => {
  return (
    <div
      className={styles.tile}
      style={{
        height: '160px',
        ...(square && { width: '50%' }),
        ...style,
      }}
    >
      <Img src={getMedia(image)} fit="cover" fill />
      <div className={styles.overlay}>{title}</div>
      {showBookmark !== false && (
        <div style={{ position: 'absolute', top: 16, right: 16 }}>
          <BookmarkIcon style={{ fill: '#fff' }} />
        </div>
      )}
    </div>
  )
}

export default Tile
