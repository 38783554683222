import React from 'react'
import TableColumn from '../base/TableColumn'
import TableRow from '../base/TableRow'

import { cellWidth } from './index'

import table from '../Table.module.css'

export default function createTableHead(model) {
  const TableHead = ({ sortItems, ...props }) => (
    <div className={table.header}>
      <TableRow>
        {model.map((column, i) => {
          const header =
            typeof column.header === 'function'
              ? column.header(props)
              : column.header

          const sortable = !!column.prop && column.sortable !== false
          const sortableStyles = {
            ...(!sortable && { pointerEvents: 'none' }),
          }

          const styles = {
            ...column.style,
            ...sortableStyles,
            ...cellWidth(column.width),
          }

          return (
            <TableColumn
              {...sortable && sortItems(column.prop)}
              style={styles}
              key={column.prop || i}
            >
              {header}
            </TableColumn>
          )
        })}
      </TableRow>
    </div>
  )

  return TableHead
}
