import React from 'react'

import FrontpageSettingsForm from '../../forms/app/frontpage'
import DataFetcher from '../../components/DataFetcher'
import TYPE from '../../../api/types'

const Frontpage = ({ item }) => {
  return (
    <FrontpageSettingsForm
      title="Frontpage settings"
      initialState={item.data}
      initialMedia={item.media}
      item={item}
    />
  )
}

Frontpage.defaultProps = {
  item: {
    data: {},
    media: {},
  },
}

export default DataFetcher(TYPE.APP_FRONTPAGE)(Frontpage)
