import React from 'react'
import { Link } from 'react-router-dom'

import styles from './Desktop.module.css'

function stringToHSL(str) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const v = (hash >> (i * 8)) & 0xff
  return `hsl(${v}, 50%, 50%)`
}

const DesktopIcon = ({ id, name, icon, handleClick }) => {
  return (
    <Link
      className={styles.app__item}
      to={`/app/${id}`}
      onClick={(ev) => handleClick(ev, id)}
      draggable="false"
    >
      <div className={styles.app__icon} style={{ color: stringToHSL(name) }}>
        {icon ? <img src={icon} alt={name} /> : name[0]}
      </div>
      <div className={styles.app__name}>{name}</div>
    </Link>
  )
}

export default DesktopIcon
