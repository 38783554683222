import React from 'react'

import Popup from '../ui/Popup'
import Forma from '../components/Forma'
import FormActions from '../forms/components/FormActions'

import { getDisplayName } from './utils'

function contentFormModalFactory(type, component, defaultProps) {
  const WrappedComponent = ({ hideModal, header, item }) => {
    const beforeHideModal = (touched) => (ev) => {
      if (touched) {
        const confirm = window.confirm('Are you sure?')
        if (confirm) {
          hideModal()
        }
      } else {
        hideModal()
      }
    }
    return (
      <Forma initialState={item.data} initialMedia={item.media}>
        {(forma) => (
          <Popup header={header} hideModal={beforeHideModal(forma.isTouched)}>
            <form onBlur={forma.handleBlur}>
              {React.createElement(component, forma)}
              <FormActions
                type={type}
                item={item}
                hideModal={hideModal}
                {...forma}
              />
            </form>
          </Popup>
        )}
      </Forma>
    )
  }

  WrappedComponent.defaultProps = {
    item: {
      data: {},
      media: {},
    },
    ...defaultProps,
  }

  WrappedComponent.displayName = `${getDisplayName(component)}Modal`
  return WrappedComponent
}

export default contentFormModalFactory
