import TYPE from '../../../api/types'

import offersModel from '../../tables/offersModel'
import { OfferModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.OFFER,
  title: 'Offers',
  table: offersModel,
  modal: {
    header: 'Edit Offer',
    component: OfferModal,
  },
}

export default pageFactory(page)
