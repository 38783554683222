import React from 'react'
import { Box } from './index'
import { Heading } from '..'

const Header = ({ title, children, ...props }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingX={4}
      paddingY={3}
      background="rgba(255, 255, 255, 0.98)"
      minHeight={56}
    >
      {title && (
        <Box paddingX={2}>
          <Heading>{title}</Heading>
        </Box>
      )}
      {children}
    </Box>
  )
}

export default Header
