import React from 'react'

import Select from './base/Select'

import countries from './SelectCountry.json'

const SelectCountry = (props) => {
  return (
    <Select placeholder="Select country" {...props}>
      {countries.map(({ name, code }) => (
        <option value={code} key={code}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default SelectCountry
