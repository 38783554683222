import React from 'react'

import table from '../Table.module.css'

const TableColumn = ({ children, ...props }) => {
  return (
    <div className={`${table.cell} ${table.column}`} {...props}>
      {children}
    </div>
  )
}

export default TableColumn
