import { useRef, useState, useEffect } from 'react'

const useFetcher = (action) => {
  const isMounted = useRef(true)
  const [loading, isLoading] = useState(true)
  const [error, isError] = useState(false)

  const fetchData = async () => {
    if (error) isError(false)
    isLoading(true)

    try {
      if (typeof action === 'function') await action()
    } catch (error) {
      if (isMounted.current) isError(true)
    } finally {
      if (isMounted.current) isLoading(false)
    }
  }

  useEffect(() => {
    fetchData()

    return () => {
      isMounted.current = false
    }
  }, [])

  return { loading, error, fetchData }
}

export default useFetcher
