import React, { useState } from 'react'

import FormRow from '../../../forms/components/FormRow'
import TagsInput from '../../../ui/inputs/TagsInput'

const exampleItems = [
  'Чехия',
  'Мальта',
  'Нидерланды',
  'Германия',
  'Гватемала',
  'США',
  'Люксембург',
  'Канада',
  'Австралия',
]

const TagsInputDemo = (props) => {
  const [state, setState] = useState({ tags: exampleItems })

  const setProperty = (name, value) => {
    setState({ [name]: value })
  }

  return (
    <FormRow label="Tags">
      {/* <TagInput
        name="tags"
        value={state.tags}
        onChange={({ name, value }) => setProperty(name, value)}
      />
      <TagItems items={state.tags} name="tags" onChange={handleDelete} /> */}
      <TagsInput
        name="tags"
        value={state.tags}
        onChange={(ev, { name, value }) => setProperty(name, value)}
      />
    </FormRow>
  )
}

export default TagsInputDemo
