import React from 'react'
import { connect } from 'react-redux'
// import Slider from 'react-slick'
import Slider from '../components/Slider'
// import { createNewApp } from '../../store/user'

import Popup from '../ui/Popup'
import Forma from '../components/Forma'
import NewAppForm from '../forms/NewAppForm'
import FormButton from '../forms/components/FormButton'
import FormFooter from '../forms/components/FormFooter'

import help from '../../images/help.png'
import help2 from '../../images/help2.png'
import help3 from '../../images/help3.png'

const Slide = ({ image }) => {
  const header = {
    height: 300,
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    borderRadius: '4px 4px 0 0',
  }
  return <div style={header} />
}

const slides = [help, help2, help3]

class NewAppModal extends React.Component {
  handleSubmit = (forma) => (ev) => {
    const { state } = forma
    console.log('submit', state)
    // this.props.dispatch(createNewApp(form))
  }

  render() {
    const { props } = this

    const renderSlides = slides.map((slide) => (
      <Slide image={slide} key={slide} />
    ))

    return (
      <Forma>
        {(forma) => (
          <Popup header="App Setup" hideModal={props.hideModal}>
            <div>
              <Slider
                slides={[...renderSlides, <NewAppForm {...forma} key="form" />]}
              >
                {({ nextSlide, prevSlide, currentSlide, slides }) => (
                  <FormFooter>
                    {currentSlide > 0 && (
                      <FormButton onClick={prevSlide}>Back</FormButton>
                    )}

                    {currentSlide < slides.length - 1 ? (
                      <FormButton
                        style={{ marginLeft: 'auto' }}
                        onClick={nextSlide}
                      >
                        Next
                      </FormButton>
                    ) : (
                      <FormButton
                        style={{ marginLeft: 'auto' }}
                        onClick={this.handleSubmit(forma)}
                      >
                        Create
                      </FormButton>
                    )}
                  </FormFooter>
                )}
              </Slider>
            </div>
          </Popup>
        )}
      </Forma>
    )
  }
}

export default connect(null)(NewAppModal)
