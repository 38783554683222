import React from 'react'
import MatchLink from '../../components/MatchLink'
import { Box } from '../../ui/index'
import Avatar from '../../ui/Avatar'

import { getMedia } from '../../../utils/normalize'

import styles from './TableFirstColumn.module.css'

const TableFirstColumn = ({ image, title, link }) => {
  return (
    <Box display="flex" alignItems="center">
      <MatchLink className={styles.tableLink} to={link}>
        <Avatar size={36} src={getMedia(image, 'tiny')} alt={title} />
      </MatchLink>
      <Box paddingX={2}>
        <MatchLink className={styles.tableLink} to={link}>
          {title}
        </MatchLink>
      </Box>
    </Box>
  )
}

export default TableFirstColumn
