import factory from '../factories/contentFormModalFactory'
import TYPE from '../../api/types'

import ArtistForm from '../forms/content/ArtistForm.js'
import AttendeeForm from '../forms/content/AttendeeForm.js'
import EventForm from '../forms/content/EventForm.js'
import ExhibitorForm from '../forms/content/ExhibitorForm.js'
import OfferForm from '../forms/content/OfferForm.js'
import PageForm from '../forms/content/PageForm.js'
import PartnerForm from '../forms/content/PartnerForm.js'
import ProductForm from '../forms/content/ProductForm.js'
import SpeakerForm from '../forms/content/SpeakerForm.js'
import VenueForm from '../forms/content/VenueForm.js'

import BannerForm from '../forms/content/BannerForm.js'

import NotificationForm from '../forms/NotificationForm.js'

export const ArtistModal = factory(TYPE.ARTIST, ArtistForm, {
  header: 'Add Artist',
})
export const AttendeeModal = factory(TYPE.ATTENDEE, AttendeeForm, {
  header: 'Add Attendee',
})
export const EventModal = factory(TYPE.EVENT, EventForm, {
  header: 'Add Event',
})
export const ExhibitorModal = factory(TYPE.EXHIBITOR, ExhibitorForm, {
  header: 'Add Exhibitor',
})
export const OfferModal = factory(TYPE.OFFER, OfferForm, {
  header: 'Add Offer',
})
export const PageModal = factory(TYPE.PAGE, PageForm, { header: 'Add Page' })
export const PartnerModal = factory(TYPE.PARTNER, PartnerForm, {
  header: 'Add Partner',
})
export const ProductModal = factory(TYPE.PRODUCT, ProductForm, {
  header: 'Add Product',
})
export const SpeakerModal = factory(TYPE.SPEAKER, SpeakerForm, {
  header: 'Add Speaker',
})
export const VenueModal = factory(TYPE.VENUE, VenueForm, {
  header: 'Add Venue',
})

export const BannerModal = factory(TYPE.BANNER, BannerForm, {
  header: 'Add Banner',
})

export const NotificationModal = factory(TYPE.NOTIFICATION, NotificationForm, {
  header: 'Add Notification',
})

export default {
  ArtistModal,
  AttendeeModal,
  EventModal,
  ExhibitorModal,
  OfferModal,
  PageModal,
  PartnerModal,
  ProductModal,
  SpeakerModal,
  VenueModal,

  BannerModal,
  NotificationModal,
}
