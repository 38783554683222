import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import popup from './Popup.module.css'

const PopupTab = ({ icon, label }) => {
  return (
    <>
      <FontAwesomeIcon icon={icon} size="3x" />
      <div className={popup.buttonTitle}>{label}</div>
    </>
  )
}

export default PopupTab
