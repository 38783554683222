import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'
import FormLocation from '../components/FormLocation'
import FormSocials from '../components/FormSocials'

import { Input, TextArea, FormFiles } from '../../ui/inputs'

import PopupTabs from '../../ui/PopupTabs'
import RelatedItemsForm from '../../forms/related/RelatedItemsForm'
import relatedArtistsModel from '../../tables/relatedArtistsModel'
import relatedVenuesModel from '../../tables/relatedVenuesModel'

import TYPE from '../../../api/types'

const VenueForm = ({
  handleChange,
  handleUpload,
  handleDelete,
  setProperty,
  state,
  media,
  bindInput,
}) => {
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Venue Images">
        <FormFiles
          files={media.image}
          name="image"
          onChange={handleUpload}
          onDelete={handleDelete}
        />
      </FormRow>

      <FormRow label="Venue name">
        <Input placeholder="Place name" {...bindInput('title')} />
      </FormRow>

      <FormLocation
        state={state}
        bindInput={bindInput}
        handleChange={handleChange}
        setProperty={setProperty}
      />

      <FormRow label="Venue capacity">
        <Input
          type="number"
          min="0"
          placeholder="1000"
          {...bindInput('capacity')}
        />
      </FormRow>

      <FormRow label="Venue description" inline={false}>
        <TextArea placeholder="About venue" {...bindInput('description')} />
      </FormRow>

      <FormSocials bindInput={bindInput} />
    </>
  )
}

const VenueFormWithTabs = (props) => {
  return (
    <PopupTabs
      tabs={[
        {
          icon: 'map-marker-alt',
          label: 'Venue',
          component: () => <VenueForm {...props} />,
        },
        {
          icon: 'calendar',
          label: 'Events',
          component: () => (
            <RelatedItemsForm
              type={TYPE.EVENT}
              model={relatedVenuesModel}
              {...props}
            />
          ),
        },
        {
          icon: 'microphone',
          label: 'Artists',
          component: () => (
            <RelatedItemsForm
              type={TYPE.ARTIST}
              model={relatedArtistsModel}
              {...props}
            />
          ),
        },
      ]}
    />
  )
}

export default VenueFormWithTabs
