import React from 'react'
import Input from './base/Input'

import styles from './ColorInput.module.css'

class ColorInput extends React.Component {
  render() {
    const { style, ...props } = this.props
    return (
      <div className={styles.relative} style={style}>
        <Input {...props} />
        <label className={styles.preview}>
          <div
            className={styles.color}
            style={{
              backgroundClip: 'content-box',
              backgroundColor: props.value,
            }}
          />
          <input hidden type="color" {...props} />
        </label>
      </div>
    )
  }
}

export default ColorInput
