import React from 'react'

import { connect } from 'react-redux'
import { syncChanges, clearTemp } from '../../../store/temp'

class FormaRedux extends React.Component {
  componentDidMount() {
    this.syncForm()
  }

  componentDidUpdate() {
    this.syncForm()
  }

  componentWillUnmount = () => {
    this.props.dispatch(clearTemp())
  }

  syncForm() {
    const { type, forma } = this.props
    const { state, media } = forma
    const form = {
      [type]: { state, media },
    }
    this.props.dispatch(syncChanges(form))
  }

  render() {
    return this.props.children
  }
}
export default connect()(FormaRedux)
