import React from 'react'

import Label from './Label'
import Checkbox from './base/Checkbox'

const CheckboxButton = ({
  justify,
  reverse,
  inline,
  label,
  style,
  ...props
}) => {
  if (label) {
    return (
      <Label
        {...{
          justify,
          reverse,
          inline,
          label,
          style,
        }}
      >
        <Checkbox {...props} />
      </Label>
    )
  } else {
    return <Checkbox {...props} />
  }
}

export default CheckboxButton
