import React from 'react'

import styles from './TagsInput.module.css'
import { Input } from '.'

export const TagInput = ({ onChange, value: items, ...props }) => {
  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      const { name } = ev.target
      const value = ev.target.value.trim()

      if (value === '') return
      if (items.indexOf(value) !== -1) return

      const update = [...items, value]
      onChange && onChange(ev, { name, value: update })

      ev.target.value = ''
    }
  }
  return <Input {...props} onKeyPress={handleKeyPress} />
}

const TagItem = ({ onDelete, children, ...props }) => {
  return (
    <div className={styles.tagsInputItem}>
      <span onClick={props.onClick}>{children}</span>
      <svg
        className={styles.tagsInputItemDelete}
        role="button"
        viewBox="0 0 15 15"
        onClick={onDelete}
      >
        <path d="M0 15V0v15zM15 0v15V0zM8.35 7.5l3.22 3.22a.5.5 0 0 1 0 .71l-.14.14a.5.5 0 0 1-.7 0L7.5 8.35l-3.22 3.22a.5.5 0 0 1-.7 0l-.15-.14a.5.5 0 0 1 0-.7L6.65 7.5 3.43 4.28a.5.5 0 0 1 0-.71l.14-.14a.5.5 0 0 1 .7 0L7.5 6.65l3.22-3.22a.5.5 0 0 1 .7 0l.15.14a.5.5 0 0 1 0 .7L8.35 7.5z" />
      </svg>
    </div>
  )
}

export const TagItems = ({ name, items, onDelete }) => {
  const handleRemove = (toRemove) => (ev) => {
    const update = items.filter((item) => item !== toRemove)
    onDelete && onDelete(ev, update)
  }
  const clickableItems = items.map((item) => (
    <TagItem value={item} onDelete={handleRemove(item)} key={item}>
      {item}
    </TagItem>
  ))
  return <div>{clickableItems}</div>
}

const TagsInput = ({ name, value, onChange }) => {
  const onDelete = (ev, value) => {
    onChange && onChange(ev, { name, value })
  }

  return (
    <div>
      <TagInput name={name} value={value} onChange={onChange} />
      <TagItems name={name} items={value} onDelete={onDelete} />
    </div>
  )
}

export default TagsInput
