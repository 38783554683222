import React from 'react'

import AppearanceForm from '../../forms/app/AppearanceForm'
import DataFetcher from '../../components/DataFetcher'
import TYPE from '../../../api/types'

const Appearance = ({ item }) => {
  return (
    <AppearanceForm
      title="Appearance"
      initialState={item.data}
      initialMedia={item.media}
      item={item}
    />
  )
}

Appearance.defaultProps = {
  item: {
    data: {},
    media: {},
  },
}

export default DataFetcher(TYPE.APP_APPEARANCE)(Appearance)
