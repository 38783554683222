import { combineReducers } from 'redux'
import * as types from './types'

import bg1 from '../../images/background.jpg'
import bg2 from '../../images/klaukre6gl_1680x1050.jpg'
import bg3 from '../../images/atelier_bebop_desktop_1.jpg'

const previewReducer = (state = true, action) => {
  switch (action.type) {
    case types.TOGGLE_PREVIEW: {
      return action.state
    }
    default:
      return state
  }
}

const sidebarReducer = (state = true, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR: {
      return action.state
    }
    default:
      return state
  }
}

const buttonsInitialState = [
  'artists',
  'speakers',
  'venues',
  'events',
  'pages',
  'exhibitors',
  'partners',
  'products',
  'offers',
  'banners',
  'notifications',
  'overview',
]

const buttonsReducer = (state = buttonsInitialState, action) => {
  switch (action.type) {
    case types.UPDATE_BUTTONS: {
      return action.state
    }
    default:
      return state
  }
}

const wallpaperInitialState = {
  wallpapers: [bg1, bg2, bg3],
  currentIndex: 2,
}

const wallpaperReducer = (state = wallpaperInitialState, action) => {
  switch (action.type) {
    case types.CHANGE_WALLPAPER:
      const index = state.currentIndex + 1
      const currentIndex = index % state.wallpapers.length
      return { ...state, currentIndex }
    default:
      return state
  }
}

const reducer = combineReducers({
  preview: previewReducer,
  sidebar: sidebarReducer,
  buttons: buttonsReducer,
  wallpaper: wallpaperReducer,
})

export default reducer
