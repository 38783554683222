import React, { useState, useRef, useMemo } from 'react'
import {
  SortableElement,
  SortableContainer,
  SortableHandle,
} from 'react-sortable-hoc'

import './SortableFrontpage.css'

import { sections, order as initialOrder } from './sections'
import { arrayMove, mapObjectToArray } from '../../../../utils'

const mapSections = (order) => mapObjectToArray(sections, order)

const SortableItem = SortableElement((props) => props.children)
const SortableList = SortableContainer((props) => props.children)

const DragHandle = SortableHandle((props) => {
  return (
    <div
      className="sortable-handle"
      style={{ position: 'absolute', top: 12, right: 24 }}
    >
      <div>..</div>
      <div>..</div>
      <div>..</div>
    </div>
  )
})

const SortableFrontpage = (props) => {
  const sortableContainer = useRef(null)
  const settings = {
    helperClass: 'dragging',
    lockAxis: 'y',
    lockOffset: 0,
    lockToContainerEdges: true,
    helperContainer: sortableContainer.current,
    useDragHandle: true,
  }

  const derivedOrder = props.state.order
  const [sectionsOrder, updateOrder] = useState(derivedOrder || initialOrder)

  // const onSortStart = ({ node, index, collection }, ev) => {
  //   ev.preventDefault()
  // }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = arrayMove(sectionsOrder, oldIndex, newIndex)
    updateOrder(sorted)
    props.setProperty('order', sorted)
  }

  const sections = useMemo(() => mapSections(sectionsOrder), [sectionsOrder])

  return (
    <SortableList
      {...settings}
      // onSortStart={onSortStart}
      onSortEnd={onSortEnd}
    >
      <div ref={sortableContainer}>
        {sections.map((section, i) => (
          <SortableItem index={i} key={`section-${i}`}>
            <div className="sortable-item">
              <DragHandle />
              {React.createElement(section, props)}
            </div>
          </SortableItem>
        ))}
      </div>
    </SortableList>
  )
}

export default SortableFrontpage
