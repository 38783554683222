import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import images from './screenshots'
import routes from './routes'

export const Placeholder = ({ location }) => {
  const page = location.pathname.split('/').pop()
  return (
    <img
      style={{ position: 'absolute', top: 0 }}
      src={images[page] || images.frontpage}
      alt={page}
    />
  )
}

export const Routes = (props) => {
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          path={`${props.match.url}${route.path}`}
          component={route.component}
          key={i}
        />
      ))}
      <Route component={Placeholder} />
    </Switch>
  )
}

const AppRoutes = (props) => {
  if (props.location.hash) {
    const page = props.location.hash.substring(1)
    const image = images[page]
    if (image) {
      return <img src={image} alt={props.location.hash} />
    } else {
      return <Route component={Routes} />
    }
  } else {
    return <Route component={Routes} />
  }
}

export default withRouter(AppRoutes)
