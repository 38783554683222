import ContentRoutes, { ContentMenu } from '../content'
import OverviewRoutes, { OverviewMenu } from '../overview'
import FrontpageRoutes, { FrontpageMenu } from '../frontpage'

const frontpage = {
  path: '/frontpage',
  label: 'FrontpageMenu',
  links: FrontpageMenu,
  routes: FrontpageRoutes,
  fontAwesomeIcon: {
    icon: 'home',
  },
}

const content = {
  path: '/content',
  label: 'ContentMenu',
  links: ContentMenu,
  routes: ContentRoutes,
  fontAwesomeIcon: {
    icon: 'pencil-alt',
  },
}

const dashboard = {
  path: '/dashboard',
  label: 'OverviewMenu',
  links: OverviewMenu,
  routes: OverviewRoutes,
  fontAwesomeIcon: {
    icon: 'chart-pie',
    style: { transform: 'translateX(1px)' },
  },
}

export default [frontpage, content, dashboard]
