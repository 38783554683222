import React from 'react'

import styles from './Spinner.module.css'

const Spinner = ({ show, position, ...props }) => {
  if (show) {
    return (
      <div className={styles.overlay} style={{ position }} {...props}>
        <div className={styles.spinner} />
      </div>
    )
  } else {
    return null
  }
}

export default Spinner
