import React from 'react'

class Editable extends React.Component {
  state = {
    value: null,
  }

  reset = () => {
    this.setState({ value: null })
  }

  toggleEditing = (ev) => {
    this.setState({ value: this.props.value })
  }

  handleChange = (ev) => {
    this.setState({ value: ev.target.value })
  }

  handleBlur = (ev) => {
    this.reset()
  }

  onEnter = (ev) => {
    if (ev.key === 'Enter') {
      this.props.onChange(ev)
      this.reset()
    } else if (ev.key === 'Escape') {
      this.reset()
    }
  }

  render() {
    if (this.state.value !== null) {
      return (
        <input
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            border: 0,
            outline: 0,
            background: '#eee',
            width: '100%',
            height: 'inherit',
            lineHeight: 'inherit',
            verticalAlign: 'inherit',
            fontSize: 'inherit',
            padding: 'inherit',
            cursor: 'text',
          }}
          type="text"
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={this.onEnter}
          onFocus={(ev) => ev.target.select()}
          autoFocus
        />
      )
    } else {
      return React.cloneElement(this.props.children, {
        onDoubleClick: this.toggleEditing,
      })
    }
  }
}

export default Editable
