import React from 'react'
import { connect } from 'react-redux'
import {
  addAppChild,
  updateAppChild,
  deleteAppChild,
  isFetching,
} from '../../../store/app'

import PopupFormActions from './PopupFormActions'
import Spinner from '../../ui/Spinner'

class FormActions extends React.Component {
  static defaultProps = {
    type: null,
    item: {},
    state: {},
    media: null,
    remove: null,
  }

  handleSubmit = (ev) => {
    ev.preventDefault()
    if (this.props.checkValidity(ev.target)) {
      const { type, item, state, media, remove, dispatch } = this.props

      let callback
      if (item && item.id) {
        callback = updateAppChild(item, state, media, remove)
      } else {
        callback = addAppChild(type, state, media)
      }

      return dispatch(callback)
    } else {
      throw new Error('Check required fields')
    }
  }

  saveAndClose = async (ev) => {
    console.log('saveAndClose')
    try {
      await this.handleSubmit(ev)
      this.props.hideModal()
    } catch (error) {
      console.log(error)
    }
  }

  saveAndAddNew = async (ev) => {
    console.log('saveAndAddNew')
    try {
      await this.handleSubmit(ev)
      this.props.resetForm()
    } catch (error) {
      console.log(error)
    }
  }

  deleteItem = (ev) => {
    const { item, dispatch, hideModal } = this.props

    if (item && item.id) {
      if (window.confirm('Delete?')) {
        dispatch(deleteAppChild(item)).then(hideModal)
      }
    }
  }

  render() {
    return (
      <>
        <PopupFormActions
          state={this.props.state}
          handleChange={this.props.handleChange}
          item={this.props.item}
          deleteItem={this.deleteItem}
          saveAndAddNew={this.saveAndAddNew}
          saveAndClose={this.saveAndClose}
        />
        <Spinner show={this.props.isFetching} />
      </>
    )
  }
}

function mapStateToProps(state) {
  return { isFetching: isFetching(state) }
}

export default connect(mapStateToProps)(FormActions)
