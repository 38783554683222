import React from 'react'
import TableRow from '../base/TableRow'
import TableCell from '../base/TableCell'
import { cellWidth } from './index'

import dotProp from 'dot-prop-immutable'

function updateEventHandlers(props, item) {
  const handlers = {}

  if (props) {
    Object.keys(props).forEach((key) => {
      if (typeof props[key] === 'function') {
        handlers[key] = (ev) => props[key](item, ev)
      }
    })
  }

  return handlers
}

const tableCell = (item) => ({ prop, render, style, width }, i) => {
  const value = prop ? dotProp.get(item, prop, null) : null
  // const property = typeof prop === 'function' ? prop(item) : value

  const content = typeof render === 'function' ? render(item) : value

  const styles = { ...style, ...cellWidth(width) }

  return (
    <TableCell style={styles} key={prop || i}>
      {content}
    </TableCell>
  )
}

export default function createTableItem(model) {
  const TableItem = ({ item, itemEvents, ...props }) => {
    const eventHandlers = updateEventHandlers(itemEvents, item)
    return (
      <TableRow {...props} {...eventHandlers}>
        {model.map(tableCell(item))}
      </TableRow>
    )
  }
  return TableItem
}
