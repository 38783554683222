import { combineReducers } from 'redux'

function matchRegex(type) {
  return /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
}

export const isFetching = (state, type) => state.api.fetchingType[type]

export const fetchingReducer = (state = false, action) => {
  const matches = matchRegex(action.type)
  if (!matches) return state

  const [, , requestState] = matches

  switch (requestState) {
    case 'REQUEST':
      return true
    case 'SUCCESS':
    case 'FAILURE':
      return false
    default:
      return state
  }
}

export const fetchReducer = (state = {}, action) => {
  const matches = matchRegex(action.type)
  if (!matches) return state

  const [, requestName, requestState] = matches

  switch (requestState) {
    case 'REQUEST':
      return { ...state, [requestName]: true }
    case 'SUCCESS':
    case 'FAILURE':
      const clear = { ...state }
      delete clear[requestName]
      return clear
    default:
      return state
  }
}

export const errorReducer = (state = {}, action) => {
  const matches = matchRegex(action.type)
  if (!matches) return state

  const [, requestName, requestState] = matches
  switch (requestState) {
    case 'REQUEST':
    case 'SUCCESS':
      return {}
    case 'FAILURE':
      return { ...state, [requestName]: action.message }
    default:
      return state
  }
}

export const apiReducer = combineReducers({
  isFetching: fetchingReducer,
  fetchingType: fetchReducer,
  errorMessage: errorReducer,
})

export default apiReducer
