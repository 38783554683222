import React from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'

import routes from './routes'

const Content = ({ match }) => {
  return (
    <div className="content">
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}${routes[0].path}`} />
        {routes.map((route) => {
          return (
            <Route
              path={`${match.url}${route.path}`}
              component={route.routes}
              key={route.label}
            />
          )
        })}
        {/* <Route component={NoMatch} /> */}
      </Switch>
    </div>
  )
}

export default withRouter(Content)
