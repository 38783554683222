import React from 'react'

import styles from './FormFiles.module.css'

import { FileInput } from '../inputs'
import { Button } from '.'
import Img, { getKey } from '../Img'
import Square from '../Square'

const SquarePreview = (props) => {
  return (
    <div className={styles.column}>
      <Square>{props.children}</Square>
    </div>
  )
}

const AddButton = ({ multiple, accept, name, onChange, ...props }) => {
  return (
    <SquarePreview>
      <Button size="lg" square fill>
        <FileInput
          multiple
          accept={accept}
          name={name}
          onChange={onChange}
          {...props}
        >
          <div style={{ height: '100%', fontSize: 32, lineHeight: '64px' }}>
            +
          </div>
        </FileInput>
      </Button>
    </SquarePreview>
  )
}

export const Del = ({ onClick }) => {
  return (
    <div className={styles.del} onClick={onClick}>
      ×
    </div>
  )
}

const FormFiles = ({
  files = [],
  onDelete,
  onChange,

  max = Infinity,
  name,
  accept,

  ...props
}) => {
  const renderPreviews = () => {
    return files.map((image) => {
      const key = getKey(image)
      return (
        <SquarePreview key={key}>
          <Img
            src={image}
            alt={image.name}
            className={styles.img}
            fit="cover"
            fill
          />
          <Del onClick={(ev) => onDelete(ev, name, image)} />
        </SquarePreview>
      )
    })
  }
  return (
    <div className={styles.container}>
      {files.length > 0 && renderPreviews()}
      {files.length < max && (
        <AddButton
          name={name}
          accept={accept}
          onChange={onChange}
          multiple
          {...props}
        />
      )}
    </div>
  )
}

export default FormFiles
