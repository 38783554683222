import reducer from './reducers'

export { initialState } from './reducers'

export * from './selectors'
export * from './operations'
export * from './types'

export default reducer

// import * as userSelectors from './selectors'
// import * as userOperations from './operations'
// import * as userTypes from './types'

// export { userSelectors, userOperations, userTypes }
