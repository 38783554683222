import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoundButton, { styles } from '../../ui/RoundButton'

const NavbarButton = ({ to, label, icon }) => {
  return (
    <NavLink activeClassName={styles.active} to={to}>
      <RoundButton data-tooltip={label}>
        <FontAwesomeIcon {...icon} />
      </RoundButton>
    </NavLink>
  )
}

export default NavbarButton
