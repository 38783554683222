import React, { useRef } from 'react'
import { useTransition, animated, config } from 'react-spring'

import Layer from '../../ui/Layer'

import styles from './index.module.css'

const SpringPopup = ({
  isOpen,
  onDismiss,
  beforeDismiss,
  afterOpen,
  afterDismiss,
  closeOnEsc,
  closeOnClickOutside,
  children,
  name,
}) => {
  const focusable = useRef()

  const transitions = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0,100px,0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0px,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0,100px,0)',
    },
    unique: true,
    config: config.stiff,
    onFrame: handleFrame,
  })

  function handleFrame(state, type) {
    if (type === 'update') {
      // state ? console.log('mounted') : console.log('unmounted')
      state ? afterOpen && afterOpen() : afterDismiss && afterDismiss()
    }
  }

  function handleDismiss() {
    if (beforeDismiss ? beforeDismiss() : true) {
      onDismiss()
    }
  }

  function handleClickOutside(ev) {
    if (isOpen && ev.target === ev.currentTarget) {
      handleDismiss()
    }
  }

  function handleEsc(ev) {
    if (isOpen && ev.key === 'Escape') {
      handleDismiss()
    }
  }

  if (focusable.current) {
    focusable.current.focus()
  }

  return transitions.map(({ item, props, key }) => {
    const { opacity, transform } = props
    return (
      item && (
        <Layer key={key}>
          <animated.div
            className={styles.overlay}
            onClick={closeOnClickOutside ? handleClickOutside : undefined}
            style={{
              opacity,
              pointerEvents: isOpen ? 'auto' : 'none',
            }}
          >
            <animated.div
              ref={focusable}
              style={{ opacity, transform, margin: 'auto' }}
              onKeyDown={closeOnEsc ? handleEsc : undefined}
              tabIndex="0"
            >
              {React.cloneElement(children, { hideModal: handleDismiss })}
            </animated.div>
          </animated.div>
        </Layer>
      )
    )
  })
}

SpringPopup.defaultProps = {
  closeOnEsc: true,
  closeOnClickOutside: true,
}

function areEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen
}
export default React.memo(SpringPopup, areEqual)
