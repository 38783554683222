import React from 'react'
import FormRow from './FormRow'
import { Input } from '../../ui/inputs'

const FormSocials = ({ bindInput }) => {
  return (
    <>
      <FormRow label="Website URL">
        <Input placeholder="https://website.com" {...bindInput('website')} />
      </FormRow>
      <FormRow label="Facebook URL">
        <Input placeholder="https://facebook.com" {...bindInput('facebook')} />
      </FormRow>
      <FormRow label="Twitter URL">
        <Input placeholder="https://twitter.com" {...bindInput('twitter')} />
      </FormRow>
      <FormRow label="Instagram URL">
        <Input
          placeholder="https://instagram.com"
          {...bindInput('instagram')}
        />
      </FormRow>
    </>
  )
}

export default FormSocials
