import TYPE from '../../../api/types'

import eventsModel from '../../tables/eventsModel'
import { EventModal } from '../../modals'

import pageFactory from '../../factories/pageFactory'

const page = {
  type: TYPE.EVENT,
  title: 'Events',
  table: eventsModel,
  modal: {
    header: 'Edit Event',
    component: EventModal,
  },
}

export default pageFactory(page)
