import React from 'react'
import { connect } from 'react-redux'
import { hideAppChild } from '../../../store/app'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import cc from 'classcat'

import styles from '../../components/Table/Table.module.css'

const HideTableItemHeader = (props) => {
  return (
    <div style={{ width: '48px', textAlign: 'center' }}>
      <FontAwesomeIcon icon="eye" />
    </div>
  )
}

let HideTableItemButton = ({ item, dispatch }) => {
  const isActive = item.isActive

  const toggleItem = (ev) => {
    if (item.id) {
      // const state = isActive ? 1 : 0
      dispatch(hideAppChild(item, !isActive))
    }
  }

  return (
    <div
      className={cc({
        [styles.tableIcon]: true,
        [styles.tableIconShow]: !isActive,
      })}
      onClick={toggleItem}
    >
      <FontAwesomeIcon
        // icon={!isActive ? 'eye' : 'eye-slash'}
        icon="eye-slash"
      />
    </div>
  )
}

HideTableItemButton = connect()(HideTableItemButton)

export default {
  prop: 'visible',
  header: <HideTableItemHeader />,
  render: (item) => <HideTableItemButton item={item} />,
  style: {
    flex: 'none',
    width: '48px',
    color: 'var(--ssColorGray03)',
    opacity: '0.8',
    textAlign: 'center',
  },
}
