import React from 'react'
import { ListItem } from '../templates'
import mapType from '../mapType'
import TYPE from '../../../api/types'

const Exhibitors = ({ data }) => {
  return (
    <div>
      {data.map(({ data, media, id }) => (
        <ListItem
          image={media[0]}
          title={data.name}
          description={`${data.position}, ${data.country}`}
          key={id}
        />
      ))}
    </div>
  )
}

Exhibitors.defaultProps = {
  data: [],
}

export default mapType(TYPE.EXHIBITOR)(Exhibitors)
