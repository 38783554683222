import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'
import FormSocials from '../components/FormSocials'

import { SelectCountry, FormFiles, Input, TextArea } from '../../ui/inputs'

export const ArtistForm = ({
  media,
  handleUpload,
  handleDelete,
  bindInput,
}) => {
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Add Images">
        <FormFiles
          files={media.image}
          name="image"
          onChange={handleUpload}
          onDelete={handleDelete}
          accept=".jpg, .jpeg, .png"
          max={5}
        />
      </FormRow>

      <FormRow label="Artist Name">
        <Input required placeholder="Artist Name" {...bindInput('title')} />
      </FormRow>

      <FormRow label="Artist Country">
        <SelectCountry {...bindInput('country')} />
      </FormRow>

      <FormRow label="Artist Genre">
        <Input placeholder="Genre" {...bindInput('genre')} />
      </FormRow>

      <FormRow label="About Artist" inline={false}>
        <TextArea placeholder="About Artist" {...bindInput('description')} />
      </FormRow>

      <FormSocials bindInput={bindInput} />
    </>
  )
}

export default ArtistForm
