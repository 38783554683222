import React from 'react'

import FormRow from '../../../forms/components/FormRow'
import Text from '../../../ui/Text'
import { Heading } from '../../../ui/index'

const TextDemo = (props) => {
  return (
    <>
      <FormRow label="Headings">
        <Heading size="xs">Heading</Heading>
        <Heading size="sm">Heading</Heading>
        <Heading size="md">Heading</Heading>
        <Heading size="lg">Heading</Heading>
        <Heading size="xl">Heading</Heading>
      </FormRow>
      <FormRow label="Text">
        <Text size="xs">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
        <Text size="sm">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
        <Text size="md">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
        <Text size="lg">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
        <Text size="xl">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
      </FormRow>
      <FormRow label="Bold">
        <Text bold>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
      </FormRow>
      <FormRow label="Italic">
        <Text italic>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
      </FormRow>
      <FormRow label="Truncate">
        <Text truncate>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod,
          incidunt?
        </Text>
      </FormRow>
      <FormRow label="Overflow">
        <Text overflow>
          Lorem ipsum dolor, sitametconsecteturadipisictconsecteturadiingelit.
          Quodincidunt?
        </Text>
      </FormRow>
      <FormRow label="Align">
        <div>
          <Text align="left">Left</Text>
          <Text align="center">Center</Text>
          <Text align="right">Right</Text>
          <Text align="justify">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </Text>
        </div>
      </FormRow>
    </>
  )
}

export default TextDemo
