import React from 'react'
import { connect } from 'react-redux'
import { fetchUserCompanies } from '../../store/user'
import { Switch, Route, Redirect } from 'react-router-dom'

import Spinner from '../ui/Spinner'
import Notifications from '../components/Notifications'
import Desktop from './desktop/Desktop'
import Dashboard from './dashboard/Dashboard'
import System from './system'

class Home extends React.Component {
  state = {
    loading: true,
    error: null,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { dispatch } = this.props

    this.setState({ loading: true })

    dispatch(fetchUserCompanies())
      .catch((err) => {
        this.setState({ error: err })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    if (this.state.loading) {
      return <Spinner show={true} style={{ background: 'rgba(0,0,0,.5)' }} />
    } else {
      return (
        <>
          <Switch>
            <Route exact path="/" component={Desktop} />
            <Route path="/app/:app" component={Dashboard} />
            <Redirect exact from="/app" to="/" />
          </Switch>

          <Route exact path="/system" component={System} />

          <Notifications />
        </>
      )
    }
  }
}

export default connect(null)(Home)
