import React from 'react'

import Button, { css } from './inputs/Button'

export const styles = css

const RoundButton = ({ children, ...props }) => {
  return (
    <Button variant="flat" size="md" rounded square {...props}>
      {children}
    </Button>
  )
}

export default RoundButton
