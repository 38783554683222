import React from 'react'
import { NavLink } from 'react-router-dom'

import RoundButton, { styles } from '../../ui/RoundButton'
import Emoji from '../../ui/Emoji'

const NavbarButtonEmoji = ({ url, path, label, value }) => {
  return (
    <NavLink activeClassName={styles.active} to={`${url}${path}`}>
      <RoundButton data-tooltip={label}>
        <Emoji label={label} value={value} />
      </RoundButton>
    </NavLink>
  )
}

export default NavbarButtonEmoji
