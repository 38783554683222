import React from 'react'

import TableFirstColumn from './components/TableFirstColumn'
import { HideItemButton, DeleteItemButton } from './actions'

export default [
  {
    prop: 'data.name',
    header: 'Speaker',
    render: ({ id, data, media }) => (
      <TableFirstColumn image={media[0]} title={data.name} link={id} />
    ),
  },
  { prop: 'data.country', header: 'Country' },
  { prop: 'data.website', header: 'Website' },
  HideItemButton,
  DeleteItemButton,
]
