import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const MatchLink = withRouter(({ className, match, children, to }) => {
  return (
    <Link className={className} to={`${match.url}/${to}`}>
      {children}
    </Link>
  )
})

export default MatchLink
