import { groupBy } from './index'

export function transformItem(item) {
  if (item.id)
    return { ...item, data: item.collection, media: item.media || [] }
}

// const sortByDate = (a, b) => {
//   const dateA = a.meta.date.created
//   const dateB = b.meta.date.created
//   return new Date(dateA) - new Date(dateB)
// }

export function normalize(res) {
  const allIds = []
  const byId = {}

  for (let i = 0; i < res.length; i++) {
    const item = transformItem(res[i])
    allIds.push(item.id)
    byId[item.id] = item
  }

  return {
    allIds,
    byId,
  }
}

export function groupMedia(media) {
  const prop = (i) => i.object
  const value = (i) => i.collection.variations.original
  const group = groupBy(media, prop, value)
  return group
}

export const getMedia = (media, size) => {
  let url
  if (media) {
    const { variations } = media.collection
    url = size ? variations[size] : variations.original
  }
  return url
}

export function getLastImage(media) {
  return media && media.length > 0 && media[media.length - 1]
}

export function getLastOfType(res) {
  if (res.length > 0) {
    return res.pop()
  } else {
    return {}
  }
}

const noString = (file) => typeof file !== 'string'
const addProp = (label) => (file) => ({ file, label })

export function flattenMediaObject(media) {
  const to_upload = []
  for (let prop in media) {
    if (media.hasOwnProperty(prop)) {
      const files = media[prop]
      const upload = files.filter(noString).map(addProp(prop))

      to_upload.push(...upload)
    }
  }
  return to_upload
}
