import * as t from './types'

export const fetchItemsRequest = (label) => ({
  type: t.FETCH_ITEMS_REQUEST,
  label,
})
export const fetchItemsSuccess = (data, label) => ({
  type: t.FETCH_ITEMS_SUCCESS,
  data,
  label,
})
export const fetchItemsFailure = (message, label) => ({
  type: t.FETCH_ITEMS_FAILURE,
  message,
  label,
})

export const addItemRequest = (label) => ({
  type: t.ADD_ITEM_REQUEST,
  label,
})
export const addItemSuccess = (data, label) => ({
  type: t.ADD_ITEM_SUCCESS,
  data,
  label,
})
export const addItemFailure = (message, label) => ({
  type: t.ADD_ITEM_FAILURE,
  message,
  label,
})

export const updateItemRequest = (label) => ({
  type: t.UPDATE_ITEM_REQUEST,
  label,
})
export const updateItemSuccess = (data, label) => ({
  type: t.UPDATE_ITEM_SUCCESS,
  data,
  label,
})
export const updateItemFailure = (message, label) => ({
  type: t.UPDATE_ITEM_FAILURE,
  message,
  label,
})

export const deleteItemRequest = (label) => ({
  type: t.DELETE_ITEM_REQUEST,
  label,
})
export const deleteItemSuccess = (type, id) => ({
  type: t.DELETE_ITEM_SUCCESS,
  payload: { type, id },
})
export const deleteItemFailure = (message, label) => ({
  type: t.DELETE_ITEM_FAILURE,
  message,
  label,
})

export const sortItemsRequest = (label) => ({
  type: t.SORT_ITEMS_REQUEST,
  label,
})
export const sortItemsSuccess = (type, items) => ({
  type: t.SORT_ITEMS_SUCCESS,
  payload: { type, items },
})
export const sortItemsFailure = (message, label) => ({
  type: t.SORT_ITEMS_FAILURE,
  message,
  label,
})

export const clearAppData = () => ({ type: t.CLEAR_APP_DATA })
