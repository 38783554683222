const BEARER_TOKEN = 'bearerToken'

export function setAuthToken(token) {
  localStorage.setItem(BEARER_TOKEN, token)
}
export function clearAuthToken() {
  localStorage.removeItem(BEARER_TOKEN)
}
export function getAuthToken() {
  return localStorage.getItem(BEARER_TOKEN)
}
export function checkAuthToken() {
  return localStorage.getItem(BEARER_TOKEN) !== null
}
