import produce from 'immer'

const produceReducer = (initialState, actions) => (
  state = initialState,
  action
) =>
  produce(state, (draft) => {
    const actionType = actions[action.type]
    actionType && actionType(draft, action)
  })

export default produceReducer
