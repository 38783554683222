import React from 'react'
import { Route } from 'react-router-dom'

import DataProvider from '../components/DataProvider'
import Table from '../components/Table'
import TablePlaceholder from '../components/Table/TablePlaceholder'

import { Heading, Text, Box } from '../ui'
import SearchInput from '../ui/SearchInput'
import SpringPopup from '../components/Modal/SpringPopup'
import { Header } from '../ui/layout'
import { ModalButton } from '../components/Modal'
import { Button } from '../ui/inputs'
import Emoji from '../ui/Emoji'

const findItemById = (data, id) => {
  return data.filter((item) => item.id === id).pop()
}

const getItem = (data, match) => {
  return match ? findItemById(data, +match.params.id) : undefined
}

const PagePlaceholder = (props) => {
  return (
    <div className="page__placeholder">
      <div>
        <Heading>
          Nothing <Emoji label="shrug" value="🤷‍♂️" />
        </Heading>
        <Text>Start by adding some content</Text>
      </div>
    </div>
  )
}

const pageFactory = (options) => {
  class Page extends React.Component {
    openModal = (item) => {
      if (item && item.id) {
        this.props.history.push(`${this.props.match.url}/${item.id}`)
      }
    }
    render() {
      const { props } = this

      return (
        <DataProvider type={options.type}>
          {({ data, loading }, { filterItems, sortItems }) => (
            <div className="page">
              <Header
              // title={props.title || options.title}
              >
                <Box paddingX={2} flexGrow={1}>
                  <SearchInput onChange={filterItems} />
                </Box>
                <Box paddingX={2}>
                  <ModalButton component={options.modal.component}>
                    <Button variant="outline" size="sm" rounded>
                      {options.modal.button || 'Add New'}
                    </Button>
                  </ModalButton>
                  {/* {options.header(props, this)} */}
                </Box>
              </Header>

              <Table
                model={options.table}
                data={data}
                onSortEnd={sortItems}
                itemEvents={{
                  onDoubleClick: this.openModal,
                }}
              />
              {loading && <TablePlaceholder />}

              {!loading && data.length === 0 && <PagePlaceholder />}

              <Route exact path={`${props.match.url}/:id`}>
                {({ match, ...route }) => {
                  const handleDismiss = () =>
                    route.history.push(props.match.url)

                  const item = getItem(data, match)
                  const isOpen = item ? item.id : false

                  return (
                    <SpringPopup isOpen={isOpen} onDismiss={handleDismiss}>
                      {React.createElement(options.modal.component, {
                        header: options.modal.header,
                        item,
                      })}
                    </SpringPopup>
                  )
                }}
              </Route>
            </div>
          )}
        </DataProvider>
      )
    }
  }

  return Page
}

export default pageFactory
