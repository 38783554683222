// An array to store notifications object
const INITIAL_STATE = []
// Action types
const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS'

export const DEFAULT_NOTIFICATION = {
  status: 'default',
  dismissible: true,
  dismissAfter: 5000,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ADD_NOTIFICATION:
      const notification = Object.assign({}, DEFAULT_NOTIFICATION, payload)
      return [...state, notification]
    case UPDATE_NOTIFICATION:
      return state.map((notification) => {
        if (notification.id === payload.id) {
          return Object.assign({}, DEFAULT_NOTIFICATION, payload)
        }
        return notification
      })
    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== payload)
    case REMOVE_NOTIFICATIONS:
      return []
    default:
      return state
  }
}

function _addNotification(notification) {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  }
}

function _updateNotification(notification) {
  return {
    type: UPDATE_NOTIFICATION,
    payload: notification,
  }
}

export function removeNotification(notification) {
  return {
    type: REMOVE_NOTIFICATION,
    payload: notification,
  }
}

export function removeNotifications() {
  return {
    type: REMOVE_NOTIFICATIONS,
  }
}

export const addNotification = (notification) => (dispatch) => {
  if (!notification.id) {
    notification.id = new Date().getTime()
  }

  if (notification.dismissAfter) {
    notification.dismissAfter = parseInt(notification.dismissAfter, 10)
  }

  dispatch(_addNotification(notification))

  return notification
}

export const updateNotification = (notification) => (dispatch, getState) => {
  if (!notification.id) {
    throw new Error('A notification must have an `id` property to be updated')
  }

  // const notifications = getState().notifications
  // const index = notifications.findIndex(
  //   (oldNotification) => oldNotification.id === notification.id
  // )

  if (notification.dismissAfter) {
    notification.dismissAfter = parseInt(notification.dismissAfter, 10)
  }

  dispatch(_updateNotification(notification))

  return notification
}

export const notify = (notification) => (dispatch, getState) => {
  const notifications = getState().notifications
  const doesNotifExist = notifications.find(
    (notif) => notif.id === notification.id
  )

  if (doesNotifExist) {
    return dispatch(updateNotification(notification))
  }

  return dispatch(addNotification(notification))
}
