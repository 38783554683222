export const FETCH_COMPANIES_REQUEST = 'user/FETCH_COMPANIES_REQUEST'
export const FETCH_COMPANIES_SUCCESS = 'user/FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'user/FETCH_COMPANIES_FAILURE'

export const FETCH_PROFILE_REQUEST = 'user/FETCH_PROFILE_REQUEST'
export const FETCH_PROFILE_SUCCESS = 'user/FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_FAILURE = 'user/FETCH_PROFILE_FAILURE'

export const UPDATE_PROFILE_REQUEST = 'user/UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'user/UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'user/UPDATE_PROFILE_FAILURE'

export const CREATE_APP = 'user/CREATE_APP'
export const DELETE_APP = 'user/DELETE_APP'
export const SORT_APPS = 'user/SORT_APPS'
