import React from 'react'

import Apps from './DesktopApps'
import Delete from './DesktopDeleteHover'
import Spinner from '../../ui/Spinner'

import styles from './Desktop.module.css'

import { connect } from 'react-redux'
import { getUserApps, sortApps, deleteApp } from '../../../store/user'
import { clearAppData } from '../../../store/app'
import { clientLogin } from '../../../api'
import Navigation from './Navigation'

class Desktop extends React.Component {
  state = {
    sorting: false,
    loading: false,
    error: false,
  }

  handleClick = async (ev, id) => {
    ev.preventDefault()
    this.setState({ loading: true, error: false })
    try {
      const res = await clientLogin(id)
      if (res) {
        this.props.dispatch(clearAppData())
        this.props.history.push(`app/${id}`)
      }
    } catch (err) {
      this.setState({ loading: false, error: true })
    }
  }

  onSortStart = ({ node, index, collection }, ev) => {
    this.setState({ sorting: true })
  }

  onSortEnd = ({ oldIndex, newIndex }, ev) => {
    if (ev.target === this.thrash) {
      if (window.confirm('Are you sure you want to delete it?')) {
        const appId = this.props.apps[oldIndex].id
        this.props.dispatch(deleteApp(appId))
      }
    } else {
      this.props.dispatch(sortApps(oldIndex, newIndex))
    }
    this.setState({ sorting: false })
  }

  getRef = (ref, name) => {
    this[name] = ref
  }

  render() {
    const settings = {
      helperClass: styles.dragging,
      axis: 'xy',
      // pressDelay:200,
      distance: 10,
    }

    return (
      <div className={styles.desktop}>
        <Navigation />
        <Apps
          {...settings}
          apps={this.props.apps}
          onSortStart={this.onSortStart}
          onSortEnd={this.onSortEnd}
          handleClick={this.handleClick}
        />
        <Delete sorting={this.state.sorting} getRef={this.getRef} />
        <Spinner
          show={this.state.loading}
          style={{ background: 'rgba(0,0,0,.5)' }}
        />
      </div>
    )
  }
}

Desktop.defaultProps = {
  apps: [],
}

function mapStateToProps(state) {
  return { apps: getUserApps(state) }
}

export default connect(mapStateToProps)(Desktop)
