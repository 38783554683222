import React from 'react'
import { connect } from 'react-redux'
import { deleteAppChild } from '../../../store/app'

import Img from '../../ui/Img'
import ToggleButton from '../../ui/inputs/ToggleButton'
import { Button } from '../../ui/inputs'

import { ModalButton } from '../../components/Modal'
import { BannerModal } from '../../modals'

import { getMedia } from '../../../utils/normalize'

import styles from './Banner.module.css'

let BannerItemActions = ({ item, ...props }) => {
  const deleteItem = (ev) => {
    if (item && item.id) {
      if (window.confirm('Delete?')) {
        props.dispatch(deleteAppChild(item))
      }
    }
  }
  return (
    <div className="flex flex--between" style={{ marginTop: 8 }}>
      <Button onClick={deleteItem}>Delete</Button>
      <ToggleButton label="Visible" value={item.isActive} reverse />
    </div>
  )
}

BannerItemActions = connect()(BannerItemActions)

const BannerItem = ({ item }) => {
  return (
    <div className={styles.banner}>
      <ModalButton
        component={BannerModal}
        modalProps={{ item, header: 'Edit Banner' }}
      >
        <Img className={styles.image} src={getMedia(item.media[0], 'medium')} />
      </ModalButton>
      <BannerItemActions item={item} />
    </div>
  )
}

export default BannerItem
