import React, { useState, useRef } from 'react'
import Layer from './Layer'

import styles from './Tooltip.module.css'

function getPlacement(placement) {
  let position
  switch (+placement) {
    case 0:
      position = 'bottom'
      break
    case 1:
      position = 'top'
      break
    case 2:
      position = 'right'
      break
    case 3:
      position = 'left'
      break
    default:
      position = 'top'
  }
  return { [position]: '100%' }
}

function alignItems(placement, alignment) {
  if (+placement === 2) {
    return 'flex-end'
  } else if (+placement === 3) {
    return 'flex-start'
  } else {
    switch (+alignment) {
      case 0:
        return 'flex-start'
      case 1:
        return 'center'
      case 2:
        return 'flex-end'
      default:
        return 'center'
    }
  }
}

function justifyContent(placement, alignment) {
  if (+placement > 1) {
    switch (+alignment) {
      case 0:
        return 'flex-end'
      case 1:
        return 'center'
      case 2:
        return 'flex-start'
      default:
        return 'center'
    }
  } else {
    switch (+placement) {
      case 0:
        return 'flex-end'
      case 1:
        return 'flex-start'
      case 2:
        return 'center'
      case 3:
        return 'center'
      default:
        return 'center'
    }
  }
}

const Tooltip = ({ anchor, placement = 1, alignment = 1, text }) => {
  const domRect = anchor.getBoundingClientRect()

  const ghost = {
    // position: 'fixed',
    // zIndex: 999999999,
    width: domRect.width,
    height: domRect.height,
    top: domRect.top,
    left: domRect.left,
    // pointerEvents: 'none',
  }

  const align = {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // zIndex: 99999,
    // width: '100%',
    // height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    justifyContent: justifyContent(placement, alignment),
    alignItems: alignItems(placement, alignment),
  }

  const place = {
    position: 'relative',
    margin: 8,
    ...getPlacement(placement),

    // width: '100%', // alignment 3 = container width
  }

  return (
    <div className={styles.ghost} style={ghost}>
      <div className={styles.align} style={align}>
        <div style={place}>
          <div className={styles.tooltip}>{text}</div>
        </div>
      </div>
    </div>
  )
}

const TooltipWrapper = ({ text, delay, placement, alignment, children }) => {
  const [show, showTooltip] = useState(false)
  const ref = useRef(null)

  let timer
  const onMouseEnter = (ev) => {
    if (ref.current !== ev.currentTarget) {
      ref.current = ev.currentTarget
    }
    timer = setTimeout(() => showTooltip(true), delay)
  }

  const onMouseLeave = (ev) => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    showTooltip(false)
  }

  return (
    <>
      {show && (
        <Layer>
          <Tooltip
            anchor={ref.current}
            placement={placement}
            alignment={alignment}
            text={text}
          />
        </Layer>
      )}

      {React.cloneElement(children, {
        onMouseEnter: onMouseEnter,
        onTouchStart: onMouseEnter,
        onMouseLeave: onMouseLeave,
      })}
    </>
  )
}

export default TooltipWrapper
