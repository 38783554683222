import React from 'react'
import { Tile } from '../templates'
import mapType from '../mapType'
import TYPE from '../../../api/types'

const Pages = ({ data }) => {
  return (
    <div>
      {data.map(({ data, media, id }) => (
        <Tile
          image={media[0]}
          title={data.title}
          description={`${data.position}, ${data.country}`}
          key={id}
        />
      ))}
    </div>
  )
}

Pages.defaultProps = {
  data: [],
}

export default mapType(TYPE.PAGE)(Pages)
