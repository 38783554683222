import React, { useState } from 'react'

import SpringPopup from './SpringPopup'

const PopupTrigger = ({ children, component, modalProps }) => {
  const [show, togglePopup] = useState(false)
  return (
    <>
      {React.cloneElement(children, { onClick: () => togglePopup(true) })}
      <SpringPopup isOpen={show} onDismiss={() => togglePopup(false)}>
        {React.createElement(component, modalProps)}
      </SpringPopup>
    </>
  )
}

export default React.memo(PopupTrigger)
