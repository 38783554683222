import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { sessionReducer, sessionService } from 'redux-react-session'
import thunk from 'redux-thunk'

import user from './user'
import app, { CLEAR_APP_DATA } from './app'
import temp from './temp'
import notifications from './notification'
import ui from './ui'
import fetching from './fetching'

import { saveState, loadState } from './localStorage'

const reducer = combineReducers({
  session: sessionReducer,
  temp,
  user,
  app,
  notifications,
  ui,
  api: fetching,
})

const RESET_STATE = 'RESET_STATE'

const rootReducer = (state, action) => {
  const { session, ui, user } = state
  switch (action.type) {
    case RESET_STATE:
      state = { session, ui }
      break
    case CLEAR_APP_DATA:
      state = { session, ui, user }
      break
    default:
      break
  }
  return reducer(state, action)
}

const persistedState = loadState()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
)

store.subscribe(() => {
  saveState({ ui: store.getState().ui })
})

sessionService.initSessionService(store)

export const resetState = () => ({ type: RESET_STATE })
export default store
