import React from 'react'

import layout from '../Layout.module.css'

const Sticky = ({ children, top, left, bottom, right, ...props }) => {
  return (
    <div className={layout.sticky} style={{ top, left, bottom, right }}>
      {children}
    </div>
  )
}

export default Sticky
