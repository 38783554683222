import React from 'react'

import styles from './FormRow.module.css'

const FormRow = ({ isLabel, label, description, inline = true, ...props }) => {
  // const Tag = isLabel ? 'label' : 'div'
  const cls = inline ? styles.group : `${styles.group} ${styles.block}`
  return (
    <div className={cls}>
      {label && (
        <div className={styles.aside}>
          <div className={styles.label}>{label}</div>
          {description && <div className={styles.descr}>{description}</div>}
        </div>
      )}

      <div className={styles.content}>{props.children}</div>
    </div>
  )
}

export default FormRow
