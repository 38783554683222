import React from 'react'
import { ListItem } from '../templates'
import mapType from '../mapType'
import TYPE from '../../../api/types'
import DatePreview from '../templates/DatePreview'

import { destructureDate } from '../../../utils'

const Events = ({ data }) => {
  return (
    <div>
      {data.map(({ data, media, id }) => {
        const { weekday, time: startTime } = destructureDate(data.eventStart)
        const { time: endTime } = destructureDate(data.eventEnd)
        return (
          <ListItem
            preview={<DatePreview image={media[0]} date={data.eventStart} />}
            title={data.eventName}
            description={`${weekday}, ${startTime} – ${endTime}`}
            key={id}
          />
        )
      })}
    </div>
  )
}

Events.defaultProps = {
  data: [],
}

export default mapType(TYPE.EVENT)(Events)
