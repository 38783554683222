import React from 'react'

import TableFirstColumn from './components/TableFirstColumn'
import { HideItemButton, DeleteItemButton } from './actions'

export default [
  {
    prop: 'data.name',
    header: 'Name',
    render: ({ id, data, media }) => (
      <TableFirstColumn image={media[0]} title={data.name} link={id} />
    ),
  },
  { prop: 'data.email', header: 'Email' },
  { prop: 'data.company', header: 'Company' },
  HideItemButton,
  DeleteItemButton,
]
