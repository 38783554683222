import React from 'react'

import { FileInput } from '../../ui/inputs'

import placeholder from '../../../images/DUFQorJW0AAXmvd.jpg'

import styles from './CoverUpload.module.css'

const CoverUpload = ({ children, ...props }) => {
  return (
    <FileInput accept=".jpg, .jpeg, .png" {...props}>
      <div
        className={styles.upload}
        style={{ backgroundImage: `url(${placeholder})` }}
      >
        {children}
        <div className={styles.overlay}>Upload Image</div>
      </div>
    </FileInput>
  )
}

export default CoverUpload
