import React from 'react'

import Forma, { FormaRedux, useSubmit } from '../components/Forma'

import { Sticky, Header, Box } from '../ui/layout'
import { Button } from '../ui/inputs'
import Spinner from '../ui/Spinner'

import { getDisplayName } from './utils'

function appSetupFormFactory(type, component, defaultProps) {
  const WrappedComponent = ({ title, item }) => {
    const [handleSubmit, isSubmitting] = useSubmit(type)

    return (
      <Forma initialState={item.data} initialMedia={item.media}>
        {(forma) => (
          <FormaRedux type={type} forma={forma}>
            <form
              onSubmit={(ev) => ev.preventDefault()}
              style={{ height: '100%', overflowY: 'auto', paddingBottom: 40 }}
            >
              <Sticky top={0}>
                <Header title={title}>
                  <Box paddingX={2}>
                    <Button
                      rounded
                      size="sm"
                      onClick={handleSubmit(forma, item)}
                    >
                      Save
                    </Button>
                  </Box>
                </Header>
              </Sticky>
              {React.createElement(component, forma)}
              <Spinner show={isSubmitting} />
            </form>
          </FormaRedux>
        )}
      </Forma>
    )
  }

  WrappedComponent.defaultProps = defaultProps
  WrappedComponent.displayName = `${getDisplayName(component)}`

  return WrappedComponent
}

export default appSetupFormFactory
