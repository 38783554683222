import React from 'react'
import mapType from '../../mapType'
import TYPE from '../../../../api/types'

import { Box } from '../../../ui/layout'
import Events from '../Events'
import Venues from '../Venues'

import { Tile } from '../../templates'
import Square from '../../../ui/Square'

import app from '../../App.module.css'
import styles from './Frontpage.module.css'

const ListLabel = ({ children, ...props }) => {
  return (
    <div
      className={app.subtitle2}
      style={{
        height: 40,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
      }}
    >
      {children}
    </div>
  )
}

export const WhatsNext = ({ data = [] }) => {
  return (
    <Box height={96}>
      <div className={styles.whatsnext}>
        <div className={styles.whatsnext__left}>
          <div className={app.subtitle1} style={{ color: '#018786' }}>
            NEXT
          </div>
          <div className={app.subtitle1} style={{ color: '#ccc' }}>
            20:00
          </div>
        </div>
        <div>
          <div className={app.title3}>
            Long event name with a lot words lorem ipsum
          </div>
          <div className={app.subtitle2} style={{ marginTop: 8 }}>
            Venue Name
          </div>
        </div>
      </div>
    </Box>
  )
}

export const Slider = mapType(TYPE.ARTIST)(({ data = [] }) => {
  return (
    <div className={styles.slider}>
      {data.map(({ id, data, media }) => {
        return (
          <div className={styles.sliderItem} key={id}>
            <Tile
              style={{ height: 140, borderRadius: 8 }}
              image={media[0]}
              title={data.title}
              description={data.country}
            />
          </div>
        )
      })}
    </div>
  )
})

export const GridView = mapType(TYPE.OFFER)(({ data = [] }) => {
  return (
    <div className={styles.gridview}>
      {data.map(({ id, data, media }) => {
        return (
          <div className={styles.gridviewItem} key={id}>
            <Square>
              <Tile
                style={{ height: '100%', borderRadius: 8 }}
                image={media[0]}
                title={data.title}
                description={data.description}
              />
            </Square>
          </div>
        )
      })}
    </div>
  )
})

export const Featured = ({ data = [] }) => {
  return (
    <Box>
      <ListLabel>Featured</ListLabel>
      <Events />
    </Box>
  )
}

export const Nearby = ({ data = [] }) => {
  return (
    <Box>
      <ListLabel>Nearby</ListLabel>
      <Venues />
    </Box>
  )
}

export const sections = {
  WhatsNext,
  Slider,
  Featured,
  GridView,
  Nearby,
}

export const order = ['WhatsNext', 'Slider', 'GridView', 'Featured', 'Nearby']

export default [WhatsNext, Slider, GridView, Featured, Nearby]
