import React from 'react'

import table from '../Table.module.css'

const TableRow = ({ children, ...props }) => {
  return (
    <div className={table.row} {...props}>
      {children}
    </div>
  )
}

export default TableRow
