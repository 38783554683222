import React from 'react'
import { connect } from 'react-redux'
import { deleteAppChild } from '../../../store/app'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from '../../components/Table/Table.module.css'

let DeleteTableItem = ({ item, dispatch }) => {
  const deleteItem = (ev) => {
    if (item.id) {
      if (window.confirm('Delete?')) {
        dispatch(deleteAppChild(item))
      }
    }
  }
  return (
    <div className={styles.tableIcon} onClick={deleteItem}>
      <FontAwesomeIcon icon="trash" />
    </div>
  )
}

DeleteTableItem = connect()(DeleteTableItem)

export default {
  render: (item) => <DeleteTableItem item={item} />,
  style: { flex: 'none', width: '48px', color: '#aaa', textAlign: 'center' },
}
