import React from 'react'

import styles from './Toggle.module.css'

const Toggle = ({ style, ...props }) => (
  <label className={styles.tggl} style={style}>
    <input className={styles.tggl__checkbox} type="checkbox" {...props} />
    <div className={styles.tggl__background} />
    <div className={styles.tggl__button} />
  </label>
)

export default Toggle
