import React from 'react'

import FormRow from '../components/FormRow'
import ImageSlider from '../components/ImageSlider'
import FormSocials from '../components/FormSocials'

import {
  Input,
  Select,
  TextArea,
  FormFiles,
  SelectCountry,
} from '../../ui/inputs'

export const SpeakerForm = ({
  media,
  bindInput,
  handleUpload,
  handleDelete,
}) => {
  return (
    <>
      <ImageSlider files={media.image} name="image" onChange={handleUpload} />

      <FormRow label="Speaker Images">
        <FormFiles
          files={media.image}
          name="image"
          onChange={handleUpload}
          onDelete={handleDelete}
          accept=".jpg, .jpeg, .png"
        />
      </FormRow>

      <FormRow label="Speaker Name">
        <Input required placeholder="Speaker Name" {...bindInput('name')} />
      </FormRow>

      <FormRow label="Speaker Country">
        <SelectCountry {...bindInput('country')} />
      </FormRow>

      <FormRow label="Speaker Job Title">
        <Input placeholder="Job Title" {...bindInput('position')} />
      </FormRow>

      <FormRow label="Speaker Industry">
        <Select placeholder="Select Industry" {...bindInput('industry')}>
          <option value="it">Internet</option>
        </Select>
      </FormRow>

      <FormRow label="About Speaker" inline={false}>
        <TextArea placeholder="About speaker" {...bindInput('description')} />
      </FormRow>

      <FormSocials bindInput={bindInput} />
    </>
  )
}

export default SpeakerForm
