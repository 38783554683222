import api, { BASE_URL } from './api'

function placeholderData(item) {
  return {
    ...item,
    data: {
      ...item.collection,
    },
  }
}

export function getUserCompanies() {
  return api(`${BASE_URL}/user/accounts`).then((res) => {
    return res.map(placeholderData)
  })
}

export function getUserConfig() {
  return api(`${BASE_URL}/user/account`)
}

export function getUserProfile() {
  return api(`${BASE_URL}/user/profile`)
}

export function updateUserProfile(data) {
  const body = JSON.stringify(data)

  return api(`${BASE_URL}/user/profile`, { method: 'POST', body })
}

export function createUserProfile({ email, password }) {
  const body = JSON.stringify({
    email,
    password,
  })
  return api(`${BASE_URL}/item/user-profile`, { method: 'POST', body })
}

export function resetUserProfile({ email, password }) {
  const body = JSON.stringify({
    email,
    password,
  })
  return api(`${BASE_URL}/item/user-profile/reset`, {
    method: 'POST',
    body,
  })
}
