import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import cc from 'classcat'

import {
  // userRegister,
  userLogin,
  userLogout,
} from '../../../store/session'

import styles from './Login.module.css'

const JoinFooter = (props) => {
  return (
    <p className={styles.text}>
      By creating an account, I accept Festivality's
      <br />{' '}
      <a className={styles.link} href="/join">
        Terms of Service
      </a>{' '}
      and{' '}
      <a className={styles.link} href="/join">
        Privacy Policy
      </a>
      .
    </p>
  )
}

const LoginFooter = (props) => {
  return (
    <Link className={styles.reset} to={`/reset`}>
      Forgot password?
    </Link>
  )
}

const LoginInput = (props) => {
  return <input className={styles.input} type="text" {...props} />
}

class Login extends React.Component {
  state = {
    email: 'slava@festivality.co',
    password: '',
    confirm: '',
    message: null,
    url: '/login',
  }

  componentDidMount() {
    if (this.props.match.url === '/logout') {
      userLogout(this.props.history)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.url !== prevState.url) {
      return { url: nextProps.match.url }
    }
    return null
  }

  handleChange = (ev) => {
    const { name, value } = ev.target

    this.setState({ [name]: value })
  }

  handleSubmit = (ev) => {
    ev.preventDefault()
    const { email, password, confirm, company, url } = this.state
    const { history } = this.props

    const user = {
      email,
      password,
      company,
    }

    this.setState({ message: null })

    switch (url) {
      case '/login':
        if (email.toLowerCase() !== password.toLowerCase()) {
          this.setState({ message: 'Wrong email or password' })
        } else {
          userLogin(user, history).catch((err) =>
            this.setState({ message: err.message })
          )
        }
        return
      case '/join':
        if (password !== confirm) {
          this.setState({ message: "Passwords doesn't match" })
        } else {
          userLogin(user, history).catch((err) =>
            this.setState({ message: err.message })
          )
          // userRegister(user, history)
        }
        return
      case '/reset':
        this.setState({ message: 'Check your email for new password' })
        return
      default:
        return
    }
  }

  checkValidity = () => {
    const { email, password, confirm } = this.state
    const { url } = this.props.match
    switch (url) {
      case '/login':
        return email && password
      case '/join':
        return email && !!password && password === confirm
      case '/reset':
        return email
      default:
        return false
    }
  }

  render() {
    const { url } = this.state

    const button = cc({
      [styles.button]: true,
      [styles.valid]: this.checkValidity(),
    })

    return (
      <div className={styles.page}>
        <div>
          <div className={styles.header}>
            <NavLink
              className={styles.headerLink}
              activeClassName={styles.active}
              to={`/login`}
            >
              Log In
            </NavLink>
            <span className={styles.headerSpan}> or </span>
            <NavLink
              className={styles.headerLink}
              activeClassName={styles.active}
              to={`/join`}
            >
              Join
            </NavLink>
          </div>
          <div className={styles.modal}>
            {this.state.message && (
              <div className={styles.message}>{this.state.message}</div>
            )}
            <form className={styles.form} onSubmit={this.handleSubmit}>
              {url === '/join' && (
                <LoginInput
                  name="company"
                  placeholder="Company"
                  onChange={this.handleChange}
                />
              )}

              <LoginInput
                name="email"
                placeholder="Email"
                onChange={this.handleChange}
              />

              {url !== '/reset' && (
                <LoginInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                />
              )}

              {url === '/join' && (
                <LoginInput
                  type="password"
                  name="confirm"
                  placeholder="Confirm Password"
                  onChange={this.handleChange}
                />
              )}

              <button className={button} type="submit">
                {url === '/login' ? 'Log In' : url.replace('/', '')}
              </button>
            </form>
            {url === '/login' && <LoginFooter />}
            {url === '/join' && <JoinFooter />}
          </div>
        </div>
      </div>
    )
  }
}

export default Login
