import React from 'react'

import Img from '../ui/Img'
import FormButton from '../forms/components/FormButton'

import { TextArea, FileInput } from '../ui/inputs'

import native_icon from '../../images/native-icon.png'
import bg from '../../images/DUFQorXXUAU-Ncv.jpg'

import './AppForm.css'

function getImage(file) {
  if (file) {
    return window.URL.createObjectURL(file[0])
  } else {
    return false
  }
}

function updateAppData() {
  return false
}

const AppForm = ({ state, media, handleUpload, bindInput, ...props }) => {
  const handleSave = (ev) => {
    ev.preventDefault()
    console.log(state)
    props
      .dispatch(updateAppData({ meta: state }, media))
      .then((res) => props.hideModal())
  }
  const handleDeploy = (ev) => {
    ev.preventDefault()
    console.log(state)
    props
      .dispatch(updateAppData({ meta: state }, media))
      .then((res) => props.hideModal())
  }
  return (
    <form className="form">
      <FileInput name="cover" onChange={handleUpload}>
        <div
          className="app-cover"
          style={{ backgroundImage: `url(${getImage(media.cover) || bg})` }}
        />
      </FileInput>

      <div className="app-form">
        <FileInput name="appIcon" onChange={handleUpload}>
          <Img
            className="app-icon"
            width="100%"
            src={media.appIcon || native_icon}
            alt=""
            style={{
              position: 'absolute',
              top: '-60px',
              left: '0',
              right: '0',
              margin: '0 auto',
              background: '#fff',
            }}
          />
        </FileInput>

        <input
          style={{ marginTop: '80px' }}
          type="text"
          className="app-input"
          placeholder="App Name"
          maxLength="30"
          {...bindInput('appName')}
        />

        <TextArea
          className="textarea app-textarea"
          maxLength={160}
          rows="2"
          placeholder="App description"
          {...bindInput('description')}
        />

        <input
          type="text"
          className="app-input app-input--small"
          placeholder="Keywords"
          {...bindInput('keywords')}
        />
      </div>

      <div className="form-buttons">
        <FormButton onClick={handleSave}>Save</FormButton>
        <FormButton onClick={handleDeploy}>Save and deploy</FormButton>
      </div>
    </form>
  )
}

export default AppForm
