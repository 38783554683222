import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './App.module.css'

const AppNavBar = ({ style = {}, title, ...props }) => {
  return (
    <div
      className={styles.surface}
      style={{ backgroundColor: style.menuBackgroundColor }}
    >
      <div className={styles.navbar}>
        <div
          className={styles.navbar__button}
          style={{ color: style.menuAccentColor }}
          onClick={props.toggleMenu}
        >
          <FontAwesomeIcon icon="bars" />
        </div>
        <div
          className={styles.navbar__title}
          style={{ color: style.menuTextColor }}
        >
          {title}
        </div>
        <div
          className={styles.navbar__button}
          style={{ color: style.menuAccentColor }}
        >
          <FontAwesomeIcon icon="search" />
        </div>
      </div>
    </div>
  )
}

export default AppNavBar
