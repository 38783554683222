import React from 'react'

import styles from './System.module.css'
// import Text from '../Text'
// import Heading from '../Heading'

import BaseInputsDemo from './views/BaseInputsDemo'
import ButtonDemo from './views/ButtonDemo'
import ToggleDemo from './views/ToggleDemo'
import RadioDemo from './views/RadioDemo'
import CheckboxDemo from './views/CheckboxDemo'
// import NotificationsDemo from './views/NotificationsDemo'
import TextareaDemo from './views/TextareaDemo'
import TextDemo from './views/TextDemo'
// import ColorsDemo from './views/ColorsDemo'
import TextInputDemo from './views/TextInputDemo'
import HeaderDemo from './views/HeaderDemo'
import BadgeDemo from './views/BadgeDemo'
import TooltipDemo from './views/TooltipDemo'
import TagsInputDemo from './views/TagsInputDemo'

class System extends React.Component {
  render() {
    return (
      <div className={styles.system}>
        <div className={styles.section}>
          {/* <NotificationsDemo /> */}
          <ButtonDemo />
          <TooltipDemo />
          <BaseInputsDemo />
          <ToggleDemo />
          <RadioDemo />
          <CheckboxDemo />

          <TextareaDemo />
          <TextInputDemo />
          <TagsInputDemo />

          <HeaderDemo />
          <TextDemo />
          <BadgeDemo />
          {/* <ColorsDemo /> */}
        </div>

        {/* <div className={styles.section}>
          <Spinner show={true} />
        </div> */}
      </div>
    )
  }
}

export default System
